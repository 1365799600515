import { TikTokEmbed } from "react-social-media-embed";
import DefaultLinkRenderer from "./DefaultLinkRenderer";

function getTiktokEmbed(gem) {
    if (!gem?.metadata?.info?.embed_product_id) {
        return;
    }
    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <TikTokEmbed url={gem.metadata.info.embed_product_id} width={325} />
        </div>
    );
}

const TiktokRenderer = {
    detect: (url) =>
        /^(?:https?:\/\/)?(?:www\.)?(?:tiktok\.com\/(?:[^/\n\s]+\/\S+\/|(?:@[^/\n\s]+\/video\/)?|\S*?[?&]id=([^&]+)(?:[?&][^=]+=[^&]+)*))|vm\.tiktok\.com\/([a-zA-Z0-9_-]{8,15})$/.test(
            url
        ),

    content: (gem) => {
        return getTiktokEmbed(gem);
    },

    preview: (gem) => {
        return (
            <div className="relative">
                <img
                    className="absolute top-1 right-1"
                    src="https://cdn3.emoji.gg/emojis/6621_tiktok.png"
                    width="10%"
                    alt="tiktok"
                />
                <img className="w-full" src={gem.metadata.image} />
            </div>
        );
    },

    title: (gem) => {
        const linkInfo = gem.metadata;
        if (linkInfo.logo) {
            return DefaultLinkRenderer.title(gem);
        }
        return <>🎥 {gem.title}</>;
    },
};

export default TiktokRenderer;

import React, { useEffect } from "react";
import { PostEditState, PostType } from "@constants";
import { EditPostState, usePost, usePostEditState } from "@features/create";

function EditCollectionWrapper({ post = null, savedState = null }) {
    const postControl = usePost(PostType.COLLECTION, post);
    const postEditState = usePostEditState(PostEditState.Edit);

    useEffect(() => {
        if (savedState?.postControl) {
            const { mergePostState } = postControl;
            mergePostState(savedState?.postControl);
        }
    }, [savedState]);

    return (
        <EditPostState
            postControl={postControl}
            postEditState={postEditState}
        />
    );
}

export default EditCollectionWrapper;

import { reorder, move } from "@util/drag-utils";

/**
 * Flattens a grid of posts into a single array, by row.
 */
function flattenPosts(distributedPosts) {
    let posts = [];
    let maxLength = Math.max(...distributedPosts.map((col) => col.length));

    for (let i = 0; i < maxLength; i++) {
        for (let j = 0; j < distributedPosts.length; j++) {
            if (i < distributedPosts[j].length) {
                posts.push(distributedPosts[j][i]);
            }
        }
    }

    return posts;
}

/**
 * Handles dropping a draggable in a column.
 */
export async function handleColumnDrop(
    source,
    destination,
    distributedPosts,
    setDistributedPosts,
    handlePageReorder
) {
    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;

    // Creating a deep copy of distributedPosts
    const newDistributedPosts = distributedPosts.map((col) => [...col]);

    // If dropped within the same list
    if (sInd === dInd) {
        newDistributedPosts[sInd] = reorder(
            newDistributedPosts[sInd],
            source.index,
            destination.index
        );
    } else {
        const result = move(
            newDistributedPosts[sInd],
            newDistributedPosts[dInd],
            source,
            destination
        );
        newDistributedPosts[sInd] = result[sInd];
        newDistributedPosts[dInd] = result[dInd];
    }

    // Optimistically update local state before updating the server, so that the drop occurs smoothly.
    setDistributedPosts(newDistributedPosts);

    const flattenedPosts = flattenPosts(newDistributedPosts);
    handlePageReorder(flattenedPosts);

    return newDistributedPosts;
}

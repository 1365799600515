import React, { useState, useEffect, useRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function SimpleTextEditor({ value, onChange, placeholder }) {
    const quillRef = useRef(null);

    useEffect(() => {
        if (quillRef.current) {
            const editor = quillRef.current.getEditor();

            // Disable image handlers
            editor.off("text-change");
        }
    }, []);

    const handleChange = (newValue, delta, source) => {
        if (source === "user") {
            onChange(newValue);
        }
    };

    return (
        <div className="relative overflow-hidden">
            <ReactQuill
                value={value}
                onChange={handleChange}
                modules={{
                    toolbar: false, // Disable toolbar
                }}
                formats={[]}
                ref={quillRef}
                style={{ backgroundColor: "white" }}
                placeholder={placeholder}
            />
        </div>
    );
}

export default SimpleTextEditor;

const friendPosts = [
    {
        author: { _id: "658dd288945313e876ff34fd", username: "david" },
        commentCount: [],
        content:
            '<p><img src="https://gems-simpleblogimages.s3.amazonaws.com/658dd288945313e876ff34fd/5a3d015f/ce369433d5c9"></p>',
        contentType: "",
        createdAt: "2023-12-28T20:02:50.651Z",
        description:
            "a lot chiller than expected , although one did try to attack us.\r\n\r\n10/10 worth doing a tour to see them! ",
        gemId: "5a3d015f",
        likeCount: [],
        link: undefined,
        metadata: null,
        privacyLevel: "Public",
        status: "published",
        title: "Komodo Dragons are p cool ",
        type: "Gem",
        updatedAt: "2023-12-28T20:02:58.244Z",
        _id: "658dd46a945313e876ff377b",
    },
];

export default friendPosts;

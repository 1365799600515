import React, { useState } from "react";
import Select from "react-select";

function CreateEditFormWrapper({ formElements, children }) {
    const [isDraft, setIsDraft] = useState(false);

    if (!formElements) return;
    return (
        <div className="p-6">
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-md font-bold">{formElements.title}</h2>
                <span className="text-sm text-gray-500">
                    by {formElements.author}
                </span>
            </div>
            <form onSubmit={(ev) => formElements.onSubmit(ev, isDraft)}>
                <div className="mb-4">{children}</div>
                <div className="flex items-center space-x-2">
                    <button
                        type="submit"
                        disabled={formElements.uploading}
                        className="bg-indigo-200 hover:bg-indigo-500 text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline text-sm"
                    >
                        {formElements.submitButtonMessage}
                    </button>
                    <button
                        type="submit"
                        onClick={() => setIsDraft(true)}
                        disabled={formElements.uploading}
                        className="bg-gray-300 hover:bg-gray-400 text-black py-2 px-4 rounded focus:outline-none focus:shadow-outline text-sm"
                    >
                        Save as Draft
                    </button>
                </div>
            </form>
        </div>
    );
}

function FormLabel({ children }) {
    return <div className="text-sm">{children}</div>;
}

function FormButton({ text }) {
    return (
        <button
            className="bg-purple-600 bg-opacity-90 hover:bg-opacity-100 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit"
        >
            {text}
        </button>
    );
}

function FormElementWrapper({ children }) {
    return <div className="mb-4">{children}</div>;
}

function FormInput({
    name,
    type,
    placeholder,
    value,
    onChange,
    classStyles,
    autoComplete,
}) {
    return (
        <input
            className={`${classStyles} appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:ring-1`}
            name={name}
            type={type}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            autoComplete={autoComplete}
        />
    );
}

function FormStatus({ status }) {
    return (
        <div className="text-center text-green-700 mt-2 text-sm">{status}</div>
    );
}

function FormStatusFail({ status }) {
    return (
        <div className="text-center text-red-700 mt-2 text-sm">{status}</div>
    );
}

function FormTextArea({
    name,
    type,
    placeholder,
    value,
    onChange,
    classStyles,
}) {
    return (
        <textarea
            className={`${classStyles} appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline`}
            name={name}
            type={type}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
        ></textarea>
    );
}

function FormSelectField({
    value,
    onChange,
    options,
    name,
    placeholder,
    isMulti,
}) {
    const styles = {
        menuPortal: (base) => ({ ...base, zIndex: 50, fontSize: "0.875rem" }),
    };

    return (
        <div className="relative">
            <Select
                value={value}
                onChange={onChange}
                options={options}
                className="basic-single text-sm"
                classNamePrefix="select"
                name={name}
                placeholder={placeholder}
                menuPosition="fixed"
                menuPortalTarget={document.body}
                styles={styles}
                isMulti={isMulti}
            />
        </div>
    );
}

function ToggleField({ name, checked, onChange }) {
    return (
        <label htmlFor={name} className="relative inline-block">
            <input
                type="checkbox"
                id={name}
                checked={checked}
                onChange={onChange}
                className="hidden"
            />
            <div
                className={`block w-10 h-6 rounded-full cursor-pointer ${
                    checked ? "bg-green-500" : "bg-gray-300"
                }`}
            ></div>
            <div
                className={`dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition ${
                    checked ? "transform translate-x-full" : ""
                }`}
            ></div>
        </label>
    );
}

export {
    CreateEditFormWrapper,
    FormLabel,
    FormElementWrapper,
    FormButton,
    FormInput,
    FormStatus,
    FormStatusFail,
    FormTextArea,
    FormSelectField,
    ToggleField,
};

import axios, { axiosPrivate } from "@api/axios";

class AuthService {
    static async login(username, password) {
        try {
            const response = await axiosPrivate.post("/users/login", {
                username,
                password,
            });

            return {
                success: true,
                status: response.status,
                message: "Logged in successfully",
                token: response.data.accessToken,
            };
        } catch (error) {
            console.error("Error during login:", error);
            if (error.response) {
                if (error.response.status === 400) {
                    return {
                        success: false,
                        status: error.response.status,
                        message: error.response.data.error || "Login failed",
                    };
                } else if (error.response.status === 403) {
                    return {
                        success: false,
                        status: error.response.status,
                        email: error.response.data.email,
                        message:
                            "Email not verified. Please verify your email before logging in.",
                    };
                }
            }
            return {
                success: false,
                message: "An error occurred. Please try again later.",
            };
        }
    }

    static async logout() {
        try {
            await axiosPrivate.post("/users/logout", {});

            document.cookie =
                "jwt=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

            return {
                success: true,
                message: "Logged out successfully",
            };
        } catch (error) {
            console.error("Error during logout:", error);
            return {
                success: false,
                message: error.message || "An error occurred during logout.",
            };
        }
    }

    static async register({ username, password, name, email, token }) {
        try {
            const response = await axios.post(`/users/register`, {
                username,
                password,
                name,
                email,
                token,
            });

            return { success: true, data: response.data };
        } catch (error) {
            console.error("Error during registration:", error);
            return {
                success: false,
                message:
                    error.response?.data?.message ||
                    "Registration failed. Please try again.",
            };
        }
    }

    static async resendVerification(email) {
        try {
            const response = await axios.post("/users/resend-verification", {
                email,
            });

            return { success: true, data: response.data.data };
        } catch (error) {
            console.error("Error during resend verification:", error);
            return {
                success: false,
                message:
                    error.response?.data?.message ||
                    "Failed to resend verification email.",
            };
        }
    }

    static async verifyEmail(token) {
        try {
            const response = await axios.get(`/users/verify-email/${token}`);

            return { success: true, data: response.data };
        } catch (error) {
            console.error("Error during email verification:", error);
            return {
                success: false,
                message:
                    error.response?.data?.message ||
                    "An unexpected error occurred",
            };
        }
    }

    static async forgotPassword(email) {
        try {
            const response = await axios.post("/users/forgot-password", {
                email,
            });

            // return { success: true, data: response.data };
            return { success: true, data: response.data };
        } catch (error) {
            console.error("Error during email verification:", error);
            return {
                success: false,
                message:
                    error.response?.data?.message ||
                    "An unexpected error occurred",
            };
        }
    }

    static async resetPassword({ password, token }) {
        try {
            const response = await axios.post("/users/reset-password", {
                password,
                token,
            });

            return { success: true, data: response.data };
        } catch (error) {
            return {
                success: false,
                message:
                    error.response?.data?.error ||
                    "Registration failed. Please try again.",
            };
        }
    }

    static async addToWaitlist({ email }) {
        try {
            const response = await axios.post("/users/add-to-waitlist", {
                email,
            });
            console.log(response.data);

            return { success: true, data: response.data };
        } catch (error) {
            console.error("Error during registration:", error);
            return {
                success: false,
                message:
                    error.response?.data?.message ||
                    "Registration failed. Please try again.",
            };
        }
    }
}

export default AuthService;

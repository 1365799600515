import YouTubeRenderer from "./YoutubeRenderer";
import SpotifyRenderer from "./SpotifyRenderer";
import DailyMotionRenderer from "./DailyMotionRenderer";
import SoundCloudRenderer from "./SoundCloudRenderer";
import VimeoRenderer from "./VimeoRenderer";
import GoogleMapsRenderer from "./GoogleMapsRenderer";
import DefaultLinkRenderer from "./DefaultLinkRenderer";
import DefaultRenderer from "./DefaultRenderer";
import BookRenderer from "./BookRenderer";
import MovieRenderer from "./MovieRenderer";
import AmazonRenderer from "./AmazonRenderer";
import TiktokRenderer from "./TiktokRenderer";
import InstagramRenderer from "./InstagramRenderer";

const SpecializedPreviewRenderers = [
    GoogleMapsRenderer,
    SpotifyRenderer,
    AmazonRenderer,
];

const SpecializedDescriptionRenderers = [
    GoogleMapsRenderer,
    BookRenderer,
    MovieRenderer,
    AmazonRenderer,
    TiktokRenderer,
];

const SpecializedContentRenderers = [
    GoogleMapsRenderer,
    YouTubeRenderer,
    DailyMotionRenderer,
    SpotifyRenderer,
    VimeoRenderer,
    SoundCloudRenderer,
    BookRenderer,
    MovieRenderer,
    AmazonRenderer,
    TiktokRenderer,
    InstagramRenderer,
];

// Export the categorized renderers
export {
    SpecializedPreviewRenderers,
    SpecializedContentRenderers,
    SpecializedDescriptionRenderers,
    DefaultLinkRenderer,
    DefaultRenderer,
};

import React from "react";
import { LandingPageBase } from "@features/landing";
import LoginPanel from "@features/authentication/components/LoginPanel";

function LoginPage() {
    return (
        <LandingPageBase>
            <div className="md:w-1/3">
                <LoginPanel />
            </div>
        </LandingPageBase>
    );
}

export default LoginPage;

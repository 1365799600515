import React, { useContext, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { UserContext } from "@contexts/UserContext";
import AuthService from "@services/AuthService";
import ResetPasswordForm from "./ResetPasswordForm";
import { FormStatus, FormStatusFail } from "@components/Form";
import LoadingComponent from "@components/LoadingComponent";

function ResetPasswordPanel() {
    const { isLoading, setIsLoading } = useContext(UserContext);
    const [status, setStatus] = useState("");
    const [failureStatus, setFailureStatus] = useState("");

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token");

    async function handleResetPassword({ newPassword }) {
        try {
            setIsLoading(true);

            // Assuming you have a reset password service method.
            // You'll need to pass any additional data required, like a reset token.
            const response = await AuthService.resetPassword({
                password: newPassword,
                token,
            });
            if (!response.success) {
                setFailureStatus("Reset link is invalid or expired.");
                return response;
            }
            setStatus("Your password has been reset successfully");
            // return response;
            return response;
        } catch (error) {
            console.error("Error during password reset:", error);
            setFailureStatus("Password reset failed, please try again later");
        } finally {
            setIsLoading(false);
        }
    }

    if (isLoading) {
        return <LoadingComponent />;
    }

    return (
        <div className="flex flex-col items-center bg-white p-4 rounded-md border-2 border-gray-400 w-96">
            <h1 className="font-title text-2xl mb-6 mt-2">
                Reset Your Password
            </h1>
            <div className="max-w-md md:w-1/2 text-center mb-6">
                Enter a new password.
            </div>
            <ResetPasswordForm onSubmit={handleResetPassword} />
            {status && <FormStatus status={status} />}
            {!status && failureStatus && (
                <FormStatusFail status={failureStatus} />
            )}
            <Link
                to="/login"
                className="mt-8 text-blue-600 hover:text-blue-800"
            >
                Back to login
            </Link>
        </div>
    );
}

export default ResetPasswordPanel;

import { useState, useEffect } from "react";
import PostService from "@services/PostService";
import { Endpoints } from "@constants";

export function usePosts({ endpoint, username = null, pageId = null }) {
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    async function fetchData(endpoint, pageId) {
        try {
            if (endpoint === Endpoints.ALL) {
                const recommendations = await PostService.getRecommendations(
                    "1"
                );
                setPosts(recommendations);
                setLoading(false);
                const updatedRecommendations =
                    await PostService.getRecommendations();
                setPosts(updatedRecommendations);
                return;
            }

            const queryParam = PostService.buildQueryParam(
                endpoint,
                username,
                pageId
            );

            const allItems = await PostService.getPosts(queryParam);
            setPosts(allItems);
            setLoading(false);
        } catch (error) {
            setError(error);
            setLoading(false);
        }
    }

    useEffect(() => {
        if (endpoint) {
            setLoading(true);
            fetchData(endpoint, pageId);
        }
    }, [endpoint, pageId, username]);

    return { posts, loading, error };
}

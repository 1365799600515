import { CreateEmbedRenderer } from "../EmbedRenderer";

const iframeProps = {
    width: "560",
    height: "315",
    allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
    allowFullScreen: "{true}",
};

function getEmbedURL(url) {
    const match = url.match(
        /(?:youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
    );
    return match ? `https://www.youtube.com/embed/${match[1]}` : null;
}

const YouTubeRenderer = {
    detect: (url) =>
        /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=([^&]+)(?:[?&][^=]+=[^&]+)*))|youtu\.be\/([a-zA-Z0-9_-]{11})$/.test(
            url
        ),

    content: (gem) => {
        return CreateEmbedRenderer(gem, getEmbedURL, iframeProps);
    },
    title: (gem) => {
        return <>🎥 {gem.title}</>;
    },
};

export default YouTubeRenderer;

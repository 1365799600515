import {
    createContext,
    useState,
    useEffect,
    useContext,
    useCallback,
} from "react";
import { UserContext } from "@contexts/UserContext";
import { PostType, PRIVACY_LEVELS, DefaultId } from "@constants";
import { Collection } from "@classes/Post";
import Page from "@classes/Page";
import PageService from "@services/PageService";

const PersonalPageContext = createContext();

export function PersonalPageProvider({ children }) {
    const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);
    const { userInfo, isAuthenticated, isLoading } = useContext(UserContext);
    const [posts, setPosts] = useState({});
    const [pages, setPages] = useState([]);
    const [loading, setLoading] = useState(false);

    const getIdToObjectMapping = (items) => {
        if (!items || !Array.isArray(items)) return null;
        const map = items.reduce((acc, item) => {
            acc[item?._id?.toString()] = item;
            return acc;
        }, {});
        return map;
    };

    useEffect(() => {
        if (isLoading) {
            return;
        }

        if (isAuthenticated()) {
            setIsUserAuthenticated(true);
        }
    }, [userInfo, isAuthenticated, isLoading]);

    useEffect(() => {
        const fetchPersonalPage = async () => {
            try {
                setLoading(true);
                const personalPage = await PageService.getPersonalPage();

                if (personalPage) {
                    const archivePage = new Page({
                        _id: DefaultId,
                        title: "Archive (All Posts)",
                        items: personalPage.posts,
                        privacyLevel: PRIVACY_LEVELS.PRIVATE,
                        isArchive: true,
                        url: "archive",
                    });

                    setPages([...personalPage.pages, archivePage]);
                    const postIdToPosts = getIdToObjectMapping(
                        personalPage.posts
                    );
                    setPosts(postIdToPosts);
                }
            } catch (error) {
                console.error("Error fetching personal page:", error);
            } finally {
                setLoading(false);
            }
        };

        if (isUserAuthenticated) {
            fetchPersonalPage();
        }
    }, [userInfo, isUserAuthenticated]);

    const getPost = (postId) => {
        if (posts && postId in posts) {
            return posts[postId];
        }

        return null;
    };

    const deletePostFromMyPosts = useCallback(
        (deletedPostId, deletedItems) => {
            setPosts((prevPosts) => {
                const updatedPosts = {};

                Object.entries(prevPosts).forEach(([postId, post]) => {
                    if (postId !== deletedPostId) {
                        // Exclude the deleted post
                        const updatedPost = { ...post };

                        // Remove the post from collections it's in
                        if (
                            updatedPost.type === PostType.COLLECTION &&
                            updatedPost.items.some((item) =>
                                deletedItems.includes(item._id)
                            )
                        ) {
                            updatedPost.items = updatedPost.items.filter(
                                (item) => !deletedItems.includes(item._id)
                            );
                        }

                        // Filter out deleted items from GEM posts
                        if (
                            updatedPost.type === PostType.GEM &&
                            deletedItems.includes(postId)
                        ) {
                            return;
                        }

                        updatedPosts[postId] = updatedPost;
                    }
                });

                return updatedPosts;
            });

            setPages((prevPages) => {
                return prevPages.map((page) => ({
                    ...page,
                    items: page.items?.filter(
                        (itemId) => itemId !== deletedPostId
                    ),
                }));
            });
        },
        [posts]
    );

    const updatePostData = (updatedPost, givenPostId = null) => {
        const postIdToUpdate = givenPostId ?? updatedPost._id;
        if (!postIdToUpdate) return;

        setPosts((prevPosts) => {
            const updatedPosts = {};

            Object.entries(prevPosts).forEach(([postId, post]) => {
                // Update the post if it's the one we're looking for
                if (postId === postIdToUpdate) {
                    updatedPosts[updatedPost._id] = updatedPost;
                    return;
                }

                // If the post is a collection, check if it contains the updated post and update
                if (
                    post.type === PostType.COLLECTION &&
                    post.items.some((item) => item._id === postIdToUpdate)
                ) {
                    const newCollection = Collection.toCollection(post);
                    newCollection.items = post.items.map((item) =>
                        item._id === postIdToUpdate ? updatedPost : item
                    );
                    updatedPosts[postId] = newCollection;
                    return;
                }

                // Otherwise, keep the post as is
                updatedPosts[postId] = post;
            });

            return updatedPosts;
        });
    };

    const handleUpdatePost = useCallback(
        (updatedPost, postId = null, page = null) => {
            const postIdToUpdate = postId ?? updatedPost._id;
            if (!postIdToUpdate) return;

            // Update postId in page.items if necessary
            if (page) {
                page.items = page.items?.map((itemId) =>
                    itemId === postIdToUpdate ? updatedPost._id : itemId
                );
                updatePage(page);
            }

            // Update the post data in posts
            updatePostData(updatedPost, postIdToUpdate);
        },
        []
    );

    const handleAddPost = useCallback((newPost, page = null) => {
        if (!newPost) return;

        // Add postId to page.items
        if (page) {
            page.items = [newPost._id, ...page.items];
            updatePage(page);
        }

        // Add post data to posts
        setPosts((prevPosts) => {
            return { [newPost._id]: newPost, ...prevPosts };
        });
    }, []);

    const handleRemovePost = useCallback((postToRemove, page = null) => {
        if (!postToRemove) return;

        // Remove postId from page.items
        if (page) {
            page.items = page.items?.filter(
                (itemId) => itemId !== postToRemove._id
            );
            updatePage(page);
        }

        // Remove post data from posts
        setPosts((prevPosts) => {
            const updatedPosts = { ...prevPosts };
            delete updatedPosts[postToRemove._id];
            return updatedPosts;
        });
    }, []);

    const updatePage = (updatedPage) => {
        setPages((prevPages) => {
            const updatedPages = prevPages.map((page) =>
                page?._id === updatedPage._id ? updatedPage : page
            );
            return updatedPages;
        });
    };

    const addNewPage = (newPage) => {
        setPages((prevPages) => [...prevPages, newPage]);
    };

    const deletePage = (deletedPage) => {
        setPages((prevPages) =>
            prevPages.filter((page) => page?._id !== deletedPage?._id)
        );
    };

    return (
        <PersonalPageContext.Provider
            value={{
                posts,
                pages,
                setPages,
                getPost,
                deletePostFromMyPosts,
                handleAddPost,
                handleUpdatePost,
                handleRemovePost,
                updatePage,
                addNewPage,
                deletePage,
                loading,
            }}
        >
            {children}
        </PersonalPageContext.Provider>
    );
}

export function usePersonalPage() {
    const context = useContext(PersonalPageContext);
    if (!context) {
        throw new Error("usePersonalPage must be used within a PageProvider");
    }
    return context;
}

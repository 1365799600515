import React, { useState, useEffect } from "react";
import { PersonalPageGrid } from "@features/grid";
import { CreateGemButton, CreateCollectionButton } from "@features/create";
import { Search } from "@features/search";
import { Endpoints } from "@constants";
import { ArchiveFilter } from "@features/archive/components/ArchiveFilter";

function PersonalPageActionSection({
    currentPage,
    setSearchResults,
    isTutorial,
    filterTypes,
    setFilterTypes,
}) {
    return (
        <div className="flex items-center justify-between w-full">
            <div className="flex-1" />
            {!currentPage?.isArchive && (
                <div className="flex-1 flex space-x-2 justify-end mr-2">
                    <CreateGemButton currentPage={currentPage} />
                    <CreateCollectionButton
                        currentPage={currentPage}
                        isTutorial={isTutorial}
                    />
                </div>
            )}
            {currentPage?.isArchive && (
                <Search
                    scope={Endpoints.MY}
                    handleSearchResults={setSearchResults}
                    barText="Search posts..."
                    useURLParams={false}
                />
            )}
            {currentPage?.isArchive && (
                <div className="flex flex-col space-x-2 justify-end mx-2">
                    <ArchiveFilter
                        setFilterTypes={setFilterTypes}
                        filterTypes={filterTypes}
                    />
                </div>
            )}
        </div>
    );
}

function PersonalPageContent({ currentPosts, currentPage, isTutorial }) {
    const [searchResults, setSearchResults] = useState(null);
    const [posts, setPosts] = useState(null);
    const [filterTypes, setFilterTypes] = useState("All");

    useEffect(() => {
        let filteredPosts = currentPosts;

        if (searchResults) {
            filteredPosts = searchResults.posts;
        }

        if (filterTypes && filterTypes !== "All" && filteredPosts) {
            filteredPosts = filteredPosts.filter(
                (post) =>
                    filterTypes.includes(post.type) ||
                    filterTypes.includes(post.status)
            );
        }
        setPosts(filteredPosts);
    }, [currentPosts, searchResults, filterTypes]);

    return (
        <div className="personal-post-content">
            <PersonalPageActionSection
                currentPage={currentPage}
                setSearchResults={setSearchResults}
                isTutorial={isTutorial}
                filterTypes={filterTypes}
                setFilterTypes={setFilterTypes}
            />
            <PersonalPageGrid posts={posts} currentPage={currentPage} />
        </div>
    );
}

export default PersonalPageContent;

import { PostType } from "@constants";

function ImageExtractor({ item, showFullCollectionPost = true }) {
    // Function to extract the first image src from the content blob
    const extractFirstImageSrc = (htmlString) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlString, "text/html");
        const img = doc.querySelector("img");
        return img ? img.src : null;
    };

    const size = showFullCollectionPost ? "w-20 h-20" : "w-16 h-16";
    if (item.type === PostType.GEM) {
        let src = null;
        if (item.metadata) {
            src = item.metadata.image;
        } else {
            src = extractFirstImageSrc(item.content);
        }

        // Render the extracted image, if it exists
        return src ? (
            <div className={size}>
                <img
                    src={src}
                    alt="Extracted from content"
                    className="w-full h-full object-cover"
                />
            </div>
        ) : (
            <div
                className={`${size} flex items-center justify-center ${
                    showFullCollectionPost ? "text-4xl" : "text-3xl"
                }`}
            >
                📝
            </div>
        );
    }

    // if Collection
    return (
        <div
            className={`${size} flex items-center justify-center ${
                showFullCollectionPost ? "text-lg" : "text-base"
            }`}
        >
            🥞📚🎶✨
        </div>
    );
}

export default ImageExtractor;

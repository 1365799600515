import { createContext, useState, useEffect } from "react";
import ProfileService from "@services/ProfileService";
import AuthService from "@services/AuthService";
import { useNavigate } from "react-router-dom";
import useAuth from "@hooks/useAuth";

export const UserContext = createContext({
    userId: null,
    userInfo: null,
    setUserInfo: () => {},
    logout: () => {},
    isAuthenticated: () => {},
    isLoading: false,
    setIsLoading: () => {},
    error: null,
    setError: () => {},
});

export function UserContextProvider({ children }) {
    const [userId, setUserId] = useState(null);
    const [userInfo, setUserInfo] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const { auth, setAuth } = useAuth();
    const navigate = useNavigate();

    function logout() {
        setIsLoading(true);
        AuthService.logout();
        setUserInfo(null);
        setIsLoading(false);
    }

    async function populateUserInfo() {
        try {
            setIsLoading(true);
            const profileResponse = await ProfileService.getCurrentProfile();
            const userInfoFromServer = profileResponse.data;
            setUserId(userInfoFromServer?.id);

            if (userInfoFromServer) {
                const profileData = await ProfileService.getProfile(
                    userInfoFromServer.username
                );

                setUserInfo({
                    username: userInfoFromServer.username,
                    profile: profileData,
                });
            }
        } catch (err) {
            console.error("Error fetching profile");
        } finally {
            setIsLoading(false);
        }
    }

    function isAuthenticated() {
        return Boolean(userInfo);
    }

    useEffect(() => {
        if (!auth) {
            setUserInfo({});
            setUserId({});
            return;
        }
        populateUserInfo();
    }, [auth]);

    useEffect(() => {
        if (userInfo?.profile?.verificationToken) {
            navigate("/onboard");
        }
    }, [userInfo]);

    // Get new profile every hour to refresh expired links
    useEffect(() => {
        const interval = setInterval(() => {
            populateUserInfo();
        }, 86400000);

        return () => clearInterval(interval);
    }, []);

    return (
        <UserContext.Provider
            value={{
                userId,
                userInfo,
                setUserInfo,
                logout,
                isAuthenticated,
                isLoading,
                setIsLoading,
                error,
                setError,
            }}
        >
            {children}
        </UserContext.Provider>
    );
}

// ForgotPasswordForm.js
import React, { useState } from "react";
import { FormButton, FormInput } from "@components/Form";

function ForgotPasswordForm({ onSubmit }) {
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");

    const handleSubmit = (ev) => {
        ev.preventDefault();
        onSubmit({ email });
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="mb-4">
                <FormInput
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(ev) => setEmail(ev.target.value)}
                />
            </div>
            <div className="flex items-center justify-center">
                <FormButton text="Submit" />
            </div>
        </form>
    );
}

export default ForgotPasswordForm;

import { useEffect, useState } from "react";
import { BellAlertIcon } from "@heroicons/react/24/outline";
import NotificationPanel from "./NotificationPanel";
import { useNotificationsHook } from "@contexts/NotificationProvider";
import Dropdown from "@components/Dropdown";
import { NotificationCounter } from "./NotificationCounter";

function NotificationPanelButton({ isActive }) {
    const { notifications } = useNotificationsHook();
    const [unseenCount, setUnseenCount] = useState(0);

    useEffect(() => {
        setUnseenCount(
            notifications.filter((notification) => !notification.seen).length
        );
    }, [notifications]);

    const notificationButton = (
        <>
            <div
                className={`relative px-4 py-2 flex space-x-2 items-center font-semibold rounded-full border-4 border-white hover:border-purple-700 ${
                    isActive ? "border-4 border-purple-700" : ""
                }`}
            >
                <BellAlertIcon className="w-6 h-6" />
            </div>
            <NotificationCounter count={unseenCount} />
        </>
    );

    return (
        <Dropdown
            widthAndPositionStyles="right-0 w-[280px]"
            triggerButton={notificationButton}
        >
            {(setShowDropdown) => (
                <NotificationPanel setShowDropdown={setShowDropdown} />
            )}
        </Dropdown>
    );
}

export default NotificationPanelButton;

import React, { useState } from "react";
import { useModal } from "@contexts/ModalContext";
import { useToast } from "@contexts/ToastContext";
import PostService from "@services/PostService";
import { usePersonalPage } from "@contexts/PersonalPageContext";

function DeleteCollectionModal({ post }) {
    const { closeOverlayModal, closeAllModals } = useModal();
    const [uploading, setUploading] = useState(false);
    const { showToast } = useToast();
    const { deletePostFromMyPosts } = usePersonalPage();
    const [deleteAllItems, setDeleteAllItems] = useState(false);

    const handleCheckboxChange = () => {
        setDeleteAllItems(!deleteAllItems);
    };

    const onDeleteCollection = async () => {
        setUploading(true);

        try {
            const handleDelete = async () => {
                try {
                    const deleteResponse = await PostService.deletePost(
                        post,
                        deleteAllItems
                    );
                    // Update local state
                    deletePostFromMyPosts(post._id, deleteResponse);
                } catch (error) {
                    console.log("Error deleting collection: " + error);
                }
            };

            handleDelete();

            setUploading(false);
            showToast("Collection has been deleted", "bg-rose-700");
        } catch (error) {
            console.error("Failed to delete:", error);
        }

        closeAllModals();
    };

    return (
        <div className="mx-auto w-96 p-4 rounded flex flex-col items-center justify-center text-center">
            <div className="font-title text-bold m-2">Delete collection?</div>
            <div className="flex items-center space-x-4 m-2">
                <input
                    type="checkbox"
                    id="deleteAllItems"
                    checked={deleteAllItems}
                    onChange={handleCheckboxChange}
                    className="h-4 w-4"
                />
                <label htmlFor="deleteAllItems" className="cursor-pointer">
                    Also delete all items within this collection
                </label>
            </div>
            <div className="text-sm italic text-gray-700 text-sm mb-4">
                (This action cannot be undone. However if the item is on a page
                or in another collection, it won't be deleted)
            </div>
            <button
                className="bg-rose-600 hover:bg-rose-800 text-white px-4 py-2 mb-2 rounded-full text-sm font-semibold"
                disabled={uploading}
                onClick={() => onDeleteCollection(false)}
            >
                Yes, delete collection
            </button>
            <button
                className="bg-gray-300 hover:bg-gray-400 hover:bg-opacity-80 px-4 py-2 mb-2 rounded-full text-sm font-semibold"
                onClick={closeOverlayModal}
            >
                Cancel
            </button>
        </div>
    );
}

export default DeleteCollectionModal;

import { Collection, Post } from "@classes/Post";
import PostService from "./PostService";
import { axiosPrivate } from "@api/axios";

class CollectionService {
    static async createCollection(
        title,
        description,
        items,
        privacyLevel,
        status,
        pageId
    ) {
        try {
            const response = await axiosPrivate.post("/collection", {
                title,
                description,
                items,
                privacyLevel,
                status,
                pageId,
            });
            return this.getCollectionFromData(response.data.data);
        } catch (error) {
            console.error("Failed to create collection:", error);
            throw new Error(
                error.response
                    ? error.response.data.error
                    : "Failed to create collection."
            );
        }
    }

    static async updateCollection(id, updateData) {
        try {
            const response = await axiosPrivate.put(
                `/collection/${id}`,
                updateData
            );

            return this.getCollectionFromData(response.data.data);
        } catch (error) {
            console.error("Failed to update collection:", error);
            throw new Error(
                error.response
                    ? error.response.data.error
                    : "Failed to update collection."
            );
        }
    }

    static async getCollection(id, existingCollection = null) {
        if (
            existingCollection &&
            this.hasAlreadyPopulated(existingCollection)
        ) {
            return existingCollection;
        }

        try {
            const response = await axiosPrivate.get(`/collection/${id}`);

            return this.getCollectionFromData(response.data.data);
        } catch (error) {
            console.error("Failed to fetch collection:", error);
            throw new Error(
                error.response
                    ? error.response.data.error
                    : "Failed to fetch collection."
            );
        }
    }

    static async getCollections() {
        try {
            const response = await axiosPrivate.get(`/collection`);

            return this.getCollectionsFromData(response.data.data);
        } catch (error) {
            console.error("Failed to fetch collections:", error);
            throw new Error(
                error.response
                    ? error.response.data.error
                    : "Failed to fetch collections."
            );
        }
    }

    static getCollectionFromData(collectionData) {
        const items = collectionData.items.map((collectionItem) =>
            PostService.getPostFromData(collectionItem)
        );

        return new Collection({
            _id: collectionData._id,
            title: collectionData.title,
            description: collectionData.description,
            author: collectionData.author,
            createdAt: collectionData.createdAt,
            updatedAt: collectionData.updatedAt,
            items: items,
            privacyLevel: collectionData.privacyLevel,
            likeCount: collectionData.likeCount,
            commentCount: collectionData.commentCount,
            status: collectionData.status,
            isLoading: false,
        });
    }

    static getCollectionsFromData(collectionsData) {
        return collectionsData.map((collectionData) =>
            this.getCollectionFromData(collectionData)
        );
    }

    static hasAlreadyPopulated(collection) {
        return collection?.items?.some((item) => item instanceof Post);
    }
}

export default CollectionService;

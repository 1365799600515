import React, { useContext, useEffect } from "react";
import { UserContext } from "@contexts/UserContext";
import { usePersonalPage } from "@contexts/PersonalPageContext";
import { useToast } from "@contexts/ToastContext";
import { useModal } from "@contexts/ModalContext";
import { Gem } from "@classes/Post";
import { useGemForm } from "../hooks/useGemForm";
import GemForm from "./GemForm";
import GemService from "@services/GemService";

function CreateGem({ postControl }) {
    const { userInfo } = useContext(UserContext);

    const { handleSubmitPostRef } = postControl;
    const gemFormControl = useGemForm(postControl);
    const { getGemForm } = gemFormControl;

    const { showToast } = useToast();
    const { closeModal } = useModal();
    const { handleAddPost, handleUpdatePost, handleRemovePost } =
        usePersonalPage();

    const handleSubmitCreateGem = async (
        currPost,
        isDraft,
        currEditorContent,
        page
    ) => {
        const tempGem = Gem.getLoadingGem(userInfo.profile);

        try {
            handleAddPost(tempGem, page);
            closeModal(); // Close the create post modal

            const gemForm = await getGemForm(
                isDraft,
                currPost,
                currEditorContent,
                page?._id
            );

            const newGem = await GemService.createGem(gemForm);
            if (newGem) {
                handleUpdatePost(newGem, tempGem._id, page);
                showToast(`New gem created!`, "bg-emerald-600");
            } else {
                handleRemovePost(tempGem, page);
                showToast("Failed to create post", "bg-rose-600");
            }
        } catch (error) {
            console.error("Error creating gem:", error);
            handleRemovePost(tempGem, page);
            showToast("Failed to create post", "bg-rose-600");
        }
    };

    useEffect(() => {
        handleSubmitPostRef.current = handleSubmitCreateGem;
    }, []);

    return <GemForm postControl={postControl} />;
}

export default CreateGem;

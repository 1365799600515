import React from "react";
import { AdjustmentsHorizontalIcon } from "@heroicons/react/24/outline";
import Dropdown from "@components/Dropdown";
import { PostType, PostStatus } from "@constants";

function ArchiveFilterOption({ children, onClick, isActive, color }) {
    const filterOptionClasses = `${color} px-4 py-2 w-full font-bold flex justify-center rounded-full hover:scale-110 m-1`;

    return (
        <button
            onClick={onClick}
            className={`${filterOptionClasses} ${
                isActive ? "border-2 border-black" : ""
            } `}
        >
            {children}
        </button>
    );
}

function ArchiveFilterTriggerButton() {
    return (
        <div className="rounded-full bg-indigo-200 hover:shadow-lg hover:scale-105 text-indigo-900 flex space-x-2 items-center font-bold py-2 px-4">
            <div className="flex space-x-1 items-center">
                <AdjustmentsHorizontalIcon className="w-4 h-4 transition-none justify-center" />
                <div className="">Filter</div>
            </div>
        </div>
    );
}

export function ArchiveFilter({ filterTypes, setFilterTypes }) {
    const archiveFilterButton = <ArchiveFilterTriggerButton />;

    const handleClick = (setShowDropdown, filter) => (e) => {
        e.preventDefault();
        e.stopPropagation();
        setFilterTypes(filter);
        setShowDropdown((showDropdown) => !showDropdown);
    };

    return (
        <Dropdown
            widthAndPositionStyles="right-0 w-[180px]"
            triggerButton={archiveFilterButton}
            shouldDetectOutsideClick={true}
        >
            {(setShowDropdown) => (
                <div className="archive-filter-options block absolute right-0 w-44 z-50">
                    <ArchiveFilterOption
                        onClick={handleClick(
                            setShowDropdown,
                            PostType.COLLECTION
                        )}
                        isActive={PostType.COLLECTION === filterTypes}
                        color="bg-blue-200 text-blue-900"
                    >
                        Collections Only
                    </ArchiveFilterOption>
                    <ArchiveFilterOption
                        onClick={handleClick(setShowDropdown, PostType.GEM)}
                        isActive={PostType.GEM === filterTypes}
                        color="bg-purple-200 text-purple-900"
                    >
                        Gems Only
                    </ArchiveFilterOption>
                    <ArchiveFilterOption
                        onClick={handleClick(
                            setShowDropdown,
                            `${PostStatus.DRAFT} ${PostStatus.REVIEW}`
                        )}
                        isActive={PostStatus.DRAFT === filterTypes}
                        color="bg-yellow-200 text-yellow-900"
                    >
                        Drafts Only
                    </ArchiveFilterOption>
                    <ArchiveFilterOption
                        onClick={handleClick(setShowDropdown, "All")}
                        isActive={"All" === filterTypes}
                        color="bg-indigo-200 text-indigo-900"
                    >
                        Show All
                    </ArchiveFilterOption>
                </div>
            )}
        </Dropdown>
    );
}

import { axiosPrivate } from "@api/axios";

class NotificationService {
    static async getNotifications(lastTimestamp) {
        try {
            let response;
            if (lastTimestamp) {
                response = await axiosPrivate.get(`/notifications`, {
                    params: {
                        lastTimestamp: lastTimestamp,
                    },
                });
            } else {
                response = await axiosPrivate.get(`/notifications`);
            }

            return response.data.data;
        } catch (error) {
            console.error("Failed to create page:", error);
            throw new Error(
                error.response
                    ? error.response.data.error
                    : "Failed to create page."
            );
        }
    }

    static async markNotificationsSeen(id) {
        try {
            const response = await axiosPrivate.post(`/notifications`, {
                id,
            });

            return response.data.data;
        } catch (error) {
            console.error("Failed to mark seen:", error);
            throw new Error(
                error.response
                    ? error.response.data.error
                    : "Failed to mark seen."
            );
        }
    }

    static async getFollowingPostCount() {
        try {
            let response;
            response = await axiosPrivate.get(
                `/notifications/followingPostCount`
            );

            return response.data.data;
        } catch (error) {
            throw new Error(
                error.response
                    ? error.response.data.error
                    : "Failed to get following post count."
            );
        }
    }

    static async resetFollowingPostCount() {
        try {
            let response;
            response = await axiosPrivate.post(
                `/notifications/followingPostCount`
            );

            return response.data.data;
        } catch (error) {
            throw new Error(
                error.response
                    ? error.response.data.error
                    : "Failed to reset following post count."
            );
        }
    }

    static getNotificationFromData(notification) {}

    static getNotificationsFromData(notifications) {
        return notifications.map((notification) =>
            this.getNotificationFromData(notification)
        );
    }
}

export default NotificationService;

import React from "react";
import { ArrowLeftIcon } from "@heroicons/react/24/solid"; // Import the ChevronLeftIcon from Heroicons

const BackButton = () => {
    const goBack = () => {
        window.history.back();
    };

    return (
        <button
            onClick={goBack}
            className="flex items-center justify-center text-purple-700"
        >
            <ArrowLeftIcon className="w-6 h-6" />
        </button>
    );
};

export default BackButton;

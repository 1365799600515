// RegistrationForm.js
import React, { useState } from "react";
import { FormButton, FormInput } from "@components/Form";

function RegistrationForm({ onSubmit }) {
    const [token, setToken] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");

    const handleSubmit = async (ev) => {
        ev.preventDefault();
        onSubmit({ username, password, name, email, token });
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="mb-4">
                <FormInput
                    type="text"
                    placeholder="Invite code"
                    value={token}
                    onChange={(ev) => setToken(ev.target.value)}
                />
            </div>
            <div className="mb-4">
                <FormInput
                    type="text"
                    placeholder="Username"
                    value={username}
                    onChange={(ev) => setUsername(ev.target.value)}
                />
            </div>
            <div className="mb-4">
                <FormInput
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(ev) => setPassword(ev.target.value)}
                />
            </div>
            <div className="mb-4">
                <FormInput
                    type="text"
                    placeholder="Name"
                    value={name}
                    onChange={(ev) => setName(ev.target.value)}
                />
            </div>
            <div className="mb-4">
                <FormInput
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(ev) => setEmail(ev.target.value)}
                />
            </div>
            <div className="flex items-center justify-center">
                <FormButton text="Register" />
            </div>
        </form>
    );
}

export default RegistrationForm;

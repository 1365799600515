import React from "react";
import { PostContent } from "@features/post";
import { NonDraggableCollectionContent } from "..";

function CollectionGridContent({ collection }) {
    return (
        <div className="collection-grid-post">
            <PostContent post={collection}>
                <NonDraggableCollectionContent
                    collection={collection}
                    allowNavToCollectionItem={false}
                    showCollectionRowOptions={false}
                />
            </PostContent>
        </div>
    );
}

export default CollectionGridContent;

import { LandingPageBase } from "@features/landing";
import { Link } from "react-router-dom";
import { WaitlistPanel, EarlyAccessLinkPanel } from "@features/waitlist";
import AutoScroller from "@features/landing/components/AutoScrollingText";
import { Logo, SparkleLogo } from "@components/Logo";

function LandingWaitlistPage() {
    return (
        <LandingPageBase center={false}>
            <div className="overflow-hidden bg-white shadow-xl w-full p-4 relative z-10 h-screen min-h-screen sm:w-5/12 ">
                <div className="h-1/3">
                    <Link to={"/"}>
                        <div className="text-3xl">
                            <SparkleLogo />
                        </div>
                    </Link>
                </div>
                <div className="flex flex-col items-center justify-start h-2/3">
                    <div className="w-2/3 ">
                        <div className="pt-5 ">
                            <div className="font-logo text-4xl text-black relative pb-10 flex space-x-2 items-center justify-center">
                                Welcome to <Logo />
                            </div>
                            <WaitlistPanel />
                        </div>
                        <div className="pt-10 pb-5">
                            <hr className="w-full "></hr>
                        </div>
                        <div>
                            <EarlyAccessLinkPanel />
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full sm:w-7/12 flex flex-col items-center h-screen min-h-screen">
                <div className="h-1/3"></div>
                <div className="text-left w-2/3 h-1/3 flex flex-col justify-evenly">
                    <div className="text-white font-semibold text-4xl">
                        What good are your best lists doing hiding in your Notes
                        app?
                    </div>
                    <div className="text-white font-other font-bold text-5xl">
                        Share your gems.
                    </div>
                </div>
                <div className="h-1/3 w-full flex flex-col justify-center">
                    <AutoScroller />
                </div>
            </div>
        </LandingPageBase>
    );
}

export { LandingWaitlistPage };

import React, { useState } from "react";
import { PostEditState } from "@constants";

export const usePostEditState = (state) => {
    const [currentState, setCurrentState] = useState(state);
    const [stateHistory, setStateHistory] = useState([]);

    const getNextState = () => {
        switch (currentState) {
            case PostEditState.Create:
            case PostEditState.Edit:
                return PostEditState.Preview;
                break;
        }
    };

    const handleNextState = () => {
        setStateHistory((prevHistory) => [...prevHistory, currentState]);
        const nextState = getNextState();
        setCurrentState(nextState);
    };

    const handlePreviousState = () => {
        setStateHistory((prevHistory) => {
            const history = [...prevHistory];
            if (history.length > 0) {
                const lastState = history.pop();
                setCurrentState(lastState);
            }
            return history;
        });
    };

    const hasPreviousState = stateHistory?.length > 0;

    const getPreviousState = () => {
        if (stateHistory.length >= 1) {
            return stateHistory[stateHistory.length - 1];
        }
    };

    const mergePostEditState = (savedState) => {
        setCurrentState(savedState?.currentState);
        setStateHistory(savedState?.stateHistory);
    };

    return {
        currentState,
        stateHistory,
        getNextState,
        handleNextState,
        handlePreviousState,
        hasPreviousState,
        getPreviousState,
        mergePostEditState,
    };
};

import React from "react";

function PageDisplay({ SidePanel, isSidePanelOpen, isLoading, children }) {
    return (
        <div className="flex">
            <div
                className={`fixed top-16 bottom-0 left-0 overflow-y-auto h-screen z-10 ${
                    isSidePanelOpen ? "md:w-60 md:min-w-[15rem]" : "hidden"
                }`}
            >
                {isSidePanelOpen && (
                    <div className="md:hidden absolute top-0 bottom-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 overflow-y-auto"></div>
                )}
                <div className="fixed top-0 bottom-0 left-0 w-60 mt-16 bg-gray-100 overflow-y-auto transition-all">
                    {!isLoading && <SidePanel />}
                </div>
            </div>
            <div
                className={`flex-grow px-3 ${
                    isSidePanelOpen ? "md:ml-60" : "ml-0 w-full"
                }`}
            >
                {children}
            </div>
        </div>
    );
}

export default PageDisplay;

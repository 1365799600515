import { createContext, useState, useContext } from "react";
import Header from "@layouts/Header";
import useSidePanel from "@hooks/useSidePanel";

const HeaderContext = createContext();

export function HeaderProvider({ children }) {
    const [activePage, setActivePage] = useState("");
    const { isSidePanelOpen, toggleSidePanel } = useSidePanel();

    return (
        <HeaderContext.Provider
            value={{
                activePage,
                setActivePage,
                isSidePanelOpen,
            }}
        >
            <Header
                isSidePanelOpen={isSidePanelOpen}
                toggleSidePanel={toggleSidePanel}
                activePage={activePage}
                setActivePage={setActivePage}
            />
            {children}
        </HeaderContext.Provider>
    );
}

export function useHeader() {
    const context = useContext(HeaderContext);
    if (!context) {
        throw new Error("useHeader must be used within a HeaderProvider");
    }
    return context;
}

import { useEffect } from "react";

const useOutsideClick = (ref, callback, shouldDetectOutsideClick = true) => {
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                callback();
            }
        };

        if (shouldDetectOutsideClick) {
            document.addEventListener("mousedown", handleClickOutside);
            return () =>
                document.removeEventListener("mousedown", handleClickOutside);
        }
    }, [ref, callback]);
};

export default useOutsideClick;

// LoginForm.js
import React, { useState } from "react";
import { FormButton, FormInput } from "@components/Form";
import LoadingComponent from "@components/LoadingComponent";

function LoginForm({ onLogin, isLoading }) {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const handleSubmit = async (ev) => {
        ev.preventDefault();
        onLogin({ username, password });
    };

    if (isLoading) {
        return <LoadingComponent />;
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="mb-4">
                <FormInput
                    type="text"
                    placeholder="username or email"
                    value={username}
                    onChange={(ev) => setUsername(ev.target.value)}
                    autoComplete="username"
                />
            </div>
            <div className="mb-4">
                <FormInput
                    type="password"
                    placeholder="password"
                    value={password}
                    onChange={(ev) => setPassword(ev.target.value)}
                    autoComplete="current-password"
                />
            </div>
            <div className="flex items-center justify-center">
                <FormButton text="Login" />
            </div>
        </form>
    );
}

export default LoginForm;

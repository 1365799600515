// UserPageSteps.js
const UserPageSteps = ({ setStepIndex, setPage }) => [
    {
        target: "body",
        content: "Welcome to Gems 🌟 Let's get started!",
        placement: "center",
        disableBeacon: true,
        hideBackButton: true,
        action: () => {
            setStepIndex((i) => i + 1);
        },
    },
    {
        target: ".grid-post-item-gem",
        content:
            "A gem is a piece of content you want to share. Gems can be links, text, or photos.",
        placement: "bottom",
        disableBeacon: true,
        back: () => {
            setStepIndex((i) => i - 1);
        },
        action: () => {
            setStepIndex((i) => i + 1);
        },
    },
    {
        target: ".grid-post-item-collection",
        content:
            'Collections contain many gems. Create collections like "my favorite recipes" or "best restaurants in nyc".',
        placement: "right",
        disableBeacon: true,
        back: () => {
            setStepIndex((i) => i - 1);
        },
        action: () => {
            setStepIndex((i) => i + 1);
        },
    },
    {
        target: ".personal-page-profile-button",
        content: "Now, let's go to your page.",
        placement: "bottom",
        disableBeacon: true,
        back: () => {
            setStepIndex((i) => i - 1);
        },
        action: () => {
            setPage("personal");
            setStepIndex((i) => i + 1);
        },
    },
];

export default UserPageSteps;

import React, { useContext } from "react";
import LikeButton from "./LikeButton";
import LikeCount from "./LikeCount";
import useLike from "../hooks/useLike";
import { UserContext } from "@contexts/UserContext";

function LikePanel({ post }) {
    const { userInfo } = useContext(UserContext);
    const { likes, isLiked, handleLikeToggle } = useLike(post);

    return (
        <div className="like-panel mb-2 flex items-center w-full text-sm">
            {userInfo ? (
                <LikeButton
                    isLiked={isLiked}
                    handleLikeToggle={handleLikeToggle}
                />
            ) : (
                <></>
            )}
            <LikeCount likes={likes} />
        </div>
    );
}

export default LikePanel;

import React from "react";
import Joyride, { ACTIONS } from "react-joyride";

function TutorialJoyride({ children, handleTourEnd, stepIndex, steps }) {
    const customTooltipStyles = {
        buttonNext: {
            // Customize the "Next" button style
            backgroundColor: "purple",
            // Add more styles as needed
        },
        buttonBack: {
            color: "purple",
        },
    };

    return (
        <div>
            {children}
            <Joyride
                steps={steps}
                stepIndex={stepIndex}
                styles={customTooltipStyles}
                continuous
                showProgress
                showSkipButton
                disableScrolling
                disableOverlayClose
                spotlightClicks={false}
                run // Start the tour automatically
                callback={async ({
                    type,
                    step,
                    skipped,
                    controlled,
                    action,
                }) => {
                    if (
                        type === "tour:end" ||
                        (type === "step:after" &&
                            step.index === steps.length - 1 &&
                            !skipped)
                    ) {
                        handleTourEnd();
                    }

                    if (type === "step:after") {
                        if (action === ACTIONS.PREV && step.back) {
                            await step.back();
                        } else if (action === ACTIONS.NEXT && step.action) {
                            await step.action();
                        }
                    }
                }}
            />
        </div>
    );
}

export default TutorialJoyride;

function Button({ onClick, colors, disabled, children, className }) {
    return (
        <button
            className={`${className} flex items-center px-4 py-2 text-sm rounded-full ${
                colors ?? "text-gray-200 hover:text-gray-300"
            }`}
            onClick={(e) => {
                e.preventDefault();
                onClick();
            }}
            disabled={disabled}
        >
            {children}
        </button>
    );
}

export default Button;

function SplitContentRendererFrame({
    gem,
    isContent = false,
    previewTitle = null,
    previewImage = null,
    previewText = null,
    children,
}) {
    const hasTextContent = previewText || children;

    const imgSizeClass = getImageSizeClass(isContent, hasTextContent);
    const contentSizeClass = getContentSizeClass(isContent, previewImage);

    return (
        <div
            className={`flex rounded-md ${
                isContent
                    ? "preview-container bg-gray-200 group-hover:bg-gray-300"
                    : "preview-container bg-gray-200 group-hover:bg-gray-300"
            }`}
        >
            {previewImage && (
                <ImageSection
                    imgSizeClass={imgSizeClass}
                    imageSrc={previewImage}
                    hasTextContent={hasTextContent}
                    gemTitle={gem.title}
                />
            )}
            {hasTextContent && (
                <ContentSection
                    contentSizeClass={contentSizeClass}
                    previewTitle={previewTitle}
                    previewText={previewText}
                >
                    {children}
                </ContentSection>
            )}
        </div>
    );
}

function getImageSizeClass(isContent, hasTextContent) {
    if (isContent) {
        return hasTextContent && "w-1/5";
    }

    return hasTextContent && "w-1/3 max-h-[200px]";
}

function getContentSizeClass(isContent, previewImage) {
    if (isContent) {
        return previewImage && "w-2/3";
    }

    return previewImage ? "w-2/3" : "max-h-[200px]";
}

function ImageSection({ imgSizeClass, imageSrc, hasTextContent, gemTitle }) {
    return (
        <div className={`${imgSizeClass}`}>
            <img
                className={`${
                    hasTextContent && "w-full h-full object-cover rounded-l-md"
                }`}
                src={imageSrc}
                alt={gemTitle}
            />
        </div>
    );
}

function ContentSection({
    contentSizeClass,
    previewTitle,
    previewText,
    children,
}) {
    return (
        <div className={`${contentSizeClass} m-4`}>
            {previewTitle && (
                <div className="font-semibold text-oversize-1">
                    {previewTitle}
                </div>
            )}
            {previewText && <p className="text-oversize-5">{previewText}</p>}
            {children}
        </div>
    );
}

export { SplitContentRendererFrame };

import React from "react";
import { PostEditState, PostType } from "@constants";
import { usePost, usePostEditState, EditPostState } from "@features/create";

function EditGemWrapper({ post = null }) {
    const postControl = usePost(PostType.GEM, post);
    const postEditState = usePostEditState(PostEditState.Edit);

    return (
        <EditPostState
            postControl={postControl}
            postEditState={postEditState}
        />
    );
}

export default EditGemWrapper;

import React, { useState, useEffect, useRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { debounce } from "lodash";

const modules = {
    toolbar: [
        [{ header: [1, 2, false] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        // [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "image"],
        ["clean"],
    ],
    imageResize: {
        modules: ["Resize", "DisplaySize"],
    },
};

const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
];

function Editor({ value, onChange, setEditorContent }) {
    const quillRef = useRef(null);
    const [lastKnownSelection, setLastKnownSelection] = useState(null);

    useEffect(() => {
        if (!quillRef.current) return;

        // Track the last position of the selection
        const editor = quillRef.current.getEditor();
        const handleSelectionChange = debounce((range) => {
            if (range) {
                setLastKnownSelection(range);
            }
        }, 300);

        editor.on("selection-change", handleSelectionChange);

        return () => {
            editor.off("selection-change", handleSelectionChange);
        };
    }, []);

    useEffect(() => {
        const positionTooltip = () => {
            const editor = quillRef.current.getEditor();
            const tooltip = document.querySelector(".ql-tooltip");

            if (
                lastKnownSelection &&
                tooltip &&
                !tooltip.classList.contains("ql-hidden")
            ) {
                const rect = tooltip.getBoundingClientRect();
                const tooltipWidth = rect.width;

                const bounds = editor.getBounds(lastKnownSelection.index);
                const editorWidth = quillRef.current.editor.root.offsetWidth;

                let left = bounds.left;
                let top = bounds.top;

                // Check if the tooltip extends past the right edge of the editor
                if (left > editorWidth - tooltipWidth) {
                    // Align the tooltip's right edge with the editor's right edge
                    tooltip.style.left = `${editorWidth - tooltipWidth}px`;
                    tooltip.style.right = "auto";
                } else {
                    // Position the tooltip based on the selection bounds
                    tooltip.style.left = `${left}px`;
                    tooltip.style.right = "auto"; // Reset right property
                }

                // Apply the top position with some padding
                tooltip.style.top = `${top + 10}px`;
            }
        };

        // Listener for the link button
        const linkButton = document.querySelector(".ql-link");
        if (linkButton) {
            linkButton.addEventListener("click", positionTooltip);
        }

        return () => {
            if (linkButton) {
                linkButton.removeEventListener("click", positionTooltip);
            }
        };
    }, [lastKnownSelection]);

    const handleChange = (newValue, delta, source, editor) => {
        if (source === "user") {
            onChange(newValue);
            const content = editor.getHTML();
            setEditorContent(content);
        }
    };

    return (
        <div className="relative overflow-hidden">
            <ReactQuill
                value={value}
                placeholder={"Add text or images (optional)"}
                onChange={handleChange}
                modules={modules}
                formats={formats}
                ref={quillRef}
                style={{ backgroundColor: "white" }}
            />
        </div>
    );
}

export default Editor;

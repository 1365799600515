// Tutorial.js
import React, { useState, useLayoutEffect, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Endpoints } from "@constants/constants";
import { usePosts } from "@hooks/usePosts";
import { ExploreDisplay } from "@features/explore";
import UserPageDisplay from "@features/userPage/components/UserPageDisplay";
import ProfileService from "@services/ProfileService";
import TutorialJoyride from "./TutorialJoyride";
import { PersonalPageDisplay } from "@features/personalPage";
import { UserContext } from "@contexts/UserContext";
import friendPosts from "../data/friendPostData";
import TutorialSteps from "./TutorialSteps";
import { PATHS } from "@features/routing";
import { useHeader } from "@contexts/HeaderContext";

function Tutorial() {
    const [profileLoading, setProfileLoading] = useState(true);
    const [profile, setProfile] = useState(null);
    const [stepIndex, setStepIndex] = useState(0);
    const [page, setPage] = useState("user");
    const { userInfo, setUserInfo } = useContext(UserContext);
    const { isSidePanelOpen } = useHeader();

    const [endpoint, setEndpoint] = useState(Endpoints.ALL);
    const { posts, loading } = usePosts({ endpoint: endpoint });
    const [pageLoading, setPageLoading] = useState(true);

    const navigate = useNavigate();

    useLayoutEffect(() => {
        const fetchProfile = async () => {
            try {
                const profileData = await ProfileService.getProfile("gems");
                setProfile(profileData);
                setProfileLoading(false);
            } catch (error) {
                console.error("Error fetching profile:", error);
            }
        };

        fetchProfile();
    }, []);

    const handleTourEnd = async () => {
        await ProfileService.completeOnboard(userInfo?.username);
        setUserInfo((prevUserInfo) => {
            return {
                ...prevUserInfo,
                profile: {
                    ...prevUserInfo.profile,
                    verificationToken: undefined,
                },
            };
        });
        navigate(PATHS.getUserPath(userInfo?.username));
    };

    const steps = TutorialSteps({
        setStepIndex,
        setPage,
    });

    return (
        <>
            {page === "user" && profile ? (
                <UserPageDisplay
                    username={profile.username}
                    profile={profile}
                    loading={profileLoading}
                    isSidePanelOpen={isSidePanelOpen}
                    setPageLoading={setPageLoading}
                />
            ) : page === "personal" ? (
                <PersonalPageDisplay
                    userInfo={userInfo}
                    setUserInfo={setUserInfo}
                    isSidePanelOpen={isSidePanelOpen}
                    isTutorial={true}
                />
            ) : page === "explore" ? (
                <ExploreDisplay
                    posts={endpoint === "friends" ? friendPosts : posts}
                    loading={loading}
                    isSidePanelOpen={isSidePanelOpen}
                />
            ) : null}
            {!pageLoading && (
                <TutorialJoyride
                    handleTourEnd={handleTourEnd}
                    stepIndex={stepIndex}
                    steps={steps}
                ></TutorialJoyride>
            )}
        </>
    );
}

export default Tutorial;

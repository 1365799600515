function OverlayModal({ ContentComponent, props }) {
    return (
        <>
            <div className="absolute inset-0 bg-black opacity-50 z-20"></div>
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-40">
                <div className="bg-gray-100 relative rounded-lg shadow-lg z-50 overflow-y-auto w-150 h-50">
                    <ContentComponent {...props} />
                </div>
            </div>
        </>
    );
}

export { OverlayModal };

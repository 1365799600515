import React from "react";
import { LandingPageBase } from "@features/landing";
import RegisterPanel from "@features/authentication/components/RegisterPanel";

function RegisterPage() {
    return (
        <LandingPageBase>
            <div className="md:w-1/3">
                <RegisterPanel />
            </div>
        </LandingPageBase>
    );
}

export default RegisterPage;

import { CreateEmbedRenderer } from "../EmbedRenderer";
import DefaultLinkRenderer from "./DefaultLinkRenderer";

const iframeProps = {
    width: "100%",
    height: "100%",
    scrolling: "no",
    frameBorder: "no",
    allow: "autoplay",
};

function getEmbedURL(url) {
    return `https://w.soundcloud.com/player/?url=${encodeURIComponent(
        url
    )}&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true`;
}

const SoundCloudRenderer = {
    detect: (url) => {
        return /^https:\/\/soundcloud\.com\/[\w-]+\/[\w-]+(\?.*)?$/.test(url);
    },
    content: (gem) => {
        return CreateEmbedRenderer(gem, getEmbedURL, iframeProps);
    },

    title: (gem) => {
        return <>🎵 {gem.title}</>;
    },
};

export default SoundCloudRenderer;

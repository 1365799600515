import { useEffect } from "react";
import useAuth from "@hooks/useAuth";
import axios, { axiosPrivate } from "@api/axios";
import useRefreshToken from "@hooks/useRefreshToken";

function AxiosProvider() {
    const { auth } = useAuth();
    const refresh = useRefreshToken();

    function isFormDataRequest(config) {
        return config.headers["Content-Type"] === "multipart/form-data";
    }
    async function getTokenForFormDataRequests(token) {
        let accessToken = token;
        try {
            await axios.get("users/checkToken", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
        } catch (err) {
            accessToken = await refresh();
        }
        return accessToken;
    }

    useEffect(() => {
        const requestIntercept = axiosPrivate.interceptors.request.use(
            async (config) => {
                let accessToken = auth?.accessToken;
                if (isFormDataRequest(config)) {
                    accessToken = await getTokenForFormDataRequests(
                        accessToken
                    );
                }

                if (!config.headers["Authorization"] && accessToken) {
                    config.headers["Authorization"] = `Bearer ${accessToken}`;
                }
                if (!accessToken) {
                    delete config.headers["Authorization"];
                }
                return config;
            },
            (error) => Promise.reject(error)
        );

        const responseIntercept = axiosPrivate.interceptors.response.use(
            (response) => response,
            async (error) => {
                const prevRequest = error?.config;
                if (error?.response?.status === 401 && !prevRequest?.sent) {
                    prevRequest.sent = true;
                    const newAccessToken = await refresh();
                    prevRequest.headers[
                        "Authorization"
                    ] = `Bearer ${newAccessToken}`;

                    return axiosPrivate(prevRequest);
                }
                return Promise.reject(error);
            }
        );

        return () => {
            axiosPrivate.interceptors.request.eject(requestIntercept);
            axiosPrivate.interceptors.response.eject(responseIntercept);
        };
    }, [auth, refresh]);

    return null;
}

export default AxiosProvider;

import React, { useEffect, useState } from "react";
import LinkInput from "./LinkInput";
import { ContentType } from "@constants";

const getInputType = (post) => {
    switch (post.contentType) {
        case ContentType.BOOK:
            return ContentType.BOOK;
        case ContentType.MOVIE:
            return ContentType.MOVIE;
        default:
            return ContentType.LINK;
    }
};
function MediaInput({ post, handleLinks, setIsLinkLoading }) {
    const [selectedInputType, setSelectedInputType] = useState(
        getInputType(post)
    );

    return (
        <LinkInput
            post={post}
            handleLinks={handleLinks}
            setIsLinkLoading={setIsLinkLoading}
            selectedInputType={selectedInputType}
            setSelectedInputType={setSelectedInputType}
        />
    );
}

export default MediaInput;

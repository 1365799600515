import React from "react";
import ProfileTile from "./ProfileTile";
import { ProfileLink } from "@features/routing";

function ClickableProfile({ profile }) {
    return (
        <div className="bg-gray-100 hover:bg-gray-200 transition duration-300 ease-in-out rounded p-4">
            <ProfileLink profile={profile}>
                <ProfileTile profile={profile} showFollowButton={false} />
            </ProfileLink>
        </div>
    );
}

export default ClickableProfile;

import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "@contexts/UserContext";
import PostService from "@services/PostService";
import CollectionService from "@services/CollectionService";
import { CollectionPost } from "@features/collection";
import { ModalType } from "@constants/constants";
import { useModal } from "@contexts/ModalContext";

function CollectionsContainingPostPanel({ post }) {
    const { userInfo } = useContext(UserContext);
    const [collections, setCollections] = useState([]);
    const [loading, setLoading] = useState(true);
    const { pushContentToModal } = useModal();

    useEffect(() => {
        const fetchCollections = async () => {
            try {
                const fetchedCollections =
                    await PostService.getCollectionsContainingPost(post._id);
                setCollections(fetchedCollections);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching collections:", error);
                setLoading(false);
            }
        };

        if (userInfo && post._id) {
            fetchCollections();
        }
    }, [post]);

    const handleCollectionClick = (collection) => {
        const collectionItem =
            CollectionService.getCollectionFromData(collection);

        pushContentToModal(
            CollectionPost,
            collectionItem.title,
            {},
            { collection: collectionItem },
            ModalType.VIEW
        );
    };

    if (!loading && collections.length > 0) {
        return (
            <div className="mt-10">
                <div className="text-sm">In collections:</div>
                {collections.map((collection) => (
                    <div
                        key={collection._id}
                        className="text-purple-800 font-semibold text-sm"
                    >
                        <Link
                            key={collection._id}
                            onClick={() => handleCollectionClick(collection)}
                        >
                            {collection.title}
                        </Link>
                    </div>
                ))}
            </div>
        );
    }

    return <></>;
}

export default CollectionsContainingPostPanel;

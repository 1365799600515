import React from "react";
import { useModal } from "@contexts/ModalContext";
import { useToast } from "@contexts/ToastContext";
import PageService from "@services/PageService";
import { PageLabel } from "./PageSidePanel";
import { PageTitleInput } from "./PageTitleInput";
import DeleteConfirmationModal from "@components/DeleteConfirmationModal";
import {
    PencilIcon,
    TrashIcon,
    ArrowTopRightOnSquareIcon,
} from "@heroicons/react/24/outline";
import { OptionButton, OptionsButton } from "@components/OptionsButton";

function EditPageButton({ handleClickEditButton, setShowOptions }) {
    const handleClick = () => {
        setShowOptions(false);
        handleClickEditButton();
    };
    return (
        <OptionButton
            Icon={PencilIcon}
            onClick={handleClick}
            title={"Rename"}
        />
    );
}

const DeletePageModal = ({ page, handleDeletePage }) => {
    const onDeletePage = async () => {
        try {
            await PageService.deletePage(page._id);
            // Update local state
            handleDeletePage(page);
        } catch (error) {
            console.error("Error deleting page: " + error);
        }
    };

    return (
        <DeleteConfirmationModal
            onDelete={onDeletePage}
            deleteMessage={"Delete page?"}
        />
    );
};

function DeletePageButton({ page, handleDeletePage, setShowOptions }) {
    const { openOverlayModal } = useModal();
    const deletePage = () => {
        openOverlayModal(DeletePageModal, {
            page: page,
            handleDeletePage: handleDeletePage,
        });
        setShowOptions(false);
    };

    return (
        <OptionButton Icon={TrashIcon} onClick={deletePage} title={"Delete"} />
    );
}

const CopyPageLinkToClipboardButton = ({
    page,
    handleCopyLinkToPage,
    setShowOptions,
}) => {
    const { showToast } = useToast();

    const handleCopy = async () => {
        try {
            await handleCopyLinkToPage(page);
            showToast("Link to page copied to clipboard", "bg-emerald-600");
            setShowOptions(false);
        } catch (error) {
            console.log("Error copying link: " + error);
        }
    };

    return (
        <OptionButton
            Icon={ArrowTopRightOnSquareIcon}
            onClick={handleCopy}
            title={"Copy link"}
        />
    );
};

function PageOptions({
    page,
    handleEditButtonClick,
    handleUpdatePage,
    handleDeletePage,
    setShowOptions,
    handleCopyLinkToPage,
}) {
    return (
        <div className="text-700">
            {handleUpdatePage && (
                <EditPageButton
                    handleClickEditButton={handleEditButtonClick}
                    setShowOptions={setShowOptions}
                />
            )}
            {handleDeletePage && (
                <DeletePageButton
                    page={page}
                    handleDeletePage={handleDeletePage}
                    setShowOptions={setShowOptions}
                />
            )}
            <CopyPageLinkToClipboardButton
                page={page}
                handleCopyLinkToPage={handleCopyLinkToPage}
                setShowOptions={setShowOptions}
            />
        </div>
    );
}

function PageButtonWithOptions({
    page,
    isCurrentPage,
    handleSelectPage,
    handleUpdatePage,
    handleDeletePage,
    handleCopyLinkToPage,
}) {
    return (
        <PageTitleInput
            placeholderValue={""}
            handleChange={handleUpdatePage}
            page={page}
        >
            {(handleButtonClick) => (
                <PageLabel
                    page={page}
                    isCurrentPage={isCurrentPage}
                    handleSelectPage={handleSelectPage}
                >
                    <div className="invisible group-hover:visible flex items-center">
                        <OptionsButton widthAndPositionStyles="right-0 w-[120px]">
                            {(setShowOptions) => (
                                <PageOptions
                                    page={page}
                                    handleEditButtonClick={handleButtonClick}
                                    handleUpdatePage={handleUpdatePage}
                                    handleDeletePage={handleDeletePage}
                                    handleCopyLinkToPage={handleCopyLinkToPage}
                                    setShowOptions={setShowOptions}
                                />
                            )}
                        </OptionsButton>
                    </div>
                </PageLabel>
            )}
        </PageTitleInput>
    );
}

export default PageButtonWithOptions;

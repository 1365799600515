import React, { useEffect, useState, useCallback, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { UserContext } from "@contexts/UserContext";
import { HeaderProvider } from "@contexts/HeaderContext";
import { useToast } from "@contexts/ToastContext";
import PostService from "@services/PostService";
import { GemPost } from "@features/gem";
import { CollectionPost } from "@features/collection";
import { PostType } from "@constants";
import LoadingComponent from "@components/LoadingComponent";

function PostPage() {
    const [postInfo, setPostInfo] = useState(null);
    const [loading, setLoading] = useState(true);
    const { userInfo, isLoading: userIsLoading } = useContext(UserContext);
    const { id } = useParams();
    const navigate = useNavigate();
    const { Toast } = useToast();

    const updateGem = useCallback((updatedGem) => {
        setPostInfo(updatedGem);
    });

    const deleteGem = () => {
        navigate(`/${userInfo.username}`);
    };

    useEffect(() => {
        const fetchPost = async () => {
            try {
                const post = await PostService.getPost(id);
                setPostInfo(post);
            } catch (error) {
                console.error("Error fetching gem:", error);
            } finally {
                setLoading(false);
            }
        };

        if (id && !userIsLoading) {
            fetchPost();
        }
    }, [id, userInfo, userIsLoading]);

    let post;
    if (postInfo?.type === PostType.GEM) {
        post = (
            <GemPost key={postInfo._id} gem={postInfo} allowModify={false} />
        );
    } else if (postInfo?.type === PostType.COLLECTION) {
        post = (
            <div className="mt-4">
                <CollectionPost
                    key={postInfo._id}
                    collection={postInfo}
                    allowModify={false}
                />
            </div>
        );
    }

    return (
        <main className="bg-white">
            <HeaderProvider>
                <div className="body-content">
                    {loading || userIsLoading ? (
                        <LoadingComponent />
                    ) : !post ? (
                        <div className="m-4">
                            Error loading gem or gem not found.
                        </div>
                    ) : (
                        post
                    )}
                </div>
                <Toast />
            </HeaderProvider>
        </main>
    );
}

export default PostPage;

import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { usePersonalPage } from "@contexts/PersonalPageContext";
import PageService from "@services/PageService";
import { handleColumnDrop } from "../utils/personal-grid-drag-utils";
import MasonryGrid from "./MasonryGrid";
import PostGrid from "./PostGrid";
import GridPost from "./GridPost";

const PersonalPostGrid = React.memo(({ distributedPosts }) => {
    return (
        <div className="personal-post-grid flex">
            {distributedPosts.map((column, columnIndex) => (
                <Droppable
                    key={String(columnIndex)}
                    droppableId={String(columnIndex)}
                >
                    {(provided) => (
                        <div
                            className="flex-1 max-w-full overflow-hidden"
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                        >
                            {column.map(
                                (post, index) =>
                                    post && (
                                        <Draggable
                                            key={post._id}
                                            draggableId={post._id}
                                            index={index}
                                        >
                                            {(provided) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    className="mx-3 my-6"
                                                >
                                                    <GridPost
                                                        key={post._id}
                                                        post={post}
                                                        provided={provided}
                                                    />
                                                </div>
                                            )}
                                        </Draggable>
                                    )
                            )}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            ))}
        </div>
    );
});

function PersonalPageGrid({ posts, currentPage }) {
    const { updatePage } = usePersonalPage();

    function onDragEnd(result, distributedPosts, setDistributedPosts) {
        const { source, destination } = result;

        // Dropped outside the drop context.
        if (!destination) {
            return;
        }

        const handlePageReorder = async (flattenedPosts) => {
            currentPage.items = flattenedPosts.map((post) => post._id);
            updatePage(currentPage);
            await PageService.updatePage(currentPage?._id, {
                items: currentPage.items,
            });
        };

        handleColumnDrop(
            source,
            destination,
            distributedPosts,
            setDistributedPosts,
            handlePageReorder
        ).catch((error) => {
            console.error("Error saving re-arranged page:", error);
        });
    }

    if (!posts || posts.length === 0) {
        return (
            <div className="flex justify-center font-title text-lg">
                No posts found.
            </div>
        );
    }

    // Don't allow reordering on the Archive page since it shows all posts in order.
    if (currentPage.isArchive) {
        return <PostGrid posts={posts} />;
    }

    return (
        <MasonryGrid posts={posts}>
            {(distributedPosts, setDistributedPosts, gridLoading) =>
                !gridLoading && (
                    <DragDropContext
                        onDragEnd={(result) =>
                            onDragEnd(
                                result,
                                distributedPosts,
                                setDistributedPosts
                            )
                        }
                    >
                        <PersonalPostGrid
                            key={currentPage?._id}
                            distributedPosts={distributedPosts}
                        />
                    </DragDropContext>
                )
            }
        </MasonryGrid>
    );
}

export default PersonalPageGrid;

import React, { useState, useEffect, useRef } from "react";
import { Post } from "@classes/Post";
import { AuthorProfileDisplay } from "@features/profile";
import { PostPrivacyIcon } from "@components/PrivacyIcon";
import { PostType, PostStatus } from "@constants/constants";
import LoadingComponent from "@components/LoadingComponent";
import LazyLoad from "react-lazyload";

function LikeCommentCount({ post }) {
    var likeCommentText =
        post.likeCount > 0
            ? post.likeCount === 1
                ? `1 like`
                : `${post.likeCount} likes`
            : "";

    likeCommentText +=
        likeCommentText !== "" && post.commentCount > 0 ? ", " : " ";

    likeCommentText +=
        post.commentCount > 0
            ? post.commentCount === 1
                ? `1 comment`
                : `${post.commentCount} comments`
            : "";

    return (
        <div className="text-gray-700 text-opacity-60">{likeCommentText}</div>
    );
}
function GridPostFooter({ post }) {
    return (
        <div className="flex justify-between items-center text-xs pt-6">
            <LikeCommentCount post={post} />
            <div className="text-gray-500">
                <time>{Post.timeDisplay(post.updatedAt)}</time>
            </div>
        </div>
    );
}
function GridPostHeader({ post, useLink, children }) {
    return (
        <div className="flex justify-between text-sm">
            <AuthorProfileDisplay profile={post.author} useLink={useLink} />
            {children}
            <div className="text-gray-400">
                <PostPrivacyIcon post={post} />
            </div>
        </div>
    );
}

function GridPostFrame({ post, provided, onClick, children }) {
    let draftColor =
        post.status === PostStatus.DRAFT || post.status === PostStatus.REVIEW
            ? "border-t-4 border-yellow-300 bg-gray-100 hover:bg-gray-200"
            : "";

    const postTypeClass = `grid-post-item-${post.type?.toLowerCase()}`;
    return (
        <div
            onClick={onClick}
            className={`post-page ${postTypeClass} group relative flex flex-col p-4 bg-gray-100 hover:bg-gray-200 rounded shadow-md ${draftColor}`}
        >
            <div className="flex-grow overflow-hidden">
                <GridPostHeader post={post} useLink={true}>
                    <GridPostHandle provided={provided} />
                </GridPostHeader>
                {children}
            </div>
            <GridPostFooter post={post} />
        </div>
    );
}

function GridPostHandle({ provided }) {
    if (!provided) return;

    const rowStyle = "flex justify-between w-6";
    const dotStyle = "w-1 h-1 bg-gray-600 rounded-full";
    return (
        <div {...provided.dragHandleProps}>
            <div className="flex flex-col space-y-1 opacity-30 scale-75 px-4 my-3">
                <div className={rowStyle}>
                    <div className={dotStyle}></div>
                    <div className={dotStyle}></div>
                    <div className={dotStyle}></div>
                </div>
                <div className="flex justify-between w-6">
                    <div className={dotStyle}></div>
                    <div className={dotStyle}></div>
                    <div className={dotStyle}></div>
                </div>
            </div>
        </div>
    );
}

const postTypeConfig = {
    [PostType.GEM]: {
        maxHeight: "400",
        maxHeightClass: "max-h-[400px]",
    },
    [PostType.COLLECTION]: {
        maxHeight: "620",
        maxHeightClass: "max-h-[620px]",
    },
};

function GridPostCommon({ post, onClick, provided = null, children }) {
    const postContentRef = useRef(null);
    const [applyFadeOut, setApplyFadeOut] = useState(false);
    const [postConfig, setPostConfig] = useState(null);

    useEffect(() => {
        const config = postTypeConfig[post.type];
        setPostConfig(config);

        if (
            postContentRef.current &&
            postContentRef.current.scrollHeight > config?.maxHeight
        ) {
            setApplyFadeOut(true);
        } else {
            setApplyFadeOut(false);
        }
    }, [postContentRef?.current]);

    if (!post) return null;

    return (
        <GridPostFrame post={post} onClick={onClick} provided={provided}>
            {post.isLoading ? (
                <LoadingComponent />
            ) : (
                <LazyLoad height={300} offset={500} once>
                    <div
                        ref={postContentRef}
                        className={`${
                            postConfig?.maxHeightClass
                        } group overflow-hidden relative ${
                            applyFadeOut ? "fade-out-text" : ""
                        }`}
                    >
                        {children}
                    </div>
                </LazyLoad>
            )}
        </GridPostFrame>
    );
}

export default GridPostCommon;

import React, { useState, useContext, useRef, useEffect } from "react";
import { Post, Gem, Collection } from "@classes/Post";
import { UserContext } from "@contexts/UserContext";
import { PostType, PRIVACY_OPTIONS as PrivacyOptions } from "@constants";

export const usePost = (
    postType,
    currPost = null,
    currentPage = null,
    isFromCollection = false
) => {
    const { userInfo } = useContext(UserContext);
    const [editorContent, setEditorContent] = useState(null);
    const [post, setPost] = useState(
        currPost ??
            (postType === PostType.GEM
                ? Gem.init(userInfo?.profile)
                : Collection.init(
                      userInfo?.profile,
                      postType === PostType.AUTOCREATE
                  ))
    );

    const [additionalPostData, setAdditionalPostData] = useState({});

    const [isLinkLoading, setIsLinkLoading] = useState(false);
    const [isCreatedFromCollection, setIsCreatedFromCollection] =
        useState(isFromCollection);

    const [page] = useState(currentPage);

    const handlePreviewPostRef = useRef(null);
    const handleSubmitPostRef = useRef(null);

    const currentPrivacyValue = PrivacyOptions.find(
        (option) => option.value === post?.privacyLevel
    );

    const updatePost = (updates) => {
        let updatedPost;

        // Create a new instance of the post
        setPost((prevPost) => {
            if (prevPost.type === PostType.GEM) {
                updatedPost = new Gem(prevPost);
            } else if (prevPost.type === PostType.COLLECTION) {
                updatedPost = new Collection(prevPost);
            }

            // Apply each update
            Object.entries(updates).forEach(([key, value]) => {
                updatedPost.updateField(key, value);
            });

            return updatedPost;
        });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        updatePost({ [name]: value });
    };

    const handlePrivacyLevelChange = (selected) => {
        updatePost({ privacyLevel: selected.value });
    };

    const previewPost = () => {
        try {
            if (handlePreviewPostRef.current) {
                handlePreviewPostRef.current(post, editorContent);
            }
        } catch (error) {
            console.error("Failed to preview the post:", error);
        }
    };

    const submitPost = (isDraft) => {
        try {
            if (handleSubmitPostRef.current) {
                handleSubmitPostRef.current(post, isDraft, editorContent, page);
            }
        } catch (error) {
            console.error("Failed to save the post:", error);
        }
    };

    const mergePostState = (savedState) => {
        if (!savedState) return null;
        setPost(savedState.post);
        setAdditionalPostData(savedState.additionalPostData);
        setEditorContent(savedState.editorContent);
    };

    const isGem = post.type === PostType.GEM;
    const isCollection =
        post.type === PostType.COLLECTION && !post.isAutoCreated;
    const isAutoCreate = post.isAutoCreated;

    return {
        post,
        setPost,
        editorContent,
        setEditorContent,
        additionalPostData,
        setAdditionalPostData,
        isLinkLoading,
        setIsLinkLoading,
        isCreatedFromCollection,
        updatePost,
        handleInputChange,
        currentPrivacyValue,
        handlePrivacyLevelChange,
        previewPost,
        handlePreviewPostRef,
        submitPost,
        handleSubmitPostRef,
        mergePostState,
        isGem,
        isCollection,
        isAutoCreate,
    };
};

import { InstagramEmbed } from "react-social-media-embed";
import DefaultLinkRenderer from "./DefaultLinkRenderer";

function getInstagramEmbed(gem) {
    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <InstagramEmbed url={gem.link} width={328} />
        </div>
    );
}

const InstagramRenderer = {
    detect: (url) =>
        /\bhttps?:\/\/(?:www\.)?instagram\.com\/[a-zA-Z0-9_\.]+\/?\b/.test(url),

    content: (gem) => {
        return getInstagramEmbed(gem);
    },

    // preview: (gem) => {
    //     return getInstagramEmbed(gem);
    // },

    title: (gem) => {
        const linkInfo = gem.metadata;
        if (linkInfo.logo) {
            return DefaultLinkRenderer.title(gem);
        }
        return <>📷 {gem.title}</>;
    },
};

export default InstagramRenderer;

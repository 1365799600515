import React from "react";
import { PostContent } from "@features/post";
import { GemContent } from "..";
import { PreviewContentRenderer } from "@features/linkToGem";

function GemGridContent({ gem }) {
    return (
        <div className="gem-grid-post">
            <PostContent post={gem}>
                <div className="px-4">
                    <GemContent
                        gem={gem}
                        contentRenderer={PreviewContentRenderer}
                    />
                </div>
            </PostContent>
        </div>
    );
}

export default GemGridContent;

import React from "react";
import { useModal } from "@contexts/ModalContext";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";

function PrevContentBar({ prevContentTitle }) {
    const { closeModal } = useModal();
    if (prevContentTitle === null || prevContentTitle === undefined) return;
    return (
        <div className="flex space-x-2 items-center text-sm p-2 bg-white font-bold text-opacity-70 relative shadow-sm z-40">
            <button
                className="button-link flex space-x-2 items-center"
                onClick={closeModal}
            >
                <ChevronLeftIcon className="w-3 h-3" />
                <div>{prevContentTitle === "" ? "Back" : prevContentTitle}</div>
            </button>
        </div>
    );
}

function ContentModal({ prevContentTitle, currentComponent }) {
    const CurrentComponentContent = currentComponent?.content;
    return (
        <div
            className="modal flex flex-col z-10 overflow-y-auto rounded-lg md:min-w-[64vw] md:max-w-[80vw] w-[92vw] max-h-[92vh] min-h-[64vh]"
            onClick={(e) => e.stopPropagation()}
        >
            <PrevContentBar prevContentTitle={prevContentTitle} />
            <CurrentComponentContent
                {...currentComponent?.props}
                savedState={currentComponent?.state}
            />
        </div>
    );
}

export { PrevContentBar, ContentModal };

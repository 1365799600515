import React from "react";

function CommentInput({ comment, setComment, handleSendComment }) {
    return (
        <input
            type="text"
            placeholder="Add a comment..."
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            onKeyPress={(e) => {
                if (e.key === "Enter") {
                    e.preventDefault();
                    handleSendComment();
                }
            }}
            className="flex-grow border-0 focus:outline-none focus:ring focus:ring-white text-sm text-black"
        />
    );
}

export default CommentInput;

import React, { useState, useEffect, useCallback } from "react";
import debounce from "lodash.debounce";
import LinkPreviewService from "@services/LinkPreviewService";
import { Gem } from "@classes/Post";
import { FullContentRenderer } from "@features/linkToGem";
import { XMarkIcon } from "@heroicons/react/24/solid";
import InputField from "./InputField";

function LinkPreview({ linkPreview, handleHideLinkPreview }) {
    const tempGem = new Gem({
        title: "",
        contentType: linkPreview?.contentType || null,
        metadata: {
            ...linkPreview,
            link: linkPreview?.url || null,
            info: linkPreview?.metadata || linkPreview?.info || null,
        },
    });

    return (
        <div className="relative">
            <div>{FullContentRenderer(tempGem)}</div>
            <button
                onClick={handleHideLinkPreview}
                className="absolute top-0 right-0 m-1 p-1 bg-white bg-opacity-90 hover:bg-opacity-100 rounded-full shadow-md"
            >
                <XMarkIcon className="h-4 w-4 text-black" />
            </button>
        </div>
    );
}

function LinkPreviewInput({
    link,
    linkPreview,
    handleHideLinkPreview,
    onLinkChange,
    handleSubmitLink,
    selectedInputType,
    setSelectedInputType,
    loading,
    error,
}) {
    const [linkInput, setLinkInput] = useState(link);

    if (linkPreview) {
        return (
            <LinkPreview
                linkPreview={linkPreview}
                handleHideLinkPreview={handleHideLinkPreview}
            />
        );
    } else {
        return (
            <InputField
                linkInput={linkInput}
                setLinkInput={setLinkInput}
                onLinkChange={onLinkChange}
                handleSubmitLink={handleSubmitLink}
                selectedInputType={selectedInputType}
                setSelectedInputType={setSelectedInputType}
                loading={loading}
                error={error}
            />
        );
    }
}

function LinkInput({
    post,
    handleLinks,
    setIsLinkLoading,
    selectedInputType,
    setSelectedInputType,
}) {
    const [linkInfo, setLinkInfo] = useState(post.link);
    const [linkPreviewInfo, setLinkPreviewInfo] = useState(post.metadata); // This isn't very stable because post.metadata has a different structure than linkPreview from server call
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const debouncedLinkPreview = useCallback(
        debounce(async (link) => {
            try {
                setLoading(true);
                setIsLinkLoading(true);
                const linkPreview = await LinkPreviewService.getLinkPreview(
                    link.trim(),
                    selectedInputType
                );
                setLinkPreviewInfo(linkPreview);
                handleLinks(link, linkPreview);
            } catch (error) {
                setError(
                    "Unable to fetch the link. Please try again later. If the issue persists, please contact us."
                );
                console.error("Error fetching link preview:", error);
            } finally {
                setLoading(false);
                setIsLinkLoading(false);
            }
        }, 800),
        [selectedInputType]
    );

    const handleHideLinkPreview = () => {
        setLinkPreviewInfo(null);
        handleLinks(linkInfo, null); // Clear results when input is cleared
        setError(null);
    };

    const handleSubmitLink = () => {
        if (!linkPreviewInfo) {
            if (linkInfo?.trim()) {
                debouncedLinkPreview(linkInfo);
            } else {
                setLinkPreviewInfo(null);
                handleLinks(null, null); // Clear results when input is cleared
                setError(null);
            }
        }
    };

    useEffect(() => {
        handleSubmitLink();
    }, [linkInfo]);

    return (
        <LinkPreviewInput
            link={linkInfo}
            linkPreview={linkPreviewInfo}
            handleHideLinkPreview={handleHideLinkPreview}
            onLinkChange={setLinkInfo}
            handleSubmitLink={handleSubmitLink}
            selectedInputType={selectedInputType}
            setSelectedInputType={setSelectedInputType}
            loading={loading}
            error={error}
        />
    );
}

export default LinkInput;

import React from "react";
import ClickableProfile from "./ClickableProfile";

function MultiProfileGrid({ users }) {
    return (
        <div>
            {users.length > 0 ? (
                <div>
                    <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 pt-8 mx-auto pl-4 pr-4">
                        {users.map((user) => (
                            <div key={user?.username}>
                                <ClickableProfile
                                    profile={user}
                                    showFollowButton={false}
                                />
                            </div>
                        ))}
                    </div>
                    <br className="pt-10"></br>
                    <hr></hr>
                </div>
            ) : (
                <div></div>
            )}
        </div>
    );
}

export default MultiProfileGrid;

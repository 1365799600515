import { useState, useEffect } from "react";
import CommentService from "@services/CommentService";
import { useGlobalPosts } from "@contexts/GlobalPostContext";
import { Post } from "@classes/Post";

function useComments(post) {
    const [comment, setComment] = useState("");
    const [allComments, setAllComments] = useState([]);
    const { updatePost } = useGlobalPosts();

    useEffect(() => {
        const fetchComments = async () => {
            try {
                const comments = await CommentService.getComments(post._id);
                setAllComments(comments);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        if (post._id) {
            fetchComments();
        }
    }, [post]);

    const handleAddCommentToPost = () => {
        const updatedPost = Post.copyFrom(post);
        if (!updatedPost.commentCount) {
            updatedPost.commentCount = 1;
        } else {
            updatedPost.commentCount += 1;
        }

        updatePost(updatedPost);
    };

    const handleRemoveCommentFromPost = () => {
        const updatedPost = Post.copyFrom(post);
        if (updatedPost.commentCount > 0) {
            updatedPost.commentCount -= 1;
        }

        updatePost(updatedPost);
    };

    const handleSendComment = async () => {
        const postId = post._id;
        const postComment = comment;
        try {
            await CommentService.makeComment(postComment, postId);
            const comments = await CommentService.getComments(postId);
            setAllComments(comments);
            setComment(""); // Clear the comment input

            handleAddCommentToPost();
        } catch (error) {
            console.error("Error sending comment:", error);
        }
    };

    const handleDeleteComment = (deletedCommentId) => {
        setAllComments((prevComments) =>
            prevComments.filter((comment) => comment._id !== deletedCommentId)
        );

        handleRemoveCommentFromPost();
    };

    return {
        comment,
        setComment,
        allComments,
        handleSendComment,
        handleDeleteComment,
    };
}

export default useComments;

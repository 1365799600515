import React, { useState, useContext } from "react";
import { UserContext } from "@contexts/UserContext";
import { useModal } from "@contexts/ModalContext";
import { useToast } from "@contexts/ToastContext";
import PageService from "@services/PageService";
import {
    DocumentPlusIcon,
    PlusSmallIcon,
    DocumentMinusIcon,
} from "@heroicons/react/24/outline";
import { usePersonalPage } from "@contexts/PersonalPageContext";
import { usePersonalPagePosts } from "@contexts/PersonalPagePostContext";
import { OptionButton, OptionsButton } from "@components/OptionsButton";

const AddPostToPage = ({ page, post, setShowOptions, updatePage }) => {
    const { showToast } = useToast();
    const handleAddToPage = async () => {
        try {
            page.items = [post._id, ...page.items];
            updatePage(page);

            setShowOptions(false);
            showToast(`Added ${post.title} to ${page.title}`, "bg-emerald-600");

            await PageService.updatePage(page._id, { items: page.items });
        } catch (error) {
            console.error("Error adding post to page: " + error);
        }
    };

    const isPostAlreadyInPage = page.items?.includes(post._id);
    const pageLabel = (
        <div
            className={`flex items-center space-x-2 text-left px-4 py-2 text-sm bg-gray-100 hover:bg-gray-200 ${
                isPostAlreadyInPage && "opacity-70"
            }
        `}
        >
            <PlusSmallIcon className="h-5 w-5 text-gray-500 hover:text-gray-700" />
            <div>{page.title}</div>
        </div>
    );

    if (isPostAlreadyInPage) {
        return pageLabel;
    }

    return (
        <button onClick={handleAddToPage} className="w-full">
            {pageLabel}
        </button>
    );
};

const AddToPageButton = ({ post, setShowOptions }) => {
    const [showPages, setShowPages] = useState(false);
    const { pages, updatePage } = usePersonalPage();

    const handlePageClick = () => {
        setShowPages(!showPages);
    };

    return (
        <div>
            <OptionButton
                Icon={DocumentPlusIcon}
                onClick={handlePageClick}
                title={"Add to page"}
            />
            {showPages && (
                <div className="page-list">
                    {pages.map(
                        (page) =>
                            page &&
                            !page.isArchive && (
                                <AddPostToPage
                                    key={page._id}
                                    page={page}
                                    post={post}
                                    setShowOptions={setShowOptions}
                                    updatePage={updatePage}
                                />
                            )
                    )}
                </div>
            )}
        </div>
    );
};

const RemoveFromPageButton = ({ post, setShowOptions }) => {
    const { closeModal } = useModal();
    const { showToast } = useToast();
    const { updatePage } = usePersonalPage();
    const { currentPage } = usePersonalPagePosts();

    const handleRemoveFromPage = async (post) => {
        try {
            currentPage.items = currentPage?.items.filter(
                (postId) => postId !== post._id
            );
            updatePage(currentPage);

            setShowOptions(false);
            closeModal();
            showToast(
                `Removed ${post.title} from ${currentPage?.title}`,
                "bg-rose-800"
            );

            await PageService.updatePage(currentPage?._id, {
                items: currentPage.items,
            });
        } catch (error) {
            console.error("Error removing post from page: " + error);
        }
    };

    return (
        <OptionButton
            Icon={DocumentMinusIcon}
            onClick={() => handleRemoveFromPage(post)}
            title={"Remove from page"}
        />
    );
};

const PostActions = ({ post, setShowOptions, userId }) => {
    const { currentPage } = usePersonalPagePosts();
    const isCurrentUser = userId === post.author._id;
    if (isCurrentUser) {
        return (
            <>
                <AddToPageButton post={post} setShowOptions={setShowOptions} />
                {!currentPage?.isArchive && (
                    <RemoveFromPageButton
                        post={post}
                        setShowOptions={setShowOptions}
                    />
                )}
            </>
        );
    }
};

const PostOptions = ({ post }) => {
    const { userId } = useContext(UserContext);
    return (
        <OptionsButton widthAndPositionStyles="right-0 w-[180px]">
            {(setShowOptions) => (
                <PostActions
                    post={post}
                    setShowOptions={setShowOptions}
                    userId={userId}
                />
            )}
        </OptionsButton>
    );
};

export default PostOptions;

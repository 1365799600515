import imageCompression from "browser-image-compression";

function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[arr.length - 1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
}

// Uploads content images and returns content with base64 strings replaced with uploaded images urls
export const getContentImages = async (editorContent, content) => {
    if (!editorContent) {
        return null;
    }

    // Create a temporary DOM element to parse HTML
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = editorContent;

    const imageElements = tempDiv.querySelectorAll("img");
    let files = [];
    let modifiedContent = content;

    for (const imageElement of imageElements) {
        const imageUrl = imageElement.getAttribute("src");
        const isBase64Image = imageUrl.startsWith("data:image/");

        if (isBase64Image) {
            // Convert base64 string to file
            const file = dataURLtoFile(imageUrl, "temp");

            // Compress the file
            const compressedFile = await imageCompression(file, {
                maxSizeMB: 1, // Max file size in MBs, you can change this value
                maxWidthOrHeight: 1920, // Max width or height in pixels, you can adjust as needed
                useWebWorker: true, // Enable multi-threading if supported
            });

            files.push(compressedFile);

            // Replace only the base64 string in the src attribute
            const newImageUrl = "[REPLACEMENT_IMAGE_SRC]";
            modifiedContent = modifiedContent.replace(
                imageElement.outerHTML,
                imageElement.outerHTML.replace(imageUrl, newImageUrl)
            );
        }
    }

    return { files, modifiedContent };
};

import React, { useState, useEffect } from "react";

const breakpointColumnsObj = {
    default: 3,
    1100: 2,
    700: 1,
};

function getNumColumns(windowWidth) {
    let numColumns = breakpointColumnsObj.default;

    const sortedBreakpoints = Object.keys(breakpointColumnsObj)
        .map((key) => parseInt(key))
        .filter((key) => !isNaN(key))
        .sort((a, b) => b - a);

    for (let breakpoint of sortedBreakpoints) {
        if (windowWidth < breakpoint) {
            numColumns = breakpointColumnsObj[breakpoint];
        } else {
            break;
        }
    }
    return numColumns;
}

function distributePosts(posts, numColumns) {
    let columns = Array.from({ length: numColumns }, () => []);
    for (let i = 0; i < posts.length; i++) {
        columns[i % numColumns].push(posts[i]);
    }
    return columns;
}

const MasonryGrid = ({ posts, children }) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [distributedPosts, setDistributedPosts] = useState([]);
    const [gridLoading, setGridLoading] = useState(true);

    useEffect(() => {
        function handleResize() {
            setWindowWidth(window.innerWidth);
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        const numColumns = getNumColumns(windowWidth);
        if (posts?.length > 0) {
            const distributedPosts = distributePosts(posts, numColumns);
            setDistributedPosts(distributedPosts);
            setGridLoading(false);
        }
    }, [windowWidth, posts]);

    return (
        <div className="masonry">
            {children(distributedPosts, setDistributedPosts, gridLoading)}
        </div>
    );
};

export default MasonryGrid;

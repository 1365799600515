import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FormStatus, FormStatusFail } from "@components/Form";
import WaitlistForm from "./WaitlistForm";
import AuthService from "@services/AuthService";

export function EarlyAccessLinkPanel() {
    return (
        <div className="text-center ">
            <div className="pb-2">Already have early access?</div>
            <div>
                <span>
                    <Link
                        to="/login"
                        className="text-purple-700 hover:text-purple-900 font-bold pr-1"
                    >
                        Login
                    </Link>
                </span>{" "}
                or{" "}
                <span>
                    <Link
                        to="/register"
                        className="text-purple-700 hover:text-purple-900 font-bold pl-1"
                    >
                        Register
                    </Link>
                </span>
            </div>
        </div>
    );
}

export function WaitlistPanel() {
    const [status, setStatus] = useState(null);
    const [failureStatus, setFailureStatus] = useState("");

    async function onWaitlistSignup({ email }) {
        try {
            setStatus(null);
            const response = await AuthService.addToWaitlist({ email });

            if (!response.success) {
                setFailureStatus("Failed to add to waitlist. Please try again");
                return;
            }

            if (response.success) {
                setStatus("You're on the waitlist, stay tuned!");
                return;
            }
        } catch (error) {
            setFailureStatus("Failed to add to waitlist. Please try again");
        }
    }

    return (
        <>
            <div className="text-center ">
                <WaitlistForm onWaitlistSignup={onWaitlistSignup} />
                {status && <FormStatus status={status} />}
                {!status && failureStatus && (
                    <FormStatusFail status={failureStatus} />
                )}
            </div>
        </>
    );
}

const PersonalPageSteps = ({ setStepIndex, setPage }) => [
    {
        target: "body",
        content: "🌟 Welcome to your page!",
        placement: "center",
        disableBeacon: true,
        back: async () => {
            setPage("user");
            setStepIndex(3);
        },
        action: () => {
            setStepIndex((i) => i + 1);
        },
    },
    {
        target: ".personal-page-side-panel",
        content: "Organize your gems and collections into shareable pages.",
        placement: "right",
        disableBeacon: true,
        back: () => {
            setStepIndex((i) => i - 1);
        },
        action: () => {
            setStepIndex((i) => i + 1);
        },
    },
    {
        target: ".create-Gem-button",
        content: "Click here to create a new gem.",
        placement: "left",
        disableBeacon: true,
        back: () => {
            setStepIndex((i) => i - 1);
        },
        action: () => {
            setStepIndex((i) => i + 1);
        },
    },
    {
        target: ".create-collection",
        content: "Click here to create a new collection.",
        placement: "bottom",
        disableBeacon: true,
        back: () => {
            setStepIndex((i) => i - 1);
        },
        action: () => {
            document.querySelector(".create-collection")?.click();
            setStepIndex((i) => i + 1);
        },
    },
    {
        target: ".create-collection-options",
        content:
            "Collections can be created using our AI builder which can convert your text notes into a collection. Or, you can add your items manually.",
        placement: "bottom",
        disableBeacon: true,
        back: () => {
            document.querySelector(".create-collection")?.click();
            setStepIndex((i) => i - 1);
        },
        action: () => {
            document.querySelector(".create-collection")?.click();
            setStepIndex((i) => i + 1);
        },
    },
    {
        target: "body",
        content: "Read our gem and collection starter guides to learn more 🌟.",
        placement: "center",
        disableBeacon: true,
        back: async () => {
            setStepIndex((i) => i - 1);
        },
        action: () => {
            setStepIndex((i) => i + 1);
        },
    },
    {
        target: ".explore-page-button",
        content: "Finally, let's check out the Explore page.",
        placement: "left",
        disableBeacon: true,
        back: () => {
            document.querySelector(".create-collection")?.click();
            setStepIndex((i) => i - 1);
        },
        action: () => {
            setPage("explore");
            setStepIndex((i) => i + 1);
        },
    },
];

export default PersonalPageSteps;

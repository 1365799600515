import React from "react";
import { LinkDisplay, LinkIcon, LinkWrapper } from "@components/Link";
import { SplitContentRendererFrame } from "../RendererFrames";

const getRenderedLink = (gem, isPreview) => {
    const linkInfo = gem.metadata;
    return (
        <SplitContentRendererFrame gem={gem} previewImage={linkInfo.image}>
            <LinkDisplay linkInfo={linkInfo} isPreview={isPreview} />
            <p className="mt-2 mb-2 text-md text-oversize-2 text-gray-600">
                {linkInfo.description}
            </p>
        </SplitContentRendererFrame>
    );
};

const DefaultLinkRenderer = {
    detect: (url) => true,

    content: (gem) => {
        const linkInfo = gem.metadata;
        return (
            <LinkWrapper linkInfo={linkInfo}>
                {getRenderedLink(gem, false)}
            </LinkWrapper>
        );
    },

    preview: (gem) => {
        return getRenderedLink(gem, true);
    },

    title: (gem) => {
        const linkInfo = gem.metadata;
        if (linkInfo.logo) {
            return (
                <div className="flex items-center space-x-2">
                    <LinkIcon linkInfo={linkInfo} size={"h-5 w-5"} />
                    <div className="text-oversize-1">{gem.title}</div>
                </div>
            );
        }

        return <>{gem.title}</>;
    },
};

export default DefaultLinkRenderer;

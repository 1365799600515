// services/GoogleMapsService.js
import React from "react";
import { LinkWrapper } from "@components/Link";
import PriceRating from "@components/rating/PriceRating";
import StarRating from "@components/rating/StarRating";
import { SplitContentRendererFrame } from "../RendererFrames";
import DefaultLinkRenderer from "./DefaultLinkRenderer";

const isGoogleMapsShortURL = (url) =>
    /^https:\/\/maps\.app\.goo\.gl\//.test(url);
const isGoogleMapsURL = (url) =>
    /^https:\/\/www\.google\.com\/maps\/place\//.test(url);

const isGoogleMapsSearchURL = (url) =>
    /^https:\/\/www\.google\.com\/maps\/search\//.test(url);

function DisplayError() {
    return (
        <div className="text-red-500">An error occurred. Please try again.</div>
    );
}

function getPreferredItemCapitalized(types) {
    try {
        // Helper function to capitalize and replace underscores
        function capitalizeAndReplaceUnderscores(str) {
            return str
                .split("_")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ");
        }

        // Check if the list has 'meal_delivery' or 'meal_takeaway'
        const hasMealDeliveryOrTakeaway =
            types.includes("meal_delivery") || types.includes("meal_takeaway");

        // If the list contains either 'meal_delivery' or 'meal_takeaway'
        if (hasMealDeliveryOrTakeaway) {
            const preferredTypes = ["cafe", "bakery", "restaurant"];

            // Find the first type in the list that matches the preferred types
            for (let type of types) {
                if (preferredTypes.includes(type)) {
                    return capitalizeAndReplaceUnderscores(type);
                }
            }
        }

        // If none of the above conditions are met, simply capitalize and replace underscores for the first item
        return capitalizeAndReplaceUnderscores(types[0]);
    } catch (error) {
        console.error("Error capitalizing item:", error);
        return types[0]; // Just return the first type as a fallback
    }
}

function getNextEventTime(hoursString, openStatus) {
    try {
        const daysOfWeek = [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
        ];
        const today = new Date();
        const currentDayName = daysOfWeek[today.getDay()];
        const currentTime = today.getHours() * 100 + today.getMinutes();

        const hoursArray = hoursString.split(",").map((item) => item.trim());
        const todayHours = hoursArray.find((item) =>
            item.startsWith(currentDayName)
        );

        const regex = /(\d+:\d+\s?[APM]+)\s?–\s?(\d+:\d+\s?[APM]+)/;
        const match = todayHours.match(regex);

        if (!match) {
            return;
        }

        const [_, openTime, closeTime] = match;

        const convertToTimeNumber = (time) => {
            const [hours, minutes] = time.split(":").map(Number);
            const isPM = time.includes("PM");
            return ((hours % 12) + (isPM ? 12 : 0)) * 100 + minutes;
        };

        if (openStatus === "Open") {
            return `Closes at ${closeTime} ${currentDayName.slice(0, 3)}.`;
        } else {
            // Assuming it's "Closed"
            if (currentTime < convertToTimeNumber(openTime)) {
                return `Opens at ${openTime} ${currentDayName.slice(0, 3)}.`;
            } else {
                const nextDayName = daysOfWeek[(today.getDay() + 1) % 7];
                const nextDayHours = hoursArray.find((item) =>
                    item.startsWith(nextDayName)
                );
                const [__, nextOpenTime] =
                    nextDayHours.match(/(\d+:\d+ [APM]+)\s–/);
                return `Opens at ${nextOpenTime} ${nextDayName.slice(0, 3)}.`;
            }
        }
    } catch (error) {
        return "Error fetching hours.";
    }
}
const RatingSection = ({ rating, ratingCount }) =>
    rating || ratingCount ? (
        <div className="flex items-center space-x-2 text-gray-600">
            {rating && (
                <>
                    <div className="font-bold">{rating}</div>
                    <StarRating className="text-yellow-400" value={rating} />
                </>
            )}
            {ratingCount && <div>({ratingCount})</div>}
        </div>
    ) : null;

const PriceAndTypeSection = ({ priceLevel, types }) =>
    priceLevel || types ? (
        <div className="flex items-center space-x-2 text-gray-600">
            {priceLevel && (
                <div className="flex items-center">
                    <PriceRating value={priceLevel} />
                </div>
            )}
            {types && (
                <div className="capitalize">
                    {getPreferredItemCapitalized(types)}
                </div>
            )}
        </div>
    ) : null;

const OpenStatusSection = ({ linkInfo, openStatus }) => {
    try {
        const nextEventTimeString = linkInfo.hours
            ? getNextEventTime(linkInfo.hours, linkInfo.openStatus)
            : null;
        return openStatus || nextEventTimeString ? (
            <div className="flex items-center space-x-2 text-green-800">
                {openStatus && <div>{openStatus}.</div>}
                {nextEventTimeString && <div>{nextEventTimeString}</div>}
            </div>
        ) : null;
    } catch (error) {
        console.error("Error generating specific description:", error);
        return <DisplayError />;
    }
};

const ServiceOptionsSection = ({ dineIn, takeout, delivery }) =>
    dineIn || takeout || delivery ? (
        <div className="flex items-center space-x-2 text-gray-600">
            {dineIn && <div className="bg-green-100 p-1 rounded">Dine-in</div>}
            {delivery && (
                <div className="bg-blue-100 p-1 rounded">Delivery</div>
            )}
            {takeout && (
                <div className="bg-yellow-100 p-1 rounded">Takeout</div>
            )}
        </div>
    ) : null;

const getCardContent = (gem) => {
    try {
        const metadata = gem.metadata;
        const linkInfo = metadata.info;
        return (
            <SplitContentRendererFrame
                gem={gem}
                isContent={true}
                previewTitle={metadata.title}
                previewImage={metadata.image}
            >
                {linkInfo && (
                    <>
                        <div className="mb-1">
                            <RatingSection
                                rating={linkInfo.rating}
                                ratingCount={linkInfo.ratingCount}
                            />
                        </div>
                        <div className="mb-2">
                            <PriceAndTypeSection
                                priceLevel={linkInfo.priceLevel}
                                types={linkInfo.types}
                            />
                        </div>
                        <div className="mb-2">
                            <OpenStatusSection
                                linkInfo={linkInfo}
                                openStatus={linkInfo.openStatus}
                            />
                        </div>
                        <ServiceOptionsSection
                            dineIn={linkInfo.dineIn}
                            takeout={linkInfo.takeout}
                            delivery={linkInfo.delivery}
                        />
                    </>
                )}
            </SplitContentRendererFrame>
        );
    } catch (error) {
        console.error("Error previewing link:", error);
        return <DisplayError />;
    }
};

function getEmbedURL(gem) {
    const placeId = gem.metadata.info?.placeId;
    return placeId
        ? `https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_API_KEY}&q=place_id:${placeId}`
        : null;
}

const GoogleMapsRenderer = {
    detect: (url) => {
        return (
            isGoogleMapsShortURL(url) ||
            isGoogleMapsURL(url) ||
            isGoogleMapsSearchURL(url)
        );
    },

    preview: (gem) => getCardContent(gem),

    content: (gem) => {
        const linkInfo = gem.metadata;
        const embedUrl = getEmbedURL(gem);
        try {
            return (
                <>
                    <LinkWrapper linkInfo={linkInfo}>
                        {getCardContent(gem)}
                    </LinkWrapper>
                    {embedUrl && (
                        <>
                            <div className="font-title mt-6 mb-2">
                                See it on the map!
                            </div>
                            <iframe
                                width="600"
                                height="450"
                                style={{ border: 0 }}
                                loading="lazy"
                                allowFullScreen
                                referrerPolicy="no-referrer-when-downgrade"
                                src={embedUrl}
                            />
                        </>
                    )}
                </>
            );
        } catch (error) {
            console.error("Error previewing link:", error);
            return <DisplayError />;
        }
    },

    title: (gem) => {
        const linkInfo = gem.metadata;
        if (linkInfo.logo) {
            return DefaultLinkRenderer.title(gem);
        }

        return <>📍 {gem.title}</>;
    },
};

export default GoogleMapsRenderer;

const MainPageSteps = ({ setStepIndex, setPage }) => [
    {
        target: "body",
        content:
            "🌟 Discover gems and collections from people around the world.",
        placement: "center",
        disableBeacon: true,
        back: () => {
            setPage("personal");
            setStepIndex(9);
        },
        action: () => {
            setStepIndex((i) => i + 1);
        },
    },
    {
        target: ".category-tabs",
        content: "Posts are organized into categories based on your interests.",
        placement: "right",
        disableBeacon: true,
        back: () => {
            setStepIndex((i) => i - 1);
        },
        action: () => {
            setStepIndex((i) => i + 1);
        },
    },
    {
        target: "body",
        content: "That's it! Enjoy Gems!",
        placement: "center",
        disableBeacon: true,
        back: () => {
            setStepIndex((i) => i - 1);
        },
        action: () => {
            setStepIndex((i) => i + 1);
        },
    },
];

export default MainPageSteps;

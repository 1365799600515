import React from "react";
import { EllipsisHorizontalIcon } from "@heroicons/react/24/solid";
import Dropdown from "./Dropdown";

const OptionButton = ({ Icon, onClick, title, classStyles = "" }) => {
    const handleClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick();
    };

    return (
        <button
            onClick={handleClick}
            className={`w-full bg-white hover:bg-gray-100 rounded-sm text-left px-4 py-2 text-sm ${classStyles}`}
        >
            <div className="flex items-center space-x-2">
                <Icon className="h-4 w-4" />
                <div>{title}</div>
            </div>
        </button>
    );
};

const OptionsButton = ({
    widthAndPositionStyles,
    shouldDetectOutsideClick = true,
    children,
}) => {
    return (
        <Dropdown
            widthAndPositionStyles={widthAndPositionStyles}
            triggerButton={<EllipsisHorizontalIcon className="h-5 w-5" />}
            shouldDetectOutsideClick={shouldDetectOutsideClick}
        >
            {(setShowDropdown) => children(setShowDropdown)}
        </Dropdown>
    );
};

export { OptionButton, OptionsButton };

import React, { useContext } from "react";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import { useModal } from "@contexts/ModalContext";
import { UserContext } from "@contexts/UserContext";
import { PostPrivacyIconWithLabel } from "@components/PrivacyIcon";

function PostPanelHeader({ post }) {
    const { userInfo } = useContext(UserContext);
    const { closeAllModals } = useModal();

    const postTime = post.createdAt ? (
        <time>{format(new Date(post.createdAt), "MMM d, yyyy, h:mm a")}</time>
    ) : null;

    return (
        <div className="flex items-center justify-between">
            <div className="text-left text-sm flex items-center space-x-2">
                <PostPrivacyIconWithLabel post={post} />
            </div>
            <div className="text-sm">
                {userInfo ? (
                    <div className="hover:gray-300">
                        <Link
                            to={`/${post.type}/${post._id}`}
                            onClick={closeAllModals}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {postTime}
                        </Link>
                    </div>
                ) : (
                    <div>{postTime}</div>
                )}
            </div>
        </div>
    );
}

export default PostPanelHeader;

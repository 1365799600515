import React from "react";

function SymbolRating({
    value,
    filledSymbol,
    emptySymbol,
    halfSymbol,
    maxSymbols = 5,
}) {
    // Validate the input value
    if (value < 0 || value > maxSymbols || typeof value !== "number") {
        console.error(`Invalid value for SymbolRating: ${value}`);
        return null;
    }

    // Round the value to the nearest half
    const roundedValue = Math.round(value * 2) / 2;

    const fullSymbols = Math.floor(roundedValue);
    const isHalfSymbol = roundedValue - fullSymbols === 0.5;
    const remainingSymbols = maxSymbols - fullSymbols - (isHalfSymbol ? 1 : 0);

    return (
        <div className="flex">
            {/* Render filled symbols */}
            {[...Array(fullSymbols)].map((_, index) => (
                <span key={index}>{filledSymbol}</span>
            ))}

            {/* Render half symbol if required */}
            {isHalfSymbol && <span>{halfSymbol}</span>}

            {/* Render empty symbols */}
            {[...Array(remainingSymbols)].map((_, index) => (
                <span key={index + fullSymbols + (isHalfSymbol ? 1 : 0)}>
                    {emptySymbol}
                </span>
            ))}
        </div>
    );
}

export default SymbolRating;

import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { HeaderProvider } from "@contexts/HeaderContext";
import LoadingComponent from "@components/LoadingComponent";
import { UserContext } from "@contexts/UserContext";
import { useToast } from "@contexts/ToastContext";
import ProfileService from "@services/ProfileService";
import PersonalPage from "./PersonalPage";
import UserPage from "./UserPage";
import { PRIVACY_LEVELS } from "@constants";

// Eventually, move this out of protectedPages but based on the user's page privacy level
function GetUserPage() {
    const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);
    const { userInfo, isAuthenticated, isLoading } = useContext(UserContext);
    const { username } = useParams();
    const [privacyLevel, setPrivacyLevel] = useState(null);
    const [loading, setLoading] = useState(true);
    const { Toast } = useToast();

    useEffect(() => {
        if (isLoading) {
            return;
        }

        if (isAuthenticated()) {
            setIsUserAuthenticated(true);
        }
    }, [userInfo, isAuthenticated, isLoading]);

    useEffect(() => {
        const fetchPrivacyLevel = async () => {
            try {
                const userPrivacyLevel =
                    await ProfileService.getProfilePrivacyLevel(username);
                setPrivacyLevel(userPrivacyLevel);
            } catch (error) {
                console.error("Error fetching profile:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchPrivacyLevel();
    }, [username]);

    if (isLoading || loading) {
        return <LoadingComponent />;
    }

    if (!loading && !isLoading && !privacyLevel) {
        return (
            <main className="bg-white w-screen">
                <HeaderProvider />
                <div className="body-content">
                    <div className="font-title p-8">
                        Sorry, we couldn't find that Gems account.
                    </div>
                </div>
            </main>
        );
    }

    if (isUserAuthenticated && userInfo?.username === username) {
        return (
            <main className="bg-white w-screen">
                <HeaderProvider>
                    <div className="body-content">
                        <PersonalPage />
                        <Toast />
                    </div>
                </HeaderProvider>
            </main>
        );
    }

    if (
        (!isUserAuthenticated || !userInfo) &&
        privacyLevel === PRIVACY_LEVELS.PRIVATE
    ) {
        return (
            <main className="bg-white w-screen">
                <HeaderProvider />
                <div className="body-content">
                    <div className="font-title p-8">
                        Sorry, a Gems account is required to view this page.
                    </div>
                </div>
            </main>
        );
    }

    return (
        <main className="bg-white w-screen">
            <div className="body-content">
                <HeaderProvider>
                    <UserPage key={username} username={username} />
                    <Toast />
                </HeaderProvider>
            </div>
        </main>
    );
}

export default GetUserPage;

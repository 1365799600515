import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { useModal } from "@contexts/ModalContext";
import { AuthorProfileDisplay } from "@features/profile";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { UserContext } from "@contexts/UserContext";
import LoadingComponent from "@components/LoadingComponent";

function LikeModal({ likes }) {
    const { closeOverlayModal } = useModal();
    return (
        <div>
            <button
                className="absolute top-0 right-0 m-2 text-lg font-bold"
                onClick={closeOverlayModal}
            >
                <XMarkIcon className={"h-5 w-5"} />
            </button>
            <div className="flex-1 p-4 w-[20vw] max-w-[30vw] max-h-[50vh] overflow-y-auto">
                <div className="mb-4 text-md font-bold">Likes</div>
                {likes.length > 0 ? (
                    likes.map((like, index) => (
                        <div key={index} className="text-sm m-4">
                            <AuthorProfileDisplay profile={like.user} />
                        </div>
                    ))
                ) : (
                    <div className="text-sm text-gray-500"> No Likes</div>
                )}
            </div>
        </div>
    );
}

function LikeCount({ likes }) {
    const { openOverlayModal } = useModal();
    const { userInfo } = useContext(UserContext);

    const openLikes = () => {
        openOverlayModal(LikeModal, {
            likes,
        });
    };

    if (likes === null) {
        return <LoadingComponent />;
    }

    const hasLikes = likes.length > 0;
    const likeText = likes.length === 1 ? "like" : "likes";
    const likeTextBody = hasLikes ? (
        <div className="font-semibold">
            {likes.length} {likeText}
        </div>
    ) : (
        <div>No likes</div>
    );

    return userInfo !== null && hasLikes ? (
        <Link onClick={openLikes}>{likeTextBody}</Link>
    ) : (
        likeTextBody
    );
}

export default LikeCount;

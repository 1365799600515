import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const LoginListener = () => {
    useEffect(() => {
        const handleStorageChange = () => {
            if (localStorage.getItem("login-event")) {
                window.location.reload();
            }
        };

        window.addEventListener("storage", handleStorageChange);

        return () => {
            window.removeEventListener("storage", handleStorageChange);
        };
    }, []);

    return null;
};

export default LoginListener;

import axios, { axiosPrivate } from "@api/axios";

class CommentService {
    static async makeComment(text, postId) {
        try {
            const response = await axiosPrivate.post("/comment", {
                text,
                postId,
            });

            return response.data;
        } catch (error) {
            console.error("Failed to make comment:", error);
            throw new Error(
                error.response
                    ? error.response.data.error
                    : "Failed to make comment."
            );
        }
    }

    static async getComments(postId) {
        try {
            const response = await axios.get("/comment", {
                params: {
                    postId: postId,
                },
            });

            return response.data.data;
        } catch (error) {
            console.error("Failed to get comments:", error);
            throw new Error(
                error.response
                    ? error.response.data.error
                    : "Failed to get comments."
            );
        }
    }

    static async deleteComment(commentId) {
        try {
            const response = await axiosPrivate.delete(`/comment/${commentId}`);

            if (!response.data.success) {
                throw new Error();
            }

            return response.data.data;
        } catch (error) {
            throw new Error("Failed to delete comment.");
        }
    }
}

export default CommentService;

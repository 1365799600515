import React, { useState, useRef } from "react";
import { ProfilePicture, AuthorProfileDisplay } from "@features/profile";
import { EllipsisHorizontalIcon, TrashIcon } from "@heroicons/react/24/outline";
import CommentService from "@services/CommentService";
import useOutsideClick from "@hooks/useOutsideClick";
import { useModal } from "@contexts/ModalContext";
import DeleteConfirmationModal from "@components/DeleteConfirmationModal";
import { Post } from "@classes/Post";

const OptionButton = ({ Icon, onClick, title }) => {
    return (
        <button
            onClick={onClick}
            className={`w-full text-left p-2 hover:bg-gray-200 text-sm`}
        >
            <div className="flex items-center space-x-2">
                <Icon className={"h-5 w-5 text-gray-500 hover:text-gray-700"} />
                <div>{title}</div>
            </div>
        </button>
    );
};
const DeleteCommentModal = ({ commentId, handleDeleteComment }) => {
    const onDeleteComment = async () => {
        try {
            handleDeleteComment(commentId);
            const response = await CommentService.deleteComment(commentId);
        } catch (error) {
            console.log("Error deleting comment: " + error);
        }
    };

    return (
        <DeleteConfirmationModal
            onDelete={onDeleteComment}
            deleteMessage={"Delete comment?"}
            closeModals={false}
        />
    );
};

const DeleteCommentButton = ({
    comment,
    handleDeleteComment,
    setShowOptions,
}) => {
    const { openOverlayModal } = useModal();
    const deleteComment = () => {
        openOverlayModal(DeleteCommentModal, {
            commentId: comment._id,
            handleDeleteComment: handleDeleteComment,
        });
        setShowOptions(false);
    };

    return (
        <OptionButton
            Icon={TrashIcon}
            onClick={deleteComment}
            title={"Delete Comment"}
        />
    );
};

const CommentActions = ({ comment, handleDeleteComment, setShowOptions }) => {
    return (
        <DeleteCommentButton
            comment={comment}
            handleDeleteComment={handleDeleteComment}
            setShowOptions={setShowOptions}
        />
    );
};

const CommentOptions = ({ comment, handleDeleteComment }) => {
    const [showOptions, setShowOptions] = useState(false);
    const optionsRef = useRef();
    useOutsideClick(optionsRef, () => setShowOptions(false));

    return (
        <div
            className="comment-options relative inline-block pl-2 invisible opacity-0 group-hover:visible group-hover:opacity-100"
            ref={optionsRef}
        >
            <button onClick={() => setShowOptions(!showOptions)}>
                <EllipsisHorizontalIcon className="h-5 w-5 text-black" />
            </button>
            {showOptions && (
                <div className="absolute left-0 bg-white border border-gray-300 rounded shadow-lg z-10 w-[180px]">
                    <CommentActions
                        comment={comment}
                        handleDeleteComment={handleDeleteComment}
                        setShowOptions={setShowOptions}
                    />
                </div>
            )}
        </div>
    );
};

function CommentDisplay({ commentItem, handleDeleteComment, currentUserId }) {
    if (!commentItem) return null;
    const commentTime = Post.timeDisplay(commentItem.createdAt);
    return (
        <div className="group mb-4 flex text-sm space-x-2">
            <div className="mt-1 flex-shrink-0">
                <ProfilePicture profile={commentItem.user} />
            </div>
            <div className="w-full">
                <div className="flex justify-between items-center">
                    <div className="flex space-x-1 items-center">
                        <AuthorProfileDisplay
                            profile={commentItem.user}
                            showProfilePicture={false}
                        />
                        {currentUserId?.toString() ===
                            commentItem?.user?._id?.toString() && (
                            <CommentOptions
                                comment={commentItem}
                                handleDeleteComment={handleDeleteComment}
                            />
                        )}
                    </div>
                    <div className="text-xs opacity-80">
                        <time>{commentTime}</time>
                    </div>
                </div>
                <p>{commentItem.text}</p>
            </div>
        </div>
    );
}

function CommentsDisplay({ allComments, handleDeleteComment, currentUserId }) {
    return (
        <div className="comments w-full">
            {allComments.map((commentItem, index) => (
                <CommentDisplay
                    key={index}
                    commentItem={commentItem}
                    handleDeleteComment={handleDeleteComment}
                    currentUserId={currentUserId}
                />
            ))}
        </div>
    );
}

export default CommentsDisplay;

import { createContext, useState, useEffect, useContext } from "react";
import { UserContext } from "@contexts/UserContext";
import { useNavigate } from "react-router-dom";
import { usePersonalPage } from "./PersonalPageContext";
import Page from "@classes/Page";
import { DefaultId } from "@constants";

const PersonalPagePostsContext = createContext();

export function PersonalPagePostsProvider({ children }) {
    const { userInfo } = useContext(UserContext);

    const [currentPage, setCurrentPage] = useState(null);
    const [currentPosts, setCurrentPosts] = useState(null);

    const [pageParam, setPageParam] = useState(null);
    const navigate = useNavigate();

    const { posts: postsMap, pages, loading } = usePersonalPage();

    useEffect(() => {
        setCurrentPageFromUrl();
    }, [pages, pageParam]);

    useEffect(() => {
        if (!currentPage || !postsMap) {
            return;
        }

        if (currentPage._id === DefaultId) {
            setCurrentPosts(Object.values(postsMap));
            return;
        }

        const populatedItems = currentPage?.items
            .map((postId) => postsMap[postId])
            .filter(Boolean); // Filter out undefined

        setCurrentPosts(populatedItems);
    }, [currentPage, postsMap, pages]);

    const setCurrentPageFromUrl = () => {
        if (pages && pageParam) {
            const page = pages.find((page) => page?.url === pageParam);
            if (page) {
                setCurrentPage(page);
                return;
            } else {
                setCurrentPage(pages[0]);
                return;
            }
        }
        const firstPage = pages?.length > 0 ? pages[0] : null;
        setCurrentPage(firstPage);
    };

    const handleSelectPage = (page) => {
        setCurrentPage(page);

        const username = userInfo?.username;
        const currentPath = window.location.pathname;
        const newPath = Page.getNewPagePath(username, page, currentPath);

        if (newPath !== currentPath) {
            navigate(newPath, { replace: true });
        }
    };

    return (
        <PersonalPagePostsContext.Provider
            value={{
                pages,
                setPageParam,
                currentPage,
                currentPosts,
                handleSelectPage,
                loading,
            }}
        >
            {children}
        </PersonalPagePostsContext.Provider>
    );
}

export function usePersonalPagePosts() {
    const context = useContext(PersonalPagePostsContext);
    if (!context) {
        throw new Error(
            "usePersonalPagePosts must be used within a PersonalPagePostsProvider"
        );
    }
    return context;
}

import React, {
    useState,
    useEffect,
    useCallback,
    useContext,
    useRef,
} from "react";
import useAuth from "@hooks/useAuth";
import NotificationService from "@services/NotificationService";
import { UserContext } from "./UserContext";

const NotificationContext = React.createContext([]);

const NotificationProvider = ({ children }) => {
    const { auth } = useAuth();
    const [notifications, setNotifications] = useState([]);
    const notificationVal = useRef([]);
    const { userInfo } = useContext(UserContext);
    const [followingPostCount, setFollowingPostCount] = useState(0);
    let interval;
    let lastTimestampRef = useRef(null);

    useEffect(() => {
        if (auth && Object.keys(auth).length != 0 && userInfo) {
            refreshNotifications();
            interval = setInterval(refreshNotifications, 30000);

            return () => clearInterval(interval);
        } else {
            if (interval) clearInterval(interval);
        }
    }, [auth, userInfo]);

    useEffect(() => {
        if (auth && Object.keys(auth).length != 0 && userInfo) {
            getFollowingPostCount();
            interval = setInterval(refreshNotifications, 120000);

            return () => clearInterval(interval);
        } else {
            if (interval) clearInterval(interval);
        }
    }, [auth, userInfo]);

    async function getFollowingPostCount() {
        const count = await NotificationService.getFollowingPostCount();
        setFollowingPostCount(count);
    }

    async function resetFollowingPostCount() {
        setFollowingPostCount(0);
    }

    function mergeNotifications(oldNotifications, newNotifications) {
        const merged = [...oldNotifications];

        for (const newNotif of newNotifications) {
            const index = merged.findIndex(
                (oldNotif) => oldNotif._id === newNotif._id
            );

            if (index !== -1) {
                merged[index] = newNotif;
            } else {
                merged.push(newNotif);
            }
        }

        merged.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

        const limitedMerged = merged.slice(0, 20);

        return limitedMerged;
    }

    async function refreshNotifications() {
        try {
            const newNotifications = await NotificationService.getNotifications(
                lastTimestampRef.current
            );

            let latestUpdatedAt = newNotifications.reduce(
                (latest, notification) => {
                    if (!latest || notification.updatedAt > latest) {
                        return notification.updatedAt;
                    }
                    return latest;
                },
                null
            );

            if (latestUpdatedAt) {
                lastTimestampRef.current = latestUpdatedAt;
            }

            const tempNotifications = mergeNotifications(
                notificationVal.current,
                newNotifications
            );
            setNotifications(tempNotifications);
            notificationVal.current = tempNotifications;
        } catch (error) {
            console.error("Error fetching notifications:", error);
        }
    }

    return (
        <NotificationContext.Provider
            value={{
                notifications,
                refreshNotifications,
                followingPostCount,
                resetFollowingPostCount,
            }}
        >
            {children}
        </NotificationContext.Provider>
    );
};

export default NotificationProvider;

export const useNotificationsHook = () => {
    return React.useContext(NotificationContext);
};

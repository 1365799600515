import React, { useEffect } from "react";
import { HeaderPage } from "@constants";
import { useHeader } from "@contexts/HeaderContext";
import { useNotificationsHook } from "@contexts/NotificationProvider";
import NotificationMessage from "@features/notifications/components/NotificationMessage";

function NotificationPage() {
    const { setActivePage } = useHeader();
    const { notifications } = useNotificationsHook();

    useEffect(() => {
        setActivePage(HeaderPage.Notification);
    });

    return (
        <div className="flex justify-center p-8 h-screen">
            <div className="w-1/3">
                <h2 className="text-2xl font-bold mb-4">Notifications</h2>
                {notifications.map((notification, index) => (
                    <NotificationMessage
                        key={index}
                        notification={notification}
                    />
                ))}
            </div>
        </div>
    );
}

export default NotificationPage;

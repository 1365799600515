import React from "react";
import { useModal } from "@contexts/ModalContext";
import { ModalType, PostType } from "@constants";
import { CreatePostState } from "./PostState";
import Dropdown from "@components/Dropdown";
import { PlusIcon } from "@heroicons/react/24/outline";
import { v4 as uuidv4 } from "uuid";

function CreatePostButton({
    currentPage,
    postType,
    newPostLabel,
    onCreatePostButtonClick = null,
    isFromCollection = false,
    children,
}) {
    const { openModal } = useModal();

    const handleCreatePostClick = () => {
        // Use key to create a new instance of CreatePost each time
        const key = uuidv4();
        openModal(
            CreatePostState,
            newPostLabel,
            {},
            {
                key,
                currentPage,
                postType,
                isFromCollection,
            },
            ModalType.WRITE
        );
    };

    return (
        <button
            onClick={(e) => {
                e.preventDefault();
                if (onCreatePostButtonClick) onCreatePostButtonClick(); // This order matters for collection -> create new item. Do this before opening new modal
                handleCreatePostClick();
            }}
            className={`create-${postType}-button w-full`}
        >
            <div className="py-2 px-4 relative flex items-center font-bold z-10 transition-none">
                {children}
            </div>
        </button>
    );
}

function CreateGemButton({ currentPage }) {
    return (
        <div className="rounded-full bg-purple-200 hover:shadow-lg hover:scale-105 text-purple-900">
            <CreatePostButton
                currentPage={currentPage}
                postType={PostType.GEM}
                newPostLabel={"New Gem"}
            >
                <div className="flex space-x-1 items-center justify-center">
                    <PlusIcon className="w-4 h-4 transition-none" />
                    <div className="">Gem</div>
                </div>
            </CreatePostButton>
        </div>
    );
}

function CreateCollectionButton({ currentPage, isTutorial }) {
    const newCollectionButton = (
        <div className="create-collection rounded-full bg-blue-200 hover:shadow-lg hover:scale-105 text-blue-900 flex space-x-2 items-center font-bold py-2 px-4">
            <div className="flex space-x-1 items-center">
                <PlusIcon className="w-4 h-4 transition-none justify-center" />
                <div className="">Collection</div>
            </div>
        </div>
    );

    return (
        <Dropdown
            widthAndPositionStyles="right-0 w-[180px]"
            triggerButton={newCollectionButton}
            shouldDetectOutsideClick={!isTutorial}
        >
            {(setShowDropdown) => (
                <div className="create-collection-options block absolute right-0 w-44 z-50">
                    <div className="bg-blue-200 rounded-full hover:scale-105 m-1 text-blue-900">
                        <CreatePostButton
                            currentPage={currentPage}
                            postType={PostType.AUTOCREATE}
                            newPostLabel={"New AI-Generated Collection"}
                            onCreatePostButtonClick={() => {
                                setShowDropdown(false);
                            }}
                        >
                            🪄 AI-Generated
                        </CreatePostButton>
                    </div>
                    <div className="bg-blue-200 rounded-full hover:scale-105 m-1 text-blue-900">
                        <CreatePostButton
                            currentPage={currentPage}
                            postType={PostType.COLLECTION}
                            newPostLabel={"New Collection"}
                            onCreatePostButtonClick={() => {
                                setShowDropdown(false);
                            }}
                        >
                            📚 From Items
                        </CreatePostButton>
                    </div>
                    {/* </div> */}
                </div>
            )}
        </Dropdown>
    );
}

export { CreatePostButton, CreateGemButton, CreateCollectionButton };

const PostType = {
    GEM: "Gem",
    COLLECTION: "Collection",
    PAGE: "Page",
    AUTOCREATE: "AI",
};

const ModalType = {
    WRITE: "write",
    VIEW: "view",
    CONFIRMCLOSE: "confirmClose",
    COLLECTIONITEM: "collectionItem",
};

const Endpoints = {
    ALL: "all",
    FOLLOWING: "following",
    FRIENDS: "friends",
    MY: "my",
    USER: "user",
    PUBLIC: "public",
    DISCOVER: "discover",
};

const PostStatus = {
    PUBLISHED: "published",
    DRAFT: "draft",
    REVIEW: "review",
};

const PostEditState = {
    Create: "create",
    Edit: "edit",
    Preview: "preview",
};

const HeaderPage = {
    Personal: 1,
    Explore: 2,
    Following: 3,
    Search: 4,
    Notification: 5,
};

const DefaultId = "00000000000000000d3fa014";
const DefaultPageTag = "DefaultHomePage";

const ContentType = {
    LINK: "link",
    BOOK: "book",
    MOVIE: "movie",
    AMAZON: "amazon",
    LOCATION: "location",
    VIDEO: "video",
    MUSIC: "music",
};

const ContentTypeToValue = {
    [ContentType.LINK]: "🔗",
    [ContentType.BOOK]: "📖",
    [ContentType.MOVIE]: "🎥",
};

const ContentTypeToPlaceholderText = {
    [ContentType.LINK]: "Embed a URL (optional)",
    [ContentType.BOOK]: "Enter a book title",
    [ContentType.MOVIE]: "Enter a movie title",
};

export {
    PostType,
    ModalType,
    Endpoints,
    PostStatus,
    PostEditState,
    DefaultId,
    DefaultPageTag,
    HeaderPage,
    ContentType,
    ContentTypeToValue,
    ContentTypeToPlaceholderText,
};

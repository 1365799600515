import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useHandleForm } from "@features/authentication";
import { ResendVerificationButton } from "@features/authentication";
import AuthService from "@services/AuthService";
import { LandingPageBase } from "@features/landing";

function VerifyEmailPage() {
    const [verificationState, setVerificationState] = useState({
        loading: true,
        success: false,
        error: null,
        message: null,
    });

    const {
        status,
        setStatus,
        userEmail,
        setUserEmail,
        showResendButton,
        setShowResendButton,
        handleResendVerification,
    } = useHandleForm();

    const navigate = useNavigate();

    const { token } = useParams();

    useEffect(() => {
        const verifyEmail = async () => {
            const result = await AuthService.verifyEmail(token);

            if (
                result.success ||
                result.message === "Email is already verified"
            ) {
                setVerificationState({
                    loading: false,
                    success: true,
                    error: null,
                    message: result.message,
                });
                setTimeout(() => {
                    navigate("/login"); // Redirect to login after 3 seconds
                }, 3000);
            } else {
                setVerificationState({
                    loading: false,
                    success: false,
                    error:
                        result.message ||
                        "Email verification failed as the link is invalid or expired.",
                });
                setShowResendButton(true);
            }
        };

        verifyEmail();
    }, []);

    if (verificationState.loading) {
        return (
            <div className="min-h-screen flex items-center justify-center text-xl bg-white p-8 rounded-md shadow-lg">
                <p>Verifying your email...</p>
            </div>
        );
    }

    if (
        verificationState.success &&
        verificationState.message === "Email is already verified"
    ) {
        return (
            <LandingPageBase>
                <div className="flex-col flex items-center justify-center space-y-4 bg-white p-8 rounded-md shadow-lg">
                    <p className="text-xl">
                        Your email has already been verified.
                    </p>
                    <p className="text-l">Redirecting to login...</p>
                </div>
            </LandingPageBase>
        );
    }

    if (verificationState.success) {
        return (
            <LandingPageBase>
                <div className="flex-col flex items-center justify-center space-y-4 bg-white p-8 rounded-md shadow-lg">
                    <p className="text-xl">
                        Your email has been successfully verified.
                    </p>
                    <p className="text-l">Redirecting to login...</p>
                </div>
            </LandingPageBase>
        );
    }

    return (
        <LandingPageBase>
            <div className="flex flex-col items-center justify-center space-y-4 bg-white p-8 rounded-md shadow-lg">
                <p className="text-xl text-red-400">
                    {verificationState.error || status}
                </p>
                {showResendButton && (
                    <div className="flex flex-col items-center space-y-2 w-full max-w-md">
                        <input
                            className="border px-4 py-2 rounded w-full"
                            type="email"
                            placeholder="Enter your email..."
                            value={userEmail}
                            onChange={(e) => setUserEmail(e.target.value)}
                        />
                        <ResendVerificationButton
                            email={userEmail}
                            onResend={handleResendVerification}
                        />
                    </div>
                )}
            </div>
        </LandingPageBase>
    );
}

export default VerifyEmailPage;

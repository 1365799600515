import React, { useState, useEffect } from "react";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { useHeader } from "@contexts/HeaderContext";
import { Endpoints, HeaderPage } from "@constants/constants";
import { usePosts } from "@hooks/usePosts";
import { PostGrid } from "@features/grid";
import LoadingComponent from "@components/LoadingComponent";

function ExploreDisplay() {
    const { setActivePage } = useHeader();
    const [endpoint] = useState(Endpoints.ALL);
    const { posts: categoryMap, loading } = usePosts({ endpoint: endpoint });
    const [tabIndex, setTabIndex] = useState(null);

    const [isTransitioning, setIsTransitioning] = useState(false);

    useEffect(() => {
        setActivePage(HeaderPage.Explore);
    }, []);

    useEffect(() => {
        setIsTransitioning(true);
        const timeout = setTimeout(() => setIsTransitioning(false), 200);
        return () => clearTimeout(timeout);
    }, [tabIndex]);

    const handleChange = (index) => {
        setTabIndex(index);
    };

    if (loading || !categoryMap) {
        return <LoadingComponent />;
    }

    return (
        <Tabs
            variant="soft-rounded"
            colorScheme="purple"
            onChange={handleChange}
            isLazy
        >
            <div className="pt-6 flex justify-center">
                <TabList className="category-tabs">
                    {Array.from(categoryMap.keys()).map((category) => (
                        <div key={category} className="px-2">
                            <Tab>{category}</Tab>
                        </div>
                    ))}
                </TabList>
            </div>
            <TabPanels>
                {Array.from(categoryMap.entries()).map(
                    ([category, categoryPosts]) => (
                        <div
                            className={`${
                                isTransitioning
                                    ? "grid-transition-out"
                                    : "grid-transition"
                            }`}
                            key={category}
                        >
                            {!isTransitioning && (
                                <TabPanel>
                                    <PostGrid posts={categoryPosts} />
                                </TabPanel>
                            )}
                        </div>
                    )
                )}
            </TabPanels>
        </Tabs>
    );
}

export default ExploreDisplay;

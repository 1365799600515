import { PRIVACY_LEVELS as PrivacyLevels, PostStatus } from "@constants";
import {
    GlobeAltIcon,
    UserGroupIcon,
    FaceSmileIcon,
    LockClosedIcon,
    PencilIcon,
} from "@heroicons/react/24/outline";
import Tooltip from "./Tooltip";

const privacyIcons = {
    [PrivacyLevels.PUBLIC]: GlobeAltIcon,
    [PrivacyLevels.FOLLOWERS]: UserGroupIcon,
    [PrivacyLevels.FRIENDS]: FaceSmileIcon,
    [PrivacyLevels.PRIVATE]: LockClosedIcon,
};

const getPrivacyIconLabel = (post) => {
    if (post.status === PostStatus.DRAFT) {
        return { Icon: PencilIcon, label: "Draft" };
    } else if (post.status === PostStatus.REVIEW) {
        return { Icon: PencilIcon, label: "AI-Generated. Please review" };
    } else {
        const privacyLevel = post.privacyLevel ?? PrivacyLevels.PUBLIC;
        const Icon = privacyIcons[privacyLevel];
        const label = privacyLevel;
        return { Icon, label };
    }
};

function PrivacyIcon({ privacyLevel }) {
    if (!privacyLevel) return;

    const Icon = privacyIcons[privacyLevel];
    return <Icon className="h-5 w-5 text-gray-500 text-opacity-60" />;
}

function PostPrivacyIcon({ post }) {
    const { Icon, label } = getPrivacyIconLabel(post);
    return (
        <Tooltip text={label} position={"top-full right-1/4"}>
            <Icon className={"h-5 w-5"} />
        </Tooltip>
    );
}

function PostPrivacyIconWithLabel({ post }) {
    const { Icon, label } = getPrivacyIconLabel(post);
    return (
        <div className="flex items-center text-gray-800 space-x-2">
            <Icon className="h-5 w-5" />
            <div className="text-sm">{label}</div>
        </div>
    );
}

export { PrivacyIcon, PostPrivacyIcon, PostPrivacyIconWithLabel };

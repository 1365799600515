import React, { useContext, useEffect, useRef } from "react";
import { Input, Textarea } from "@chakra-ui/react";
import { UserContext } from "@contexts/UserContext";
import { AuthorProfileDisplay } from "@features/profile";
import { PRIVACY_OPTIONS as PrivacyOptions, PostEditState } from "@constants";
import { FormSelectField } from "@components/Form";
import Button from "@components/Button";
import { PostFrame } from "@features/post";
import { SparkleLogo } from "@components/Logo";

function PrivacySelect({ postControl }) {
    const { currentPrivacyValue, handlePrivacyLevelChange } = postControl;
    return (
        <FormSelectField
            value={currentPrivacyValue}
            onChange={handlePrivacyLevelChange}
            options={PrivacyOptions}
            name="privacyLevel"
            placeholder="Set privacy level"
            isMulti={false}
        />
    );
}

const getTitleText = (postControl, postEditState) => {
    const { currentState } = postEditState;
    const { isGem, isCollection, isAutoCreate } = postControl;

    let title = "";
    if (isAutoCreate) {
        title = "AI Generated Collection";
    } else {
        if (currentState === PostEditState.Create) {
            title += "Create ";
        } else if (currentState === PostEditState.Edit) {
            title += "Edit ";
        }

        if (isGem) {
            title += "Gem";
        } else if (isCollection) {
            title += "Collection";
        }
    }

    return (
        <div className="font-title text-base flex items-center space-x-2">
            <SparkleLogo />
            {title}
        </div>
    );
};

function EditPostHeader({ postControl, postEditState }) {
    const titleText = getTitleText(postControl, postEditState);

    return (
        <div className="flex justify-between items-center text-sm">
            {titleText}
            <div className="flex items-center space-x-2">
                <div className="text-gray-600">Who can see this?</div>
                <PrivacySelect postControl={postControl} />
            </div>
        </div>
    );
}

function EditPostFrame({ postControl }) {
    const { post, handleInputChange } = postControl;
    const inputRef = useRef(null);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    return (
        <div className="my-4">
            <Input
                variant="unstyled"
                ref={inputRef}
                name="title"
                size="md"
                value={post.title}
                onChange={handleInputChange}
                placeholder="Title (e.g. 'Family Dumpling Recipe')"
                bg="white"
                _placeholder={{ color: "gray.700" }}
                pl="2"
            />
        </div>
    );
}

function EditPostNavigationButtons({ handleNext, disableNext = false }) {
    return (
        <div className="flex justify-center space-x-2">
            <Button
                onClick={handleNext}
                className="edit-post-next"
                disabled={disableNext}
                colors={
                    disableNext
                        ? "bg-indigo-700 bg-opacity-40"
                        : "bg-indigo-300 hover:bg-indigo-400"
                }
            >
                <div
                    className={
                        disableNext
                            ? "text-gray-700 font-bold"
                            : "text-black font-bold"
                    }
                >
                    Next
                </div>
            </Button>
        </div>
    );
}

function EditPostPanel({ postControl }) {
    const { userInfo } = useContext(UserContext);
    const { post, handleInputChange } = postControl;

    return (
        <div className="mt-2">
            <div className="text-sm mb-2">
                <AuthorProfileDisplay profile={userInfo?.profile} />
            </div>
            <div className="ml-2">
                <Textarea
                    variant="unstyled"
                    name="description"
                    value={post.description}
                    onChange={handleInputChange}
                    placeholder={
                        "Write a note (optional)\n\n" +
                        "(e.g. 'Got my mom to actually measure the seasonings for this recipe. You're welcome!')"
                    }
                    _placeholder={{ color: "gray.700", fontStyle: "italic" }}
                    size="sm"
                    bg="transparent"
                />
            </div>
        </div>
    );
}

function EditPost({ postControl, postEditState, children }) {
    const { getNextState, handleNextState } = postEditState;
    const { previewPost, isLinkLoading } = postControl;

    const handleNext = () => {
        const nextState = getNextState();
        if (nextState === PostEditState.Preview) {
            previewPost();
        }
        handleNextState();
    };

    return (
        <PostFrame
            leftContent={
                <>
                    <EditPostHeader
                        postControl={postControl}
                        postEditState={postEditState}
                    />
                    <EditPostFrame postControl={postControl} />
                    {children}
                    <div className="mt-4">
                        <EditPostNavigationButtons
                            handleNext={handleNext}
                            disableNext={isLinkLoading}
                        />
                    </div>
                </>
            }
            rightContent={<EditPostPanel postControl={postControl} />}
        />
    );
}

export default EditPost;

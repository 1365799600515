import React, { useEffect, useState } from "react";
import GridPostCommon from "./GridPostComponents";
import { GemGridContent, GemPost } from "@features/gem";
import { CollectionGridContent, CollectionPost } from "@features/collection";
import { PostType, ModalType } from "@constants/constants";
import { FullContentRenderer } from "@features/linkToGem";
import { useModal } from "@contexts/ModalContext";
import { useGlobalPosts } from "@contexts/GlobalPostContext";

function GridPostGem({ post, provided }) {
    const { openModal } = useModal();
    const onClick = () => {
        openModal(
            GemPost,
            post.title,
            {},
            { gem: post, contentRenderer: FullContentRenderer },
            ModalType.VIEW
        );
    };

    return (
        <GridPostCommon post={post} onClick={onClick} provided={provided}>
            <GemGridContent key={post._id} gem={post} />
        </GridPostCommon>
    );
}

function GridPostCollection({ post, provided }) {
    const { openModal } = useModal();
    const onClick = () => {
        openModal(
            CollectionPost,
            post.title,
            {},
            { collection: post },
            ModalType.VIEW
        );
    };

    return (
        <GridPostCommon post={post} provided={provided} onClick={onClick}>
            <CollectionGridContent key={post._id} collection={post} />
        </GridPostCommon>
    );
}

function GridPost({ post, provided = null }) {
    const [postInfo, setPostInfo] = useState(post);
    const { posts, getPost } = useGlobalPosts();

    useEffect(() => {
        const updatedPost = getPost(post);
        if (updatedPost) {
            setPostInfo(updatedPost);
        }
    }, [posts, post]);

    if (postInfo.type === PostType.GEM) {
        return <GridPostGem post={postInfo} provided={provided} />;
    } else if (postInfo.type === PostType.COLLECTION) {
        return <GridPostCollection post={postInfo} provided={provided} />;
    }
}

export default GridPost;

import React from "react";
import { HeartIcon as HeartIconSolid } from "@heroicons/react/24/solid";
import { HeartIcon as HeartIconOutline } from "@heroicons/react/24/outline";

function LikeButton({ isLiked, handleLikeToggle }) {
    return (
        <button onClick={handleLikeToggle} className="mr-4 focus:outline-none">
            {isLiked ? (
                <HeartIconSolid className="h-6 w-6 text-pink-500 hover:scale-110 transition-transform" />
            ) : (
                <HeartIconOutline className="h-6 w-6 text-pink-500 hover:scale-110 transition-transform" />
            )}
        </button>
    );
}

export default LikeButton;

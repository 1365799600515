import { Link } from "react-router-dom";
import { useRedirectIfLoggedIn } from "@hooks/useRedirectIfLoggedIn";

function LandingPageBase({ center = true, children }) {
    useRedirectIfLoggedIn();

    if (center) {
        return (
            <div
                className="flex flex-col h-screen justify-center items-center bg-center bg-no-repeat multicolor-gradient-purple"
                // style={{ backgroundImage: 'url("/images/background.webp")' }}
            >
                {children}
            </div>
        );
    }
    return (
        <div
            className="flex flex-col sm:flex-row bg-center bg-no-repeat multicolor-gradient-purple"
            // style={{ backgroundImage: 'url("/images/background.webp")' }}
        >
            {children}
        </div>
    );
}

export default LandingPageBase;

import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import FollowService from "@services/FollowService";
import { UserContext } from "@contexts/UserContext";
import Button from "@components/Button";

function FollowButton({ followersList, addFollower, removeFollower }) {
    const [followed, setFollowed] = useState(null);
    const { userInfo } = useContext(UserContext);
    const { username } = useParams();

    useEffect(() => {
        const followedState = followersList?.some(
            (user) => user.username == userInfo?.username
        );
        setFollowed(followedState);
    }, [username, userInfo, followersList]);

    const handleFollowClick = async () => {
        try {
            if (followed) {
                await FollowService.unfollowUser(username, userInfo.username);
                setFollowed(false);
                removeFollower(userInfo.username);
            } else {
                await FollowService.followUser(username, userInfo.username);
                setFollowed(true);
                addFollower(userInfo);
            }
        } catch (error) {
            console.error("Error updating follow status:", error);
        }
    };

    if (followed === null) {
        return null; // or a loading indicator
    }

    return (
        <Button
            colors={`${
                followed
                    ? "bg-gray-200 hover:bg-gray-300"
                    : "bg-indigo-100 hover:bg-indigo-200"
            }`}
            onClick={handleFollowClick}
        >
            <div className="font-semibold text-black">
                {followed ? "Following" : "Follow"}
            </div>
        </Button>
    );
}

export default FollowButton;

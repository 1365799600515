function Logo() {
    return (
        <div className="font-logo blue-purple-gradient-text px-2">
            Gems <SparkleLogo />
        </div>
    );
}
function SparkleLogo() {
    return <span className="font-logo blue-purple-gradient-text">✨</span>;
}

export { Logo, SparkleLogo };

import Profile from "@classes/Profile";
import PostService from "./PostService";
import { axiosPrivate } from "@api/axios";

class SearchService {
    static async search(searchText, scope) {
        try {
            let queryParam = PostService.buildQueryParam(scope);
            if (searchText) {
                queryParam += `&q=${encodeURIComponent(searchText)}`;
            }

            const response = await axiosPrivate.get(`/search${queryParam}`);

            const allItems = PostService.getPostsFromData(
                response.data.data.posts
            );
            const usersData = response.data.data.users;

            const users = usersData.map((user) => {
                return new Profile(
                    user.username,
                    user.profilePicture,
                    user.name,
                    user.bio,
                    user.privacyLevel
                );
            });
            return { posts: allItems, users: users };
        } catch (error) {
            console.error("Error searching posts:", error);
        }
    }
}

export default SearchService;

import React, { useEffect, useState, useContext } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { UserContext } from "@contexts/UserContext";
import { HeaderProvider } from "@contexts/HeaderContext";
import { useToast } from "@contexts/ToastContext";
import LoadingComponent from "@components/LoadingComponent";

function ProtectedRoute({ children }) {
    const navigate = useNavigate();
    const location = useLocation();
    const { userInfo, isAuthenticated, isLoading } = useContext(UserContext);
    const [isAllowed, setIsAllowed] = useState(false);
    const { Toast } = useToast();

    useEffect(() => {
        if (isLoading) {
            return;
        }

        if (isAuthenticated()) {
            setIsAllowed(true);
        } else {
            navigate("/welcome", { state: { from: location } });
        }
    }, [userInfo, isAuthenticated, isLoading, navigate, location]);

    if (isLoading || !isAllowed) {
        return <LoadingComponent />;
    }

    return (
        <main className="bg-white">
            {children ? (
                children
            ) : (
                <>
                    <HeaderProvider>
                        <div className="body-content">
                            <Outlet />
                            <Toast />
                        </div>
                    </HeaderProvider>
                </>
            )}
        </main>
    );
}

export default ProtectedRoute;

import {
    SpecializedContentRenderers,
    SpecializedDescriptionRenderers,
    DefaultRenderer,
} from "./renderers";
import DefaultLinkRenderer from "./renderers/DefaultLinkRenderer";

const FullContentRenderer = (gem) => {
    if (!gem) return null;

    let linkContent;
    if (gem.metadata) {
        const specializedContentRenderer = SpecializedContentRenderers.find(
            (s) =>
                s.detect(
                    gem?.metadata?.url,
                    gem?.metadata?.tag,
                    gem?.contentType
                )
        );

        if (specializedContentRenderer) {
            linkContent = specializedContentRenderer.content(gem);
        } else {
            linkContent = DefaultLinkRenderer.content(gem);
        }
    }

    let userContent;
    if (gem.content) {
        userContent = DefaultRenderer.content(gem);
    }

    return (
        <>
            {linkContent && <div className="mb-4">{linkContent}</div>}
            {userContent && <div className="mb-2 text-base">{userContent}</div>}
        </>
    );
};

const PreviewContentRenderer = (gem) => {
    let contentRenderer = SpecializedDescriptionRenderers.find((s) =>
        s.detect(gem?.metadata?.link, gem?.contentType)
    );

    let previewContent;
    let userContent;
    if (contentRenderer) {
        previewContent = contentRenderer.preview(gem);
        userContent = DefaultRenderer.content(gem);
    } else if (hasLink(gem)) {
        previewContent = DefaultLinkRenderer.preview(gem);
        userContent = DefaultRenderer.content(gem);
    } else {
        previewContent = DefaultRenderer.preview(gem);
    }

    return (
        <>
            {previewContent && <div className="mb-4">{previewContent}</div>}
            {userContent && (
                <div className="mb-2 text-sm text-gray-500">{userContent}</div>
            )}
        </>
    );
};

const TitleRenderer = (gem) => {
    const contentRenderer = SpecializedContentRenderers.find((s) =>
        s.detect(gem?.metadata?.link, gem?.metadata?.tag || gem?.contentType)
    );
    if (contentRenderer) {
        return contentRenderer.title(gem);
    }

    const defaultRenderer = getDefaultRenderer(gem);
    return defaultRenderer.title(gem);
};

const hasLink = (gem) => gem?.metadata?.link;
const getDefaultRenderer = (gem) =>
    hasLink(gem) ? DefaultLinkRenderer : DefaultRenderer;

export { FullContentRenderer, PreviewContentRenderer, TitleRenderer };

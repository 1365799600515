import React from "react";
import { LinkDisplay, LinkDisplayWithLink } from "@components/Link";
import { SplitContentRendererFrame } from "../RendererFrames";
import DefaultLinkRenderer from "./DefaultLinkRenderer";

function isAmazonProductUrl(url) {
    // Common Amazon product URL patterns
    const amazonProductUrlPatterns = [
        /https?:\/\/(?:www\.)?amazon\.com\/(?:[\w-]+\/)?dp\/[A-Za-z0-9]+/,
        /https?:\/\/(?:www\.)?amazon\.com\/(?:[\w-]+\/)?gp\/product\/[A-Za-z0-9]+/,
        /https?:\/\/(?:www\.)?amazon\.com\/(?:[\w-]+\/)?asin\/[A-Za-z0-9]+/,
        /https?:\/\/amzn\.com\/[A-Za-z0-9]+/,
    ];

    // Check if the URL matches any of the patterns
    return amazonProductUrlPatterns.some((pattern) => pattern.test(url));
}

const getRenderedContent = (gem) => {
    const linkInfo = gem.metadata;
    delete linkInfo.logo;

    return (
        <>
            <div className="bg-white rounded-md shadow-md transform transition-transform hover:shadow-lg">
                <LinkDisplayWithLink linkInfo={linkInfo} gem={gem} />
            </div>
            {linkInfo.image ? (
                <img
                    src={linkInfo.image}
                    alt={linkInfo.title}
                    className="w-full max-h-[320px] object-cover mt-4"
                />
            ) : null}
            <p className="mt-4 mb-2 text-md text-gray-600">
                {linkInfo.description}
            </p>
        </>
    );
};

const AmazonRenderer = {
    detect: (url) => {
        return isAmazonProductUrl(url);
    },

    content: getRenderedContent,

    preview: (gem) => {
        const linkInfo = gem.metadata;
        delete linkInfo.logo;

        return (
            <SplitContentRendererFrame gem={gem} previewImage={linkInfo.image}>
                <LinkDisplay linkInfo={linkInfo} gem={gem} />
                <p className="mt-2 mb-2 text-md text-oversize-2 text-gray-600">
                    {linkInfo.description}
                </p>
            </SplitContentRendererFrame>
        );
    },

    title: (gem) => {
        const linkInfo = gem.metadata;
        if (linkInfo.logo) {
            return DefaultLinkRenderer.title(gem);
        }
        return <>🛒 {gem.title}</>;
    },
};

export default AmazonRenderer;

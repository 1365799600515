import { useEffect } from "react";
import axios from "@api/axios";
import useAuth from "./useAuth";

const useRefreshToken = () => {
    const { setIsLoading, auth, setAuth } = useAuth();

    useEffect(() => {
        const verifyRefreshToken = async () => {
            try {
                await refresh();
            } catch (err) {
            } finally {
                setIsLoading(false);
            }
        };

        !auth?.accessToken ? verifyRefreshToken() : setIsLoading(false);
    }, []);

    const refresh = async () => {
        const response = await axios.get("/users/refreshToken", {
            withCredentials: true,
        });
        setAuth((prev) => {
            return {
                ...prev,
                accessToken: response.data.accessToken,
            };
        });
        return response.data.accessToken;
    };
    return refresh;
};

export default useRefreshToken;

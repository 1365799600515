import React, { useState, useRef } from "react";
import useOutsideClick from "@hooks/useOutsideClick";

const Dropdown = ({
    widthAndPositionStyles,
    triggerButton,
    shouldDetectOutsideClick = true,
    children,
}) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const dropdownRef = useRef();
    useOutsideClick(
        dropdownRef,
        () => setShowDropdown(false),
        shouldDetectOutsideClick
    );

    const handleClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setShowDropdown(!showDropdown);
    };

    return (
        <div className="relative inline-block" ref={dropdownRef}>
            <button onClick={handleClick}>{triggerButton}</button>
            {showDropdown && (
                <div
                    className={`mt-2 bg-white rounded shadow-lg absolute z-50 ${widthAndPositionStyles}`}
                >
                    {children(setShowDropdown)}
                </div>
            )}
        </div>
    );
};

export default Dropdown;

import React, { useEffect, useState } from "react";
import ProfileService from "@services/ProfileService";
import UserPageDisplay from "@features/userPage/components/UserPageDisplay";
import { useHeader } from "@contexts/HeaderContext";

function UserPage({ username }) {
    const [loading, setLoading] = useState(true);
    const [profile, setProfile] = useState(null);
    const { isSidePanelOpen } = useHeader();

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const profileData = await ProfileService.getProfile(username);
                setProfile(profileData);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching profile:", error);
            }
        };

        fetchProfile();
    }, [username]);

    return UserPageDisplay({ username, profile, loading, isSidePanelOpen });
}

export default UserPage;

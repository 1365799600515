import {
    PostType,
    PostStatus,
    PRIVACY_LEVELS as PrivacyLevels,
} from "@constants";
import { v4 as uuidv4 } from "uuid";

class Post {
    constructor(options = {}) {
        this._id = options._id;
        this.title = options.title;
        this.description = options.description;
        this.author = options.author;
        this.createdAt = options.createdAt;
        this.updatedAt = options.updatedAt;
        this.privacyLevel = options.privacyLevel;
        this.likeCount = options.likeCount;
        this.commentCount = options.commentCount;
        this.status = options.status;
        this.isLoading = options.isLoading;
        this.fullQuality = false;
    }

    equals(other) {
        return this._id === other._id;
    }

    updateField(fieldName, value) {
        this[fieldName] = value;
    }

    display() {
        console.log(
            `Title: ${this.title}\nDescription: ${this.description}\nAuthor: ${this.author}`
        );
    }

    isPersonalPost(userId) {
        return this.author?._id === userId;
    }

    static copyFrom(post) {
        const copy =
            post.type === PostType.GEM
                ? new Gem()
                : post.type === PostType.COLLECTION
                ? new Collection()
                : new Post();
        Object.assign(copy, post);
        return copy;
    }

    static createEncodedString(id, type) {
        const data = { id, type };
        const buffer = btoa(JSON.stringify(data));
        return buffer.toString("base64");
    }

    static decodeString(queryString) {
        const buffer = atob(queryString, "base64");
        const data = JSON.parse(buffer.toString());
        return data;
    }

    static timeDisplay(time) {
        const now = new Date();
        const inputTime = new Date(time);
        const differenceInTime = now - inputTime;

        // Time constants
        const oneMinute = 1000 * 60;
        const oneHour = 1000 * 60 * 60;
        const oneDay = oneHour * 24;
        const oneWeek = oneDay * 7;

        // Minutes ago
        if (differenceInTime < oneHour) {
            const minutesAgo = Math.max(
                Math.ceil(differenceInTime / oneMinute),
                1
            ); // Round up to avoid "0 minutes ago"
            return minutesAgo === 1
                ? `${minutesAgo} minute ago`
                : `${minutesAgo} minutes ago`;
        }

        // Hours ago
        if (differenceInTime < oneDay) {
            const hoursAgo = Math.floor(differenceInTime / oneHour);
            return hoursAgo === 1
                ? `${hoursAgo} hour ago`
                : `${hoursAgo} hours ago`;
        }

        // Days ago
        if (differenceInTime < oneWeek) {
            const daysAgo = Math.floor(differenceInTime / oneDay);
            return daysAgo === 1 ? `${daysAgo} day ago` : `${daysAgo} days ago`;
        }

        // If the date is more than one week ago
        return inputTime.toLocaleDateString("en-US", {
            month: "short",
            day: "numeric",
            year: "numeric",
        });
    }

    static toPost(post) {
        return new Post({
            _id: post._id,
            title: post.title,
            description: post.description,
            author: post.author,
            createdAt: post.createdAt,
            updatedAt: post.updatedAt,
            privacyLevel: post.privacyLevel,
            likeCount: post.likeCount,
            commentCount: post.commentCount,
            status: post.status,
        });
    }
}

class Gem extends Post {
    constructor(options = {}) {
        super(options);
        this.type = PostType.GEM;
        this.content = options.content;
        this.link = options.link;
        this.gemId = options.gemId;
        this.metadata = options.metadata;
        this.contentType = options.contentType;
    }

    static init(user) {
        return new Gem({
            title: "",
            description: "",
            author: user,
            privacyLevel: PrivacyLevels.PUBLIC,
        });
    }

    static toGem(post) {
        return new Gem({
            _id: post._id,
            title: post.title,
            description: post.description,
            author: post.author,
            createdAt: post.createdAt,
            updatedAt: post.updatedAt,
            privacyLevel: post.privacyLevel,
            likeCount: post.likeCount,
            commentCount: post.commentCount,
            status: post.status,
        });
    }

    static getLoadingGem(userInfo) {
        const now = new Date();
        return new Gem({
            _id: uuidv4(),
            author: userInfo,
            updatedAt: now,
            createdAt: now,
            isLoading: true,
        });
    }
}

class Collection extends Post {
    constructor(options = {}) {
        super(options);
        this.type = PostType.COLLECTION;
        this.items = options.items;
        this.isAutoCreated = options.isAutoCreated;
    }

    static init(user, isAutoCreated) {
        return new Collection({
            title: "",
            description: "",
            author: user,
            privacyLevel: PrivacyLevels.PUBLIC,
            isAutoCreated: isAutoCreated,
        });
    }

    updateField(fieldName, value) {
        // Override updateField method to handle 'items' property
        if (fieldName === "items") {
            this.items = value;
        } else {
            super.updateField(fieldName, value);
        }
    }

    static toCollection(post) {
        return new Collection({
            _id: post._id,
            title: post.title,
            description: post.description,
            author: post.author,
            createdAt: post.createdAt,
            updatedAt: post.updatedAt,
            privacyLevel: post.privacyLevel,
            likeCount: post.likeCount,
            commentCount: post.commentCount,
            status: post.status,
        });
    }

    static getLoadingCollection(userInfo, inReview = false) {
        const now = new Date();
        const collectionData = {
            _id: uuidv4(),
            author: userInfo,
            updatedAt: now,
            createdAt: now,
            isLoading: true,
        };
        if (inReview) {
            collectionData.status = PostStatus.REVIEW;
        }
        return new Collection(collectionData);
    }
}

class PostUtility {
    static mergeAndSort(gems, collections) {
        const allItems = [...gems, ...collections];
        allItems.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
        return allItems;
    }
}

export { Post, Gem, Collection, PostUtility };

import React, { useEffect, useState } from "react";
import { PostGrid } from "@features/grid";
import { Endpoints } from "@constants/constants";
import LoadingComponent from "@components/LoadingComponent";
import { usePosts } from "@hooks/usePosts";
import { Search } from "@features/search";
import { MultiProfileGrid } from "@features/profile";
import { useHeader } from "@contexts/HeaderContext";
import { HeaderPage } from "@constants";

function SearchPage() {
    const { posts, loading } = usePosts({ endpoint: Endpoints.ALL });
    const [defaultPosts, setDefaultPosts] = useState(null);
    const [searchResults, setSearchResults] = useState(null);
    const { setActivePage } = useHeader();

    useEffect(() => {
        setActivePage(HeaderPage.Search);
    }, []);

    useEffect(() => {
        const allPosts = Array.from(posts.values())
            .reduce(
                (accumulator, truePosts) => accumulator.concat(truePosts),
                []
            )
            .filter(
                (post, index, self) =>
                    self.findIndex((p) => p._id === post._id) === index
            );
        setDefaultPosts(allPosts);
    }, [posts]);

    const displayedPosts = searchResults?.posts || defaultPosts;
    const displayedUsers = searchResults?.users || [];
    const limitedDisplayedUsers = displayedUsers.slice(0, 3);

    return (
        <div className="pt-8 px-2">
            <div className="flex justify-center items-center mb-2">
                <div className="w-1/3">
                    <Search
                        scope={Endpoints.ALL}
                        handleSearchResults={setSearchResults}
                    />
                </div>
            </div>
            <MultiProfileGrid users={limitedDisplayedUsers} />

            {loading ? (
                <LoadingComponent />
            ) : (
                <PostGrid posts={displayedPosts} />
            )}
        </div>
    );
}

export default SearchPage;

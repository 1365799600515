// services/VimeoService.js

import { CreateEmbedRenderer } from "../EmbedRenderer";
import DefaultLinkRenderer from "./DefaultLinkRenderer";

const iframeProps = {
    width: "640",
    height: "360",
    frameBorder: "0",
    allow: "autoplay; fullscreen",
    allowFullScreen: true,
};

function getEmbedURL(url) {
    const match = url.match(/^https:\/\/vimeo\.com\/(\d+)$/);
    return match ? `https://player.vimeo.com/video/${match[1]}` : null;
}

const VimeoRenderer = {
    detect: (url) => /^https:\/\/vimeo\.com\/\d+$/.test(url),

    content: (gem) => {
        return CreateEmbedRenderer(gem, getEmbedURL, iframeProps);
    },
    title: (gem) => {
        return <>🎥 {gem.title}</>;
    },
};

export default VimeoRenderer;

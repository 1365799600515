import React from "react";

function Loader({ size = "h-5 w-5" }) {
    return (
        <>
            <div
                className={`loader ease-linear rounded-full border-8 border-t-8 border-gray-200 ${size}`}
            ></div>
            <style>{`
        .loader {
            border-top-color: #6a00ff;
            animation: spinner 1s linear infinite;
        }

        @keyframes spinner {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }
    `}</style>
        </>
    );
}

export default Loader;

import React from "react";

function ResendVerificationButton({ email, onResend }) {
    return (
        <button
            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded"
            onClick={() => onResend(email)}
        >
            Resend Verification Email
        </button>
    );
}

export default ResendVerificationButton;

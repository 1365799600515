import React from "react";
import {
    BuildingStorefrontIcon,
    BookOpenIcon,
    FireIcon,
    GlobeAsiaAustraliaIcon,
    BuildingOfficeIcon,
    CheckBadgeIcon,
    MusicalNoteIcon,
    BookmarkIcon,
    HandThumbUpIcon,
} from "@heroicons/react/24/solid";

function InlineScrollListElement({ text, icon }) {
    return (
        <div className="flex pr-16">
            <div
                className="pr-3 text-white "
                style={{ display: "inline-block" }}
            >
                {text}
            </div>
            <div style={{ display: "inline-block" }}>{icon}</div>
        </div>
    );
}

const SimpleSlider = ({ list, fast = false }) => {
    let sliderClass =
        "text-white flex min-w-max justify-between overflow-hidden animate-loop-scroll";
    if (fast) {
        sliderClass =
            "text-white flex min-w-max justify-between overflow-hidden animate-loop-scroll-fast";
    }
    return (
        <div className={sliderClass}>
            {list.map((item, index) => (
                <div key={index} className="flex-shrink-0">
                    {item}
                </div>
            ))}
        </div>
    );
};

const AutoScroller = ({}) => {
    return (
        <>
            <hr className="w-full pb-2"></hr>
            <div className="flex overflow-hidden">
                <div className="flex-shrink-0">
                    <SimpleSlider fast={true} list={list1Elements} />
                </div>
                <div className="ml-auto">
                    <SimpleSlider fast={true} list={list1Elements} />
                </div>
            </div>
            <hr className="w-full my-2"></hr>
            <div className="flex overflow-hidden">
                <div className="flex-shrink-0">
                    <SimpleSlider list={list2Elements} />
                </div>
                <div className="ml-auto">
                    <SimpleSlider list={list2Elements} />
                </div>
            </div>
            <hr className="w-full my-2"></hr>
        </>
    );
};

const list1 = [
    {
        text: "fav restaurants in nyc",
        icon: <BuildingStorefrontIcon className="inline-block w-5" />,
    },
    {
        text: "fiction books i couldn't put down",
        icon: <BookOpenIcon className="inline-block w-5" />,
    },
    {
        text: "recipes i'll cook forever",
        icon: <FireIcon className="inline-block w-5" />,
    },
    {
        text: "my fav PNW hiking trails",
        icon: <GlobeAsiaAustraliaIcon className="inline-block w-5" />,
    },
    {
        text: "small businesses to support",
        icon: <BuildingOfficeIcon className="inline-block w-5" />,
    },
];

const list2 = [
    {
        text: "to-do’s I loved in Mexico City",
        icon: <CheckBadgeIcon className="inline-block w-5" />,
    },
    {
        text: "listen to these albums in order",
        icon: <MusicalNoteIcon className="inline-block w-5" />,
    },
    {
        text: "thought-provoking articles from this week",
        icon: <BookmarkIcon className="inline-block w-5" />,
    },
    {
        text: "non-fiction books that made my life better",
        icon: <HandThumbUpIcon className="inline-block w-5" />,
    },
];

const list1Elements = generateInlineScrollList(list1);
const list2Elements = generateInlineScrollList(list2);

function generateInlineScrollList(list) {
    return list.map((item, index) => (
        <InlineScrollListElement
            key={index}
            text={item.text}
            icon={item.icon}
        />
    ));
}

export default AutoScroller;

import React, { useState, useEffect } from "react";

function Tooltip({ children, text, position = null }) {
    const [isHovered, setIsHovered] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);
    let hoverTimeout;

    const handleMouseEnter = () => {
        hoverTimeout = setTimeout(() => {
            setShowTooltip(true);
        }, 500); // Delay in milliseconds
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        clearTimeout(hoverTimeout);
        setShowTooltip(false);
        setIsHovered(false);
    };

    useEffect(() => {
        return () => {
            clearTimeout(hoverTimeout); // Clear timeout on component unmount
        };
    }, []);

    return (
        <div className="relative flex items-center">
            <div
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                {children}
            </div>
            {showTooltip && (
                <div
                    className={`absolute ${
                        position ?? "top-full"
                    } mt-1 px-2 py-1 min-w-max bg-white text-black border border-gray-300 rounded shadow-lg text-sm z-50 whitespace-nowrap transition-opacity duration-300 ease-in-out ${
                        isHovered
                            ? "opacity-100 visible"
                            : "opacity-0 invisible"
                    }`}
                    style={{
                        transition: "visibility 0s, opacity 0.3s linear",
                        visibility: isHovered ? "visible" : "hidden",
                    }}
                >
                    {text}
                </div>
            )}
        </div>
    );
}

export default Tooltip;

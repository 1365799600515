// privacyLevels.js

const PRIVACY_LEVELS = {
    PUBLIC: "Public",
    FOLLOWERS: "Followers",
    FRIENDS: "Friends",
    PRIVATE: "Private",
    EVERYONE: "Everyone",
    ONLY_ME: "Only Me",
};

const PRIVACY_OPTIONS = [
    { value: PRIVACY_LEVELS.PUBLIC, label: PRIVACY_LEVELS.EVERYONE },
    { value: PRIVACY_LEVELS.FOLLOWERS, label: PRIVACY_LEVELS.FOLLOWERS },
    // { value: PRIVACY_LEVELS.FRIENDS, label: PRIVACY_LEVELS.FRIENDS },
    { value: PRIVACY_LEVELS.PRIVATE, label: PRIVACY_LEVELS.ONLY_ME },
];

export { PRIVACY_LEVELS, PRIVACY_OPTIONS };

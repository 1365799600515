import React from "react";
import { LinkWrapper } from "@components/Link";
import { SplitContentRendererFrame } from "../RendererFrames";
import { ContentType } from "@constants";

const getRenderedContent = (gem) => {
    const metadata = gem.metadata;
    return (
        <LinkWrapper linkInfo={metadata}>
            <SplitContentRendererFrame
                gem={gem}
                isContent={true}
                previewTitle={`📖 ${metadata?.title}`}
                previewImage={metadata?.image}
            >
                {metadata?.author && (
                    <div className="mb-4">Written by {metadata.author}</div>
                )}
                {metadata?.description && (
                    <p className="mt-4 text-md text-oversize-4">
                        {metadata.description}
                    </p>
                )}
                {metadata?.publisher && (
                    <div className="mt-4 text-md text-oversize-1 text-gray-500">
                        View on {metadata.publisher}
                    </div>
                )}
            </SplitContentRendererFrame>
        </LinkWrapper>
    );
};

const getPreviewContent = (gem) => {
    const metadata = gem.metadata;
    return (
        <SplitContentRendererFrame
            gem={gem}
            previewTitle={`📖 ${metadata?.title}`}
            previewImage={metadata?.image}
        >
            <div className="text-md">Written by {metadata?.author}</div>
            <p className="my-2 text-md text-oversize-2 text-gray-600">
                {metadata?.description}
            </p>
        </SplitContentRendererFrame>
    );
};

const BookRenderer = {
    detect: (url, tag, contentType) => {
        return (
            tag?.toLowerCase() === ContentType.BOOK ||
            contentType?.toLowerCase() === ContentType.BOOK
        );
    },

    content: getRenderedContent,

    preview: (gem) => {
        return getPreviewContent(gem);
    },

    title: (gem) => {
        return <>📖 {gem.title}</>;
    },
};

export default BookRenderer;

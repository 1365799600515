import React from "react";
import GridPost from "./GridPost";
import MasonryGrid from "./MasonryGrid";

function PostGrid({ posts }) {
    const hasPosts = posts?.length > 0;
    return (
        <>
            {hasPosts ? (
                <MasonryGrid posts={posts}>
                    {(distributedPosts) =>
                        distributedPosts &&
                        distributedPosts.length > 0 && (
                            <div className="flex">
                                {distributedPosts.map((column, index) => (
                                    <div
                                        key={index}
                                        className="flex-1 max-w-full overflow-hidden"
                                    >
                                        {column.map((post) => (
                                            <div
                                                key={post?._id}
                                                className="mx-3 my-6"
                                            >
                                                <GridPost post={post} />
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        )
                    }
                </MasonryGrid>
            ) : (
                <div className="flex justify-center font-title text-lg">
                    No posts found.
                </div>
            )}
        </>
    );
}

export default PostGrid;

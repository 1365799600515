import React from "react";
import { ProfilePicture } from "..";
import { FollowButton, FollowPanel } from "@features/follow";
import { useFollow } from "../../follow/hooks/useFollow";

function ProfileTile({ profile, showFollowButton = true }) {
    const { followersList, followingList, addFollower, removeFollower } =
        useFollow(profile?.username);

    if (!profile) {
        return;
    }

    return (
        <div className="flex">
            <div className="flex items-center space-x-4 mb-4">
                <ProfilePicture profile={profile} size={"w-16 h-16"} />
                <div>
                    <p className="text-lg font-semibold">
                        {profile.name ||
                            profile.username ||
                            "Name not provided"}
                    </p>
                    <p className="mb-2 text-sm">{profile.bio || ""}</p>
                    <FollowPanel
                        followersList={followersList}
                        followingList={followingList}
                    />
                </div>
            </div>
            {showFollowButton ? (
                <div className="ml-4 pt-2">
                    <FollowButton
                        followersList={followersList}
                        addFollower={addFollower}
                        removeFollower={removeFollower}
                    />
                </div>
            ) : (
                <></>
            )}
        </div>
    );
}

export default ProfileTile;

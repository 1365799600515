import React, { useEffect, useState } from "react";
import { PostType } from "@constants/constants";
import { usePersonalPage } from "@contexts/PersonalPageContext";

export const useCollectionForm = (postControl) => {
    const [oldPosts, setOldPosts] = useState([]);

    const {
        post: collectionInfo,
        updatePost,
        additionalPostData,
        setAdditionalPostData,
    } = postControl;

    const { posts, getPost } = usePersonalPage();

    useEffect(() => {
        const postItems = Object.values(posts);
        if (oldPosts.length === 0) {
            setItemOptions(postItems);
        } else {
            // If there are new items that have been created during the Collection creation process,
            // assume they are new items to be added to the collection
            const newPosts = postItems?.filter(
                (post) => !oldPosts.hasOwnProperty(post._id)
            );

            if (newPosts?.length > 0) {
                addNewPostsToCollectionItems(newPosts);
            }
        }

        setOldPosts(posts);
    }, [posts]);

    const updateCollectionAndOptionItems = (
        updateCollectionsCallback,
        updateOptionsCallback
    ) => {
        const updatedCollectionItems = updateCollectionsCallback(
            additionalPostData.collectionItems || []
        );

        const updatedItemOptions = updateOptionsCallback(
            additionalPostData.itemOptions || []
        );

        // AdditionalPostData holds the index of the item in itemOptions, so if removed it can be placed back in order.
        setAdditionalPostData((prevState) => ({
            ...prevState,
            itemOptions: updatedItemOptions,
            collectionItems: updatedCollectionItems,
        }));

        // Post items holds the actual item info. We get it from post context to make sure it's the most recent version of the item.
        updatePost({
            items: updatedCollectionItems.map((collectionItem) =>
                getPost(collectionItem?.addedItemId)
            ),
        });
    };

    const isValidItemOption = (item) => {
        return (
            item &&
            (item.type !== PostType.COLLECTION ||
                // When editing an existing collection, filter out collections that contain the current collection.
                (item.type === PostType.COLLECTION &&
                    item._id !== collectionInfo?._id &&
                    !item.items?.some(
                        (collectionItem) =>
                            collectionItem.type === PostType.COLLECTION &&
                            collectionItem._id === collectionInfo?._id
                    ))) &&
            // Exclude items already in the collection
            !collectionInfo?.items?.some(
                (collectionItem) => item._id === collectionItem._id
            )
        );
    };

    const setItemOptions = (postItems) => {
        const itemOptions = postItems.filter((item) =>
            isValidItemOption(item, collectionInfo)
        );

        setAdditionalPostData((prevState) => ({
            ...prevState,
            itemOptions: itemOptions.map((item) => item._id),
        }));
    };

    const addNewPostsToCollectionItems = (newPosts) => {
        const newPostItems = newPosts.map((post) => ({
            addedItemId: post._id,
            addedItemIndex: -1,
        }));

        setAdditionalPostData((prevState) => ({
            ...prevState,
            collectionItems: [
                ...newPostItems,
                ...(prevState?.collectionItems || []),
            ],
        }));

        updatePost({
            items: [...newPosts, ...(collectionInfo?.items || [])],
        });
    };

    return {
        additionalPostData,
        updateCollectionAndOptionItems,
        getPost,
    };
};

import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { UserContext } from "@contexts/UserContext";
import { PATHS } from "@features/routing";

function IndexPage() {
    const { userInfo } = useContext(UserContext);

    if (userInfo) {
        return <Navigate to={PATHS.getUserPath(userInfo.username)} />;
    }

    return <Navigate to="/explore" />;
}

export default IndexPage;

import { useState } from "react";
import AuthService from "@services/AuthService";

function useHandleForm(initialStatus) {
    const [status, setStatus] = useState(initialStatus);
    const [failureStatus, setFailureStatus] = useState(null);
    const [userEmail, setUserEmail] = useState(null);
    const [showResendButton, setShowResendButton] = useState(false);

    const handleResendVerification = async () => {
        const result = await AuthService.resendVerification(userEmail);

        if (result.success) {
            setStatus("Verification email resent. Please check your inbox.");
        } else {
            setStatus(result.message);
        }
    };

    return {
        status,
        setStatus,
        failureStatus,
        setFailureStatus,
        userEmail,
        setUserEmail,
        showResendButton,
        setShowResendButton,
        handleResendVerification,
    };
}

export default useHandleForm;

import { PRIVACY_LEVELS as PrivacyLevels, PostStatus } from "@constants";
import { getContentImages } from "@util/file-utils";

export const useGemForm = (postControl) => {
    const {
        post,
        editorContent,
        setEditorContent,
        updatePost,
        setIsLinkLoading,
    } = postControl;

    const handleContentChange = (selected) => {
        updatePost({ content: selected });
    };

    const handleLinks = (link, linkInfo) => {
        if (linkInfo === null) {
            updatePost({
                link: link,
                metadata: null,
                title: post?.title || "",
            });
        } else {
            const linkData = { ...linkInfo };
            linkData.info = linkData?.metadata;
            const contentType = linkData?.contentType;
            delete linkData.metadata;
            linkData.link = link;

            updatePost({
                link: link,
                contentType: contentType,
                metadata: linkData,
                title: post.title || linkData?.title || "",
            });
        }
    };

    const getGemForm = async (
        isDraft,
        currPost = null,
        currEditorContent = null,
        pageId = null
    ) => {
        currPost = currPost ?? post;
        currEditorContent = currEditorContent ?? editorContent;

        const formData = new FormData();
        formData.set("title", currPost.title ?? null);
        formData.set("description", currPost.description ?? null);
        formData.set("id", currPost._id ?? null);
        formData.set("gemId", currPost.gemId ?? null);
        formData.set(
            "privacyLevel",
            currPost.privacyLevel ?? PrivacyLevels.PUBLIC
        );
        formData.set("content", currPost.content ?? null);
        formData.set("contentType", currPost.contentType ?? null);
        formData.set(
            "status",
            isDraft ? PostStatus.DRAFT : PostStatus.PUBLISHED
        );

        if (currPost.metadata) {
            formData.set("metadata", JSON.stringify(currPost.metadata));
        } else {
            formData.set("content", currPost.content ?? null);
        }

        // If content is not link:
        // Upload images supplied by user and get content with s3 URLs
        const contentImagesResult = await getContentImages(
            currEditorContent,
            currPost.content
        );

        if (contentImagesResult?.files?.length > 0) {
            const files = contentImagesResult["files"];
            formData.set("content", contentImagesResult["modifiedContent"]);
            files.forEach((file) => {
                formData.append("files", file);
            });
        }

        if (pageId) {
            formData.set("pageId", pageId);
        }
        return formData;
    };

    return {
        post,
        setEditorContent,
        handleContentChange,
        handleLinks,
        getGemForm,
        setIsLinkLoading,
    };
};

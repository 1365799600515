import React, { useEffect, useState } from "react";
import Select from "react-select";
import { PRIVACY_LEVELS } from "@constants";
import { useModal } from "@contexts/ModalContext";
import ProfileService from "@services/ProfileService";

const EditProfile = ({ profile, updateProfileCallback }) => {
    // No need to get username from useParams since initialProfile is passed
    const [formData, setFormData] = useState({
        username: profile.username,
        profilePicture: "",
        name: profile.name,
        bio: profile.bio,
    });
    const [profilePicturePreview, setProfilePicturePreview] = useState("");

    const PrivacyOptions = [
        {
            value: PRIVACY_LEVELS.PUBLIC,
            label: `${PRIVACY_LEVELS.PUBLIC}: anyone can view`,
        },
        {
            value: PRIVACY_LEVELS.PRIVATE,
            label: `${PRIVACY_LEVELS.PRIVATE}: only Gems users can view`,
        },
    ];

    const [privacyLevel, setPrivacyLevel] = useState(PRIVACY_LEVELS.PRIVATE);
    const { closeModal } = useModal();

    useEffect(() => {
        let curProfile = profile;
        if (profile.profile) {
            curProfile = profile.profile;
        }
        setFormData(curProfile);
        setProfilePicturePreview(curProfile.profilePicture);
        setPrivacyLevel(curProfile.privacyLevel ?? PRIVACY_LEVELS.PRIVATE);
    }, [profile]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (!file) {
            return;
        }

        const reader = new FileReader();
        reader.onloadend = () => {
            setFormData({
                ...formData,
                profilePicture: file,
            });
            setProfilePicturePreview(reader.result);
        };
        reader.readAsDataURL(file);
    };

    const handlePrivacyLevelChange = (selected) => {
        setPrivacyLevel(selected.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const requestFormData = new FormData();
        requestFormData.set("username", formData.username);
        requestFormData.set("name", formData.name);
        requestFormData.set("bio", formData.bio);
        requestFormData.set("privacyLevel", privacyLevel);

        requestFormData.append("files", formData.profilePicture);

        // Assume updateProfile is a method in ProfileService that accepts formData as parameter
        await ProfileService.updateProfile(formData.username, requestFormData)
            .then((updatedProfile) => {
                closeModal();
                updateProfileCallback(updatedProfile);
            })
            .catch((error) => {
                console.error("Error updating profile: ", error);
                // Handle error appropriately for your use case
            });
    };

    const currentPrivacyValue = PrivacyOptions.find(
        (option) => option.value === privacyLevel
    );

    return (
        <div className="p-6 max-w-md mx-auto bg-white rounded-md">
            <h2 className="text-2xl mb-4">Edit Profile</h2>
            <form onSubmit={handleSubmit}>
                <label className="block mb-4">
                    Username
                    <input
                        type="text"
                        name="username"
                        value={formData.username}
                        onChange={handleInputChange}
                        disabled
                        className="mt-1 w-full px-3 py-2 border rounded-md text-gray-600 focus:outline-none focus:border-indigo-500"
                    />
                </label>
                <label className="block mb-4">
                    Name
                    <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        className="mt-1 w-full px-3 py-2 border rounded-md focus:outline-none focus:border-indigo-500"
                    />
                </label>
                <label className="block mb-4">
                    Bio
                    <textarea
                        name="bio"
                        value={formData.bio}
                        onChange={handleInputChange}
                        className="mt-1 w-full px-3 py-2 border rounded-md focus:outline-none focus:border-indigo-500"
                    />
                </label>
                <label className="block mb-6">
                    Privacy level
                    <div className="relative mt-1 w-full border rounded-md focus:outline-none focus:border-indigo-500">
                        <Select
                            value={currentPrivacyValue}
                            onChange={handlePrivacyLevelChange}
                            options={PrivacyOptions}
                            classNamePrefix="select"
                            name="privacyLevel"
                            placeholder="Set privacy level"
                            menuPosition="fixed"
                        />
                    </div>
                </label>
                <label className="block mb-2">
                    Profile Picture
                    {profilePicturePreview && (
                        <img
                            src={profilePicturePreview}
                            alt="Profile preview"
                            className="mb-2 w-32 h-32 object-cover rounded-full"
                        />
                    )}
                </label>
                <input
                    type="file"
                    name="profilePicture"
                    onChange={handleImageChange}
                    className="mt-1"
                />
                <button
                    type="submit"
                    className="w-full p-2 bg-indigo-600 text-white rounded-full focus:outline-none focus:bg-indigo-700 mt-8"
                >
                    Save Changes
                </button>
            </form>
        </div>
    );
};

export default EditProfile;

import { Collection } from "./Post";
import { PostType } from "@constants/constants";

class Page extends Collection {
    constructor(options = {}) {
        super(options);
        this.type = PostType.PAGE;
        this.isArchive = options.isArchive;
        this.isDefault = options.isDefault;
        this.url = options.url;
    }

    static getPagePath(page) {
        if (page) {
            const encodedTitle = encodeURIComponent(
                page.title?.replace(/\s+/g, "-")
            );

            const pagePath = `${encodedTitle}-${page._id}`;
            return pagePath;
        }

        return null;
    }

    static getPageLink(username, page) {
        if (username && page) {
            const pagePath = this.getPagePath(page);
            const pageLink = `${process.env.REACT_APP_URL}/${username}/${pagePath}`;
            return pageLink;
        }

        return null;
    }

    static extractIdFromPageLink(pageParam) {
        if (pageParam) {
            const parts = pageParam.split("-");
            if (parts?.length > 0) {
                const pageId = parts[parts.length - 1];
                return pageId;
            }
        }

        return null;
    }

    static getNewPagePath(username, page, currentPath) {
        if (!username || !page || !currentPath) return;

        const newPath = `/${username}/${page.url}`;

        return newPath;
    }
}

export default Page;

import React, { useEffect, useState, useContext } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { reorder } from "@util/drag-utils";
import { UserContext } from "@contexts/UserContext";
import { usePersonalPage } from "@contexts/PersonalPageContext";
import { useToast } from "@contexts/ToastContext";
import CollectionService from "@services/CollectionService";
import { CollectionItemRow } from "./CollectionItemRow";

function DraggableCollectionContent({
    collection,
    allowNavToCollectionItem,
    showCollectionRowOptions,
    handleUpdateCollection,
}) {
    const { showToast } = useToast();

    const updateCollection = async (newCollectionItems) => {
        try {
            const updatedCollection = { ...collection };
            updatedCollection.items = newCollectionItems;
            handleUpdateCollection(updatedCollection);

            if (collection?._id) {
                await CollectionService.updateCollection(collection._id, {
                    items: newCollectionItems.map((item) => item._id),
                });
            }
        } catch (err) {
            showToast("Failed to update collection", "bg-rose-600");
            console.error("Error updating collection: ", err);
        }
    };

    const onDragEnd = async (result) => {
        const { source, destination } = result;
        if (!destination) {
            return;
        }

        const newCollectionItems = reorder(
            collection.items,
            source.index,
            destination.index
        );
        await updateCollection(newCollectionItems);
    };

    return (
        <div className="divide-y divide-gray-200">
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId={collection?._id ?? "newCollection"}>
                    {(provided) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            {collection.items?.map((collectionItem, index) => {
                                return (
                                    collectionItem && (
                                        <Draggable
                                            key={collectionItem._id}
                                            draggableId={collectionItem._id}
                                            index={index}
                                        >
                                            {(provided) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    <CollectionItemRow
                                                        key={collectionItem._id}
                                                        collectionItem={
                                                            collectionItem
                                                        }
                                                        parentCollectionItem={
                                                            collection
                                                        }
                                                        index={index}
                                                        allowNavToCollectionItem={
                                                            allowNavToCollectionItem
                                                        }
                                                        showCollectionRowOptions={
                                                            showCollectionRowOptions
                                                        }
                                                    />
                                                </div>
                                            )}
                                        </Draggable>
                                    )
                                );
                            })}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
    );
}

function NonDraggableCollectionContent({
    collection,
    allowNavToCollectionItem,
    showCollectionRowOptions,
}) {
    return (
        <>
            {collection.items?.map(
                (collectionItem, index) =>
                    collectionItem && (
                        <CollectionItemRow
                            key={
                                collectionItem._id ||
                                `${collectionItem.title}${collectionItem.content}`
                            }
                            collectionItem={collectionItem}
                            parentCollectionItem={collection}
                            index={index}
                            allowNavToCollectionItem={allowNavToCollectionItem}
                            showCollectionRowOptions={showCollectionRowOptions}
                        />
                    )
            )}
        </>
    );
}

function PopulatedCollectionContent({
    collection,
    allowNavToCollectionItem,
    showCollectionRowOptions,
    handleUpdateCollection = null,
    canDragItems = true,
}) {
    const { userId } = useContext(UserContext);
    if (userId === collection.author?._id && canDragItems) {
        return (
            <DraggableCollectionContent
                collection={collection}
                allowNavToCollectionItem={allowNavToCollectionItem}
                showCollectionRowOptions={showCollectionRowOptions}
                handleUpdateCollection={handleUpdateCollection}
            />
        );
    }

    return (
        <NonDraggableCollectionContent
            collection={collection}
            allowNavToCollectionItem={allowNavToCollectionItem}
            showCollectionRowOptions={showCollectionRowOptions}
        />
    );
}

function CollectionContent({
    collection,
    allowNavToCollectionItem = true,
    showCollectionRowOptions = true,
    handleEditPost = null,
}) {
    const { userId } = useContext(UserContext);
    const [populatedCollection, setPopulatedCollection] = useState(collection);
    const [loading, setLoading] = useState(true);
    const { posts, getPost, handleUpdatePost } = usePersonalPage();

    useEffect(() => {
        const fetchCollection = async () => {
            try {
                const fetchedCollection = await CollectionService.getCollection(
                    collection._id,
                    collection
                );
                setPopulatedCollection(fetchedCollection);

                if (userId === collection.author._id) {
                    handleUpdatePost(fetchedCollection);
                }
            } catch (error) {
                console.error("Error fetching collection:", error);
            } finally {
                setLoading(false);
            }
        };

        if (
            !collection._id ||
            collection?.items?.length ||
            CollectionService.hasAlreadyPopulated(collection)
        ) {
            let populatedCollection = collection;
            if (userId === collection.author._id) {
                populatedCollection = {
                    ...collection,
                    items: collection.items.map(
                        (item) => getPost(item?._id) || null
                    ),
                };
            }
            setPopulatedCollection(populatedCollection);
            setLoading(false);
        } else {
            fetchCollection();
        }
    }, [collection, posts]);

    const handleUpdateCollection = (updatedCollection) => {
        setPopulatedCollection(updatedCollection);
        handleUpdatePost(updatedCollection);
        handleEditPost && handleEditPost(updatedCollection);
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <PopulatedCollectionContent
            collection={populatedCollection}
            allowNavToCollectionItem={allowNavToCollectionItem}
            showCollectionRowOptions={showCollectionRowOptions}
            handleUpdateCollection={handleUpdateCollection}
        />
    );
}

export {
    PopulatedCollectionContent,
    NonDraggableCollectionContent,
    CollectionContent,
};

import React from "react";
import { Link, useNavigate } from "react-router-dom";
import RegistrationForm from "./RegistrationForm";
import ResendVerificationButton from "./ResendVerificationButton";
import { FormStatus, FormStatusFail } from "@components/Form";
import useHandleForm from "../hooks/useHandleForm";
import AuthService from "@services/AuthService";
import { Logo } from "@components/Logo";
import BackButton from "@components/BackButton";

function isValidUsername(username) {
    // Regular expression to match only alphanumeric characters and underscores
    const regex = /^[a-zA-Z0-9_]+$/;

    // Test if the username matches the regular expression
    return regex.test(username);
}

function RegisterPanel() {
    const {
        status,
        setStatus,
        failureStatus,
        setFailureStatus,
        userEmail,
        setUserEmail,
        showResendButton,
        setShowResendButton,
        handleResendVerification,
    } = useHandleForm(null);

    const navigate = useNavigate();

    const handleRegistration = async ({
        username,
        password,
        name,
        email,
        token,
    }) => {
        if (!isValidUsername(username)) {
            setFailureStatus("username cannot contain special characters");
            return;
        }
        const result = await AuthService.register({
            username,
            password,
            name,
            email,
            token,
        });

        if (result.success) {
            setUserEmail(email); // set the email from the registration
            setShowResendButton(true); // display the resend button
            setStatus(
                "Thanks for signing up to Gems! Please verify your email. It may take up to 10 minutes to receive the email in your inbox."
            );
            setTimeout(() => {
                navigate("/login");
            }, 5000);
        } else {
            setStatus(result.message);
        }
    };

    return (
        <div className="flex flex-col items-center bg-white p-8 rounded-md border-2 border-gray-400">
            <div className="w-full">
                <BackButton className="left-0" />
            </div>
            <div className="text-5xl mb-6 mt-2">
                <Logo />
            </div>
            <RegistrationForm onSubmit={handleRegistration} />
            {status && <FormStatus status={status} />}
            {!status && failureStatus && (
                <FormStatusFail status={failureStatus} />
            )}
            {showResendButton && (
                <ResendVerificationButton
                    email={userEmail}
                    onResend={handleResendVerification}
                />
            )}
            <div className="mt-4">
                Already have an account?{" "}
                <Link
                    to="/login"
                    className="mt-2 text-blue-600 hover:text-blue-800"
                >
                    Login
                </Link>
            </div>
        </div>
    );
}

export default RegisterPanel;

import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { useModal } from "@contexts/ModalContext";
import { AuthorProfileDisplay } from "@features/profile";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { UserContext } from "@contexts/UserContext";

function FollowListColumn({ listName, list }) {
    return (
        <div className="flex p-4 w-[20vw] max-w-[30vw] max-h-[50vh] overflow-y-auto">
            <div className="flex-1 mr-2">
                <div className="mb-4 text-md font-bold">{listName}</div>
                {list.length > 0 ? (
                    list.map((user, index) => (
                        <div key={index} className="text-sm m-4">
                            <AuthorProfileDisplay profile={user} />
                        </div>
                    ))
                ) : (
                    <div className="text-sm text-gray-500"> No {listName}</div>
                )}
            </div>
        </div>
    );
}

function FollowModal({ followersList, followingList }) {
    const { closeOverlayModal } = useModal();
    return (
        <div>
            <button
                className="absolute top-0 right-0 m-2 text-lg font-bold"
                onClick={closeOverlayModal}
            >
                <XMarkIcon className={"h-5 w-5"} />
            </button>
            <div className="flex space-x-4 justify-center px-4">
                <FollowListColumn listName="Followers" list={followersList} />
                <FollowListColumn listName="Following" list={followingList} />
            </div>
        </div>
    );
}

function FollowPanel({ followersList, followingList }) {
    const { openOverlayModal } = useModal();
    const { userInfo } = useContext(UserContext);

    const openFollowList = () => {
        openOverlayModal(FollowModal, {
            followersList: followersList,
            followingList: followingList,
        });
    };

    if (followersList === null || followingList === null) {
        return null; // or display a loading indicator
    }

    const followTextBody = (
        <div className="text-center flex text-sm">
            <div className="mr-4 mb-4">
                <strong>{followersList.length}</strong> Followers
            </div>
            <div>
                <strong>{followingList.length}</strong> Following
            </div>
        </div>
    );

    return userInfo !== null ? (
        <Link onClick={openFollowList}>{followTextBody}</Link>
    ) : (
        followTextBody
    );
}

export default FollowPanel;

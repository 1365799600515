import { useState, useEffect, useContext } from "react";
import LikeService from "@services/LikeService";
import { UserContext } from "@contexts/UserContext";
import { useGlobalPosts } from "@contexts/GlobalPostContext";
import { Post } from "@classes/Post";

function useLike(post) {
    const [likes, setLikes] = useState([]);
    const [isLiked, setIsLiked] = useState(false);
    const { userId, userInfo } = useContext(UserContext);
    const { updatePost } = useGlobalPosts();

    const handleAddLikeToPost = () => {
        const updatedPost = Post.copyFrom(post);
        if (!updatedPost.likeCount) {
            updatedPost.likeCount = 1;
        } else {
            updatedPost.likeCount += 1;
        }

        updatePost(updatedPost);
    };

    const handleRemoveLikeFromPost = () => {
        const updatedPost = Post.copyFrom(post);
        if (updatedPost.likeCount > 0) {
            updatedPost.likeCount -= 1;
        }

        updatePost(updatedPost);
    };

    const handleAddLike = (like) => {
        const likeWIthUser = {
            ...like,
            user: {
                _id: userId,
                username: userInfo.username,
                ...userInfo.profile,
            },
        };
        setLikes((prevLikes) => [likeWIthUser, ...prevLikes]);

        handleAddLikeToPost();
    };

    const handleRemoveLike = () => {
        setLikes((prevLikes) =>
            prevLikes.filter((like) => like.user?._id !== userId)
        );

        handleRemoveLikeFromPost();
    };

    useEffect(() => {
        const fetchLikes = async () => {
            try {
                const allLikes = await LikeService.getLikes(post._id);
                setLikes(allLikes);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        if (post._id) {
            fetchLikes();
        }
    }, [post]);

    useEffect(() => {
        const checkIfLiked = async () => {
            try {
                const userHasLiked = likes.some(
                    (like) => like.user?._id === userId
                );
                setIsLiked(userHasLiked);
            } catch (error) {
                console.error("Error checking likes:", error);
            }
        };
        checkIfLiked();
    }, [likes, userId]);

    const handleLikeToggle = async () => {
        try {
            if (isLiked) {
                await LikeService.unlikePost(post._id);
                handleRemoveLike(userId);
            } else {
                const like = await LikeService.likePost(post._id);
                handleAddLike(like);
            }
            setIsLiked((prevIsLiked) => !prevIsLiked);
        } catch (error) {
            console.error("Error updating like:", error);
        }
    };

    return { likes, isLiked, handleLikeToggle };
}

export default useLike;

export const useAutoCreateForm = (postControl) => {
    const { post, editorContent, setEditorContent, updatePost } = postControl;

    const handleContentChange = (selected) => {
        updatePost({ content: selected });
    };

    return {
        post,
        editorContent,
        setEditorContent,
        handleContentChange,
    };
};

import React, { useContext, useEffect } from "react";
import { UserContext } from "@contexts/UserContext";
import { useModal } from "@contexts/ModalContext";
import { AuthorProfileDisplay } from "@features/profile";
import { PrivacyIcon } from "@components/PrivacyIcon";
import { GemContent } from "@features/gem";
import { CollectionContent } from "@features/collection";
import { PostContent } from "@features/post";
import { FullContentRenderer } from "@features/linkToGem";
import Button from "@components/Button";
import { PostType, PostEditState } from "@constants";
import { PostFrame, PostDescription } from "@features/post";
import LoadingComponent from "@components/LoadingComponent";

function PostPreviewHeader({ postControl }) {
    const { userInfo } = useContext(UserContext);
    const { post } = postControl;

    return (
        <div className="flex justify-between items-center text-sm">
            <AuthorProfileDisplay profile={post.author} />
            <PrivacyIcon privacyLevel={post.privacyLevel} />
        </div>
    );
}
function PostPreviewContent({ postControl, postEditState }) {
    const { post, updatePost, isGem, isCollection, isAutoCreate } = postControl;

    let postContent;
    if (post.type === PostType.GEM) {
        postContent = (
            <GemContent gem={post} contentRenderer={FullContentRenderer} />
        );
    } else if (post.type === PostType.COLLECTION) {
        postContent = (
            <CollectionContent
                collection={post}
                allowNavToCollectionItem={true}
                showCollectionRowOptions={false}
                handleEditPost={updatePost}
            />
        );
    } else if (isAutoCreate) {
        // Fallback to show the text content.
        postContent = (
            <GemContent gem={post} contentRenderer={FullContentRenderer} />
        );
    }
    return <PostContent post={post}>{postContent}</PostContent>;
}

const getSubmitMessage = (prevState, isCreatedFromCollection) => {
    let submitMessage;
    if (isCreatedFromCollection) {
        submitMessage = "Add to collection";
        return submitMessage;
    }

    switch (prevState) {
        case PostEditState.Create:
            submitMessage = "Create";
            break;
        case PostEditState.Edit:
            submitMessage = "Publish";
            break;
        case PostEditState.AutoCreate:
            submitMessage = "Auto-format (saves as draft)";
            break;
    }

    return submitMessage;
};

function PostPreviewNavigationButtons({ postControl, postEditState }) {
    const { handlePreviousState, getPreviousState } = postEditState;
    const { post, submitPost, isCreatedFromCollection } = postControl;

    const handlePost = () => submitPost(false);
    const handleSaveAsDraft = () => submitPost(true);

    // Disable submit if there is no content (content, link, or items in collection)
    const disableSubmit =
        !post.title && !post.content && !post.metadata && !post.items?.length;

    const prevState = getPreviousState();
    const submitMessage = getSubmitMessage(prevState, isCreatedFromCollection);

    return (
        <div className="flex justify-center space-x-2">
            <Button
                onClick={handlePreviousState}
                colors="bg-gray-300 hover:bg-gray-400"
            >
                <div className="text-black font-bold">Back</div>
            </Button>
            <Button
                onClick={handlePost}
                disabled={disableSubmit}
                colors={
                    disableSubmit
                        ? "bg-indigo-700 bg-opacity-40"
                        : "bg-indigo-300 hover:bg-indigo-400"
                }
            >
                <div
                    className={
                        disableSubmit
                            ? "text-gray-700 font-bold"
                            : "text-black font-bold"
                    }
                >
                    {submitMessage}
                </div>
            </Button>
            {prevState !== PostEditState.AutoCreate &&
                !isCreatedFromCollection && (
                    <Button
                        onClick={handleSaveAsDraft}
                        disabled={disableSubmit}
                        colors={
                            disableSubmit
                                ? "bg-yellow-500 bg-opacity-50"
                                : "bg-yellow-400 hover:bg-yellow-500 bg-opacity-60 hover:bg-opacity-60"
                        }
                    >
                        <div
                            className={
                                disableSubmit
                                    ? "text-gray-700 font-bold"
                                    : "text-black font-bold"
                            }
                        >
                            Save as Draft
                        </div>
                    </Button>
                )}
        </div>
    );
}

function PostPreviewPanel({ postControl }) {
    const { post } = postControl;
    return (
        <div className="m-2">
            <PostDescription post={post} />
        </div>
    );
}

function PreviewPost({ postControl, postEditState }) {
    const { post } = postControl;
    const { updateCurrentModalState } = useModal();

    useEffect(() => {
        if (!post.isLoading) {
            updateCurrentModalState(
                {
                    postControl,
                    postEditState,
                },
                post.title
            );
        }
    }, [post.isLoading]);

    if (post.isLoading) {
        return <PostFrame leftContent={<LoadingComponent />} />;
    }

    return (
        <PostFrame
            leftContent={
                <>
                    <PostPreviewHeader postControl={postControl} />
                    <PostPreviewContent
                        postControl={postControl}
                        postEditState={postEditState}
                    />
                    <PostPreviewNavigationButtons
                        postControl={postControl}
                        postEditState={postEditState}
                    />
                </>
            }
            rightContent={<PostPreviewPanel postControl={postControl} />}
        />
    );
}

export default PreviewPost;

export const PATHS = {
    WELCOME: "/welcome",
    ABOUT: "/about",
    HELP: "/help",
    LOGIN: "/login",
    REGISTER: "/register",
    VERIFY_EMAIL: "/verify-email/:token",
    FORGOT_PASSWORD: "/forgot-password",
    RESET_PASSWORD: "/reset-password",
    USER: "/:username",
    USER_PAGE: "/:username/:page",
    USER_PROFILE: "/:username/profile",
    USER_BASE: "/users/",
    SHARED_GEM: "/gem/:id",
    SHARED_COLLECTION: "/collection/:id",
    INDEX: "/",
    EXPLORE: "/explore",
    ONBOARD: "/onboard",
    // COLLECTION: "/collection/:id",
    // GEM: "/gem/:id",
    SEARCH: "/search",
    NOTIFICATION: "/notifications",
    FOLLOWING: "/following",
    POST: "/post/:id",

    getUserPath: function (username) {
        return PATHS.USER.replace(":username", username);
    },

    getUserProfilePath: function (username) {
        return PATHS.USER_PROFILE.replace(":username", username);
    },
};

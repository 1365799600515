import React, { useState, useEffect, useCallback } from "react";
import debounce from "lodash.debounce";
import SearchService from "@services/SearchService";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { useNavigate, useLocation } from "react-router-dom";

function SearchBar({ onSearch, text, searchText }) {
    const [input, setInput] = useState("");

    useEffect(() => {
        setInput(searchText);
    }, [searchText]);

    const handleInputChange = (e) => {
        setInput(e.target.value);
        onSearch(e.target.value);
    };

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            setInput(e.target.value);
            onSearch(e.target.value);
        }
    };

    return (
        <div className="flex items-center space-x-2 border-2 border-gray-400 rounded-full py-1 px-4 text-md hover:border-purple-700 hover:border-2">
            <MagnifyingGlassIcon className="w-5 h-5 text-gray-600" />
            <input
                type="text"
                value={input}
                onChange={handleInputChange}
                onKeyPress={handleKeyPress}
                placeholder={text}
                className="bg-transparent outline-none ml-2 flex-1 border-none placeholder-gray-600"
            />
        </div>
    );
}

function Search({
    scope,
    handleSearchResults,
    barText = "Search for gems, collections, or users...",
    useURLParams = true,
}) {
    const [searchText, setSearchText] = useState("");
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const queryText = params.get("q");
        if (queryText) {
            setSearchText(queryText);
        }
    }, []);

    const debouncedSearch = useCallback(
        debounce(async (text, scope) => {
            try {
                if (text.trim()) {
                    const searchResults = await SearchService.search(
                        text,
                        scope
                    );
                    handleSearchResults(searchResults);
                    if (useURLParams) navigate(`/search?q=${text}`);
                }
            } catch (error) {
                console.error("Error searching posts:", error);
            }
        }, 400),
        [handleSearchResults, scope]
    );

    useEffect(() => {
        if (searchText.trim()) {
            debouncedSearch(searchText, scope);
        } else {
            handleSearchResults(null);
        }
    }, [searchText, scope, debouncedSearch]);

    return (
        <div className="rounded-full">
            <SearchBar
                onSearch={setSearchText}
                text={barText}
                searchText={searchText}
            />
        </div>
    );
}

export default Search;

import React, { useState } from "react";
import { useModal } from "@contexts/ModalContext";
import { useToast } from "@contexts/ToastContext";

function DeleteConfirmationModal({
    onDelete,
    deleteMessage,
    closeModals = true,
}) {
    const { closeOverlayModal, closeModal } = useModal();
    const [uploading, setUploading] = useState(false);
    const { showToast } = useToast();

    const onDeleteItem = async () => {
        setUploading(true);

        try {
            onDelete();

            setUploading(false);
            showToast("Deleted", "bg-rose-700");
        } catch (error) {
            console.error("Failed to delete:", error);
        }

        if (closeModals) {
            closeOverlayModal();
            closeModal();
        } else {
            closeOverlayModal();
        }
    };

    return (
        <div className="mx-auto min-w-40 py-4 px-8 rounded flex flex-col items-center justify-center">
            <div className="font-title text-bold mb-4">{deleteMessage}</div>
            <button
                className="bg-rose-600 hover:bg-rose-800 text-white px-4 py-2 mb-2 rounded-full text-sm font-semibold"
                disabled={uploading}
                onClick={onDeleteItem}
            >
                Yes, delete
            </button>
            <button
                className="bg-gray-300 hover:bg-gray-400 hover:bg-opacity-80 px-4 py-2 mb-2 rounded-full text-sm font-semibold"
                onClick={closeOverlayModal}
            >
                Cancel
            </button>
        </div>
    );
}

export default DeleteConfirmationModal;

function PostFrame({ leftContent, rightContent }) {
    return (
        <div className="post flex flex-col md:flex-row bg-white flex-grow w-full">
            <div className="left-content w-full md:w-2/3 relative bg-gray-100 overflow-y-auto p-4">
                {leftContent && leftContent}
            </div>
            <div className="post-panel w-full md:w-1/3 relative multicolor-gradient overflow-y-auto p-4">
                {/*Setting bg-opacity-50 here doesn't work. Need to do it directly in the gradient defined in CSS */}
                {rightContent && rightContent}
            </div>
        </div>
    );
}

export default PostFrame;

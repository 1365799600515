import React from "react";
import { Link } from "react-router-dom";
import { FormStatus, FormStatusFail } from "@components/Form";
import useHandleForm from "../hooks/useHandleForm";
import LoginForm from "./LoginForm";
import ResendVerificationButton from "./ResendVerificationButton";
import { Logo } from "@components/Logo";
import useLogin from "@hooks/useLogin";
import BackButton from "@components/BackButton";

function LoginPanel() {
    const {
        status,
        setStatus,
        failureStatus,
        setFailureStatus,
        userEmail,
        setUserEmail,
        showResendButton,
        setShowResendButton,
        handleResendVerification,
    } = useHandleForm(null);

    const { login, isLoading } = useLogin();

    async function handleLogin({ username, password }) {
        try {
            setStatus(null);

            const response = await login(username, password);

            if (!response.success && response.status === 403) {
                setFailureStatus("Email not verified");
                setUserEmail(response.email);
                setShowResendButton(true);
                return;
            }
            if (!response.success) {
                throw new Error();
            }
        } catch (error) {
            setFailureStatus("Wrong credentials");
        }
    }

    return (
        <div className="flex flex-col space-y-1">
            <div className="flex flex-col items-center bg-white p-4 rounded-md border-2 border-gray-400">
                <div className="w-full">
                    <BackButton className="left-0" />
                </div>
                <div className="text-5xl mb-6 mt-2">
                    <Logo />
                </div>
                <LoginForm onLogin={handleLogin} isLoading={isLoading} />
                {status && <FormStatus status={status} />}
                {!status && failureStatus && (
                    <FormStatusFail status={failureStatus} />
                )}
                {showResendButton && (
                    <ResendVerificationButton
                        email={userEmail}
                        onResend={handleResendVerification}
                    />
                )}
                <Link
                    to="/forgot-password"
                    className="mt-2 text-blue-600 hover:text-blue-800"
                >
                    Forgot Password?
                </Link>
            </div>
            <div className="flex flex-col items-center bg-white p-4 rounded-md border-2 border-gray-400">
                <p>Don't have an account?</p>
                <Link
                    to="/register"
                    className="mt-2 text-blue-600 hover:text-blue-800"
                >
                    Sign up
                </Link>
            </div>
        </div>
    );
}

export default LoginPanel;

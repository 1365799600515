import React from "react";
import Loader from "./Loader";

function LoadingComponent({ size = "h-12 w-12" }) {
    return (
        <div className="flex justify-center items-center my-20">
            <Loader size={size} />
        </div>
    );
}

export default LoadingComponent;

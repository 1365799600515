import React from "react";
import { Link } from "react-router-dom";

function LinkIcon({ linkInfo, size = "h-4 w-4" }) {
    return linkInfo.logo ? (
        <img
            src={linkInfo.logo}
            alt={linkInfo.url}
            className={`${size} object-cover opacity-80`}
        />
    ) : (
        <div></div>
    );
}

function LinkDisplay({ linkInfo, isPreview = false }) {
    let linkTitle = linkInfo.title;

    return (
        <div className="flex space-x-2">
            {linkInfo.logo && (
                <div className={`${linkInfo.publisher ? "pt-1" : ""}`}>
                    <LinkIcon linkInfo={linkInfo} size={"h-6 w-6"} />
                </div>
            )}
            <div>
                {linkTitle && (
                    <div
                        className={`text-md font-semibold ${
                            isPreview && "text-oversize-1"
                        }`}
                    >
                        {linkTitle}
                    </div>
                )}
                {linkInfo.publisher && (
                    <div
                        className={`text-md ${isPreview && "text-oversize-1"}`}
                    >
                        <div>{linkInfo.publisher}</div>
                    </div>
                )}
            </div>
        </div>
    );
}

function LinkWrapper({ linkInfo, children }) {
    const link = linkInfo?.url || linkInfo?.link;
    if (!link) return null;
    return <Link to={link}>{children}</Link>;
}

function LinkDisplayWithLink({ linkInfo, gem = null }) {
    return (
        <LinkWrapper linkInfo={linkInfo}>
            <div className="p-2">
                <LinkDisplay linkInfo={linkInfo} gem={gem} />
            </div>
        </LinkWrapper>
    );
}

export { LinkIcon, LinkDisplay, LinkDisplayWithLink, LinkWrapper };

import React from "react";
import ImageExtractor from "./ImageExtractor";
import CollectionRowOptions from "./CollectionRowOptions";
import { useModal } from "@contexts/ModalContext";
import { CollectionPost } from "@features/collection";
import { GemPost } from "@features/gem";
import { PostType, ModalType } from "@constants/constants";
import { LinkIcon } from "@components/Link";
import { TitleRenderer } from "@features/linkToGem";
import GemService from "@services/GemService";

function ItemDetails({ item }) {
    return (
        <div className="ml-4 flex-1">
            <ItemTitle item={item} />
            <div className="text-gray-500 text-sm">
                <ItemDescription item={item} />
            </div>
        </div>
    );
}

function ItemTitle({ item }) {
    const title = (
        <div className="font-bold text-oversize-1">{TitleRenderer(item)}</div>
    );

    if (item.linkInfo) {
        return (
            <div className="flex space-x-2 items-center">
                <LinkIcon linkInfo={item.linkInfo} />
                {title}
            </div>
        );
    }

    return title;
}

function ItemDescription({ item }) {
    if (item.description) {
        return (
            <div
                className="text-oversize-1"
                dangerouslySetInnerHTML={{ __html: item.description }}
            />
        );
    }

    if (item.metadata) {
        return (
            <div className="flex items-center space-x-2">
                <div className="text-oversize-1">
                    {item.metadata.description}
                </div>
            </div>
        );
    }

    return <></>;
}

function ItemModalComponent({ item, allowModify = true }) {
    if (item.type === PostType.GEM) {
        return <GemPost gem={item} allowModify={allowModify} />;
    }
    if (item.type === PostType.COLLECTION) {
        return (
            <CollectionPost
                collection={item}
                allowNavToCollectionItem={true}
                showCollectionRowOptions={true}
                allowModify={allowModify}
            />
        );
    }
    return null;
}

function CollectionRowContent({
    index,
    item,
    allowNavToCollectionItem,
    showCollectionRowOptions,
    parentCollectionItem,
}) {
    return (
        <>
            <span className="text-sm text-center w-6 mr-3 text-gray-500">
                {index + 1}
            </span>
            <ImageExtractor
                item={item}
                showFullCollectionPost={allowNavToCollectionItem}
            />
            <ItemDetails item={item} />
            {showCollectionRowOptions && (
                <CollectionRowOptions
                    currentItem={item}
                    parentCollection={parentCollectionItem}
                />
            )}
        </>
    );
}

function CollectionItemRow({
    collectionItem,
    parentCollectionItem,
    index,
    allowNavToCollectionItem,
    showCollectionRowOptions,
}) {
    const { pushContentToModal } = useModal();

    const handleRowClick = async () => {
        let post = collectionItem;
        if (post.type === "Gem" && !post.fullQuality) {
            post = await GemService.getGem(post._id);
        }

        pushContentToModal(
            ItemModalComponent,
            post.title,
            {},
            { item: post, index, parentCollectionItem },
            ModalType.COLLECTIONITEM
        );
    };

    return (
        <div
            className={`flex items-center justify-between py-4 ${
                allowNavToCollectionItem
                    ? "p-4 hover:bg-gray-200 cursor-pointer"
                    : ""
            }`}
            onClick={allowNavToCollectionItem ? handleRowClick : undefined}
        >
            <CollectionRowContent
                index={index}
                item={collectionItem}
                allowNavToCollectionItem={allowNavToCollectionItem}
                showCollectionRowOptions={showCollectionRowOptions}
                parentCollectionItem={parentCollectionItem}
            />
        </div>
    );
}

export { CollectionItemRow, ItemModalComponent };

import React from "react";

import { LandingPageBase } from "@features/landing";
import ForgotPasswordPanel from "@features/authentication/components/ForgotPasswordPanel";

function ForgotPasswordPage() {
    return (
        <LandingPageBase>
            <ForgotPasswordPanel />
        </LandingPageBase>
    );
}

export default ForgotPasswordPage;

class Profile {
    constructor(
        username,
        profilePicture,
        name,
        bio,
        privacyLevel,
        verificationToken
    ) {
        this.username = username;
        this.profilePicture = profilePicture;
        this.name = name;
        this.bio = bio;
        this.privacyLevel = privacyLevel;
        this.verificationToken = verificationToken;
    }

    getName() {
        return this.name === true ? this.name : this.username;
    }
}

export default Profile;

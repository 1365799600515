import React from "react";
import { Link } from "react-router-dom";

function WelcomeButton({ toLink, children }) {
    return (
        <Link to={toLink}>
            <div className="rounded-full blue-purple-hover bg-gray-300 text-black hover:text-white font-bold shadow-md px-6 py-3">
                <div className="relative z-10 transition-none">{children}</div>
            </div>
        </Link>
    );
}

export default WelcomeButton;

import React, { createContext, useState, useContext } from "react";
import Modal from "@components/modal/Modal";

const ModalContext = createContext();

export const useModal = () => {
    return useContext(ModalContext);
};

export const ModalProvider = ({ children }) => {
    const [modalStack, setModalStack] = useState([]);
    const [overlayModal, setOverlayModal] = useState(null); // Use this small modal for confirming actions.

    // useEffect(() => {
    //     // console.log("Updated modalStack:", modalStack);
    //     // console.log("overlayModal: " + overlayModal);
    // }, [modalStack]);

    function openModal(content, title, initialState, props, type) {
        if (modalStack.length > 0) {
            pushContentToModal(content, title, initialState, props, type);
        } else {
            setModalStack([
                { content, title, state: initialState, props, type },
            ]);
        }
    }

    function pushContentToModal(content, title, initialState, props, type) {
        setModalStack((prevStack) => [
            ...prevStack,
            { content, title, state: initialState, props, type },
        ]);
    }

    function replaceContentInModal(content, title, initialState, props, type) {
        setModalStack((prevStack) => {
            if (prevStack.length === 0) {
                return [{ content, title, state: initialState, props, type }];
            }

            const stackWithoutLastItem = prevStack.slice(0, -1);
            return [
                ...stackWithoutLastItem,
                { content, title, state: initialState, props, type },
            ];
        });
    }

    function updateCurrentModalState(newState, newTitle = null) {
        setModalStack((prevStack) => {
            const currentModal = prevStack[prevStack.length - 1];
            const updatedModal = {
                ...currentModal,
                state: newState,
                title: newTitle ?? currentModal.title,
            };
            return [...prevStack.slice(0, -1), updatedModal];
        });
    }

    function closeModal() {
        setModalStack((prevStack) => prevStack.slice(0, -1));
    }

    // Use this when a post is edited, and we no longer want to keep the original post. Since it is still on  the modal stack,
    // without this the modal will include the back link to the original post.
    function closePreviousModal() {
        setModalStack((prevStack) => {
            if (prevStack.length < 1) return prevStack; // if there's only one item or none, just return the original stack
            const newStack = [...prevStack];
            newStack.splice(-2, 1); // remove the second to last item
            return newStack;
        });
    }

    // Overlay modal is a second modal that opens on top of the content modal. It is used for Yes/No confirmation actions.
    function openOverlayModal(content, props) {
        setOverlayModal({ content, props });
    }

    const closeOverlayModal = () => {
        setOverlayModal(null);
    };

    const closeAllModals = () => {
        setModalStack([]);
        closeOverlayModal();
    };

    return (
        <ModalContext.Provider
            value={{
                modalStack,
                openModal,
                pushContentToModal,
                replaceContentInModal,
                updateCurrentModalState,
                closeModal,
                closePreviousModal,
                closeAllModals,
                openOverlayModal,
                closeOverlayModal,
                overlayModal,
            }}
        >
            <Modal />
            {children}
        </ModalContext.Provider>
    );
};

import React from "react";
import CommentInput from "./CommentInput";
import SendCommentButton from "./SendCommentButton";

function CommentPanel({ comment, setComment, handleSendComment }) {
    return (
        <div className="comment-panel flex flex-col items-start w-full">
            <div className="input-panel flex items-center w-full space-x-4">
                <CommentInput
                    comment={comment}
                    setComment={setComment}
                    handleSendComment={handleSendComment}
                />
                <SendCommentButton handleSendComment={handleSendComment} />
            </div>
        </div>
    );
}

export default CommentPanel;

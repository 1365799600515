import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PersonalPageContent from "./PersonalPageContent";
import LoadingComponent from "@components/LoadingComponent";
import { PageDisplay } from "@features/page";
import PersonalPageSidePanel from "./PersonalPageSidePanel";
import PersonalPageProfileTile from "./PersonalPageProfileTile";
import { usePersonalPagePosts } from "@contexts/PersonalPagePostContext";

function PersonalPageDisplay({
    userInfo,
    setUserInfo,
    isSidePanelOpen,
    isTutorial,
}) {
    const [posts, setPosts] = useState([]);
    const { page: pageParam } = useParams();
    const [isTransitioning, setIsTransitioning] = useState(false);

    const {
        setPageParam,
        currentPage,
        currentPosts,
        handleSelectPage,
        loading: pageLoading,
    } = usePersonalPagePosts();

    useEffect(() => {
        setPosts(currentPosts);
    }, [currentPosts]);

    useEffect(() => {
        setPageParam(pageParam);
    }, [pageParam]);

    useEffect(() => {
        setIsTransitioning(true);
        const timeout = setTimeout(() => setIsTransitioning(false), 200);
        return () => clearTimeout(timeout);
    }, [currentPage]);

    return (
        <PageDisplay
            SidePanel={() => (
                <div className="personal-page-side-panel">
                    <PersonalPageSidePanel
                        currentPageId={currentPage?._id}
                        handleSelectPage={handleSelectPage}
                        isOpen={isSidePanelOpen}
                    />
                </div>
            )}
            isSidePanelOpen={isSidePanelOpen}
            isLoading={pageLoading}
        >
            <div className="mt-8">
                <PersonalPageProfileTile
                    userInfo={userInfo}
                    setUserInfo={setUserInfo}
                />
                {pageLoading ? (
                    <LoadingComponent />
                ) : (
                    <div
                        className={`${
                            isTransitioning
                                ? "grid-transition-out"
                                : "grid-transition"
                        }`}
                    >
                        {!isTransitioning && (
                            <PersonalPageContent
                                currentPosts={posts}
                                currentPage={currentPage}
                                isTutorial={isTutorial}
                            />
                        )}
                    </div>
                )}
            </div>
        </PageDisplay>
    );
}

export default PersonalPageDisplay;

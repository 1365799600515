// ForgotPasswordPage.js
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "@contexts/UserContext";
import ForgotPasswordForm from "./ForgotPasswordForm";
import { FormStatus, FormStatusFail } from "@components/Form";
import LoadingComponent from "@components/LoadingComponent";
import AuthService from "@services/AuthService";

function ForgotPasswordPanel() {
    const { isLoading, setIsLoading } = useContext(UserContext);
    const [status, setStatus] = useState("");
    const [failureStatus, setFailureStatus] = useState("");

    async function handleForgotPassword({ email }) {
        try {
            setIsLoading(true);

            const response = await AuthService.forgotPassword(email);
            setStatus(
                "If an account was found, a reset link has been sent. It might take a few minutes for you to receive the email."
            );
            return response;
        } catch (error) {
            console.error("Error during login:", error);
            setFailureStatus(
                "Failed to generate a reset password link. Please try again later."
            );
            // handle any other cleanup or error state setting here
        } finally {
            setIsLoading(false);
        }
    }

    if (isLoading) {
        return <LoadingComponent />;
    }

    return (
        <div className="flex flex-col items-center bg-white p-4 rounded-md border-2 border-gray-400">
            <h1 className="font-title text-2xl mb-6 mt-2">
                Forgot Your Password?
            </h1>
            <div className="max-w-md md:w-1/2 text-center mb-6">
                Enter your email and we'll send you a link to get back into your
                account.
            </div>
            <ForgotPasswordForm onSubmit={handleForgotPassword} />
            {status && <FormStatus status={status} />}
            {!status && failureStatus && (
                <FormStatusFail status={failureStatus} />
            )}
            <Link
                to="/login"
                className="mt-8 text-blue-600 hover:text-blue-800"
            >
                Back to login
            </Link>
        </div>
    );
}

export default ForgotPasswordPanel;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Page from "@classes/Page";
import PageService from "@services/PageService";

function useUserPagePosts(username, pageParam) {
    const [pages, setPages] = useState(null);

    const [currentPage, setCurrentPage] = useState(null);
    const [pagePosts, setPagePosts] = useState([]);

    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        setPagePosts(currentPage?.items);
    }, [currentPage]);

    useEffect(() => {
        const fetchUserPage = async () => {
            try {
                const userPages = await PageService.getUserPages(
                    null,
                    username
                );

                setPages(userPages);
            } catch (error) {
                console.error("Error fetching user posts: " + error);
            } finally {
                setLoading(false);
            }
        };

        if (!pages) {
            fetchUserPage();
        } else {
            setLoading(false);
        }
    }, [username]);

    useEffect(() => {
        setCurrentPageFromUrl(pages);
    }, [pages]);

    const setCurrentPageFromUrl = (userPages) => {
        if (pages) {
            const page = pages.find((page) => page?.url === pageParam);
            if (page) {
                setCurrentPage(page);
                return;
            }
        }

        if (userPages?.length > 0) {
            setCurrentPage(userPages[0]);
        }
    };

    const handleSelectPage = (page) => {
        setCurrentPage(page);

        const currentPath = window.location.pathname;
        const newPath = Page.getNewPagePath(username, page, currentPath);

        if (newPath !== currentPath) {
            navigate(newPath, { replace: true });
        }
    };

    return { pages, currentPage, pagePosts, handleSelectPage, loading };
}

export default useUserPagePosts;

import React from "react";
import {
    LandingWaitlistPage,
    AboutPage,
    LoginPage,
    RegisterPage,
    VerifyEmailPage,
    ForgotPasswordPage,
    ResetPasswordPage,
    GetUserPage,
    IndexPage,
    SearchPage,
    PersonalPage,
    ProfilePage,
    TutorialPage,
    ExplorePage,
    FollowingPage,
    NotificationPage,
    PostPage,
} from "@pages";
import { PATHS } from "./Paths";

export const PUBLIC_ROUTES = [
    { path: PATHS.WELCOME, element: <LandingWaitlistPage /> },
    { path: PATHS.ABOUT, element: <AboutPage /> },
    { path: PATHS.HELP, element: <AboutPage /> },
    { path: PATHS.LOGIN, element: <LoginPage /> },
    { path: PATHS.REGISTER, element: <RegisterPage /> },
    { path: PATHS.VERIFY_EMAIL, element: <VerifyEmailPage /> },
    { path: PATHS.FORGOT_PASSWORD, element: <ForgotPasswordPage /> },
    { path: PATHS.RESET_PASSWORD, element: <ResetPasswordPage /> },
    { path: PATHS.USER, element: <GetUserPage /> },
    { path: PATHS.USER_PAGE, element: <GetUserPage /> },
    { path: PATHS.SHARED_GEM, element: <PostPage /> },
    {
        path: PATHS.SHARED_COLLECTION,
        element: <PostPage />,
    },
    {
        path: PATHS.POST,
        element: <PostPage />,
    },
];

export const PROTECTED_ROUTES = [
    {
        path: PATHS.USER_PROFILE,
        element: <ProfilePage />,
    },
    {
        path: PATHS.NOTIFICATION,
        element: <NotificationPage />,
    },
    { path: PATHS.SEARCH, element: <SearchPage /> },
    {
        path: PATHS.EXPLORE,
        element: <ExplorePage />,
    },
    {
        path: PATHS.FOLLOWING,
        element: <FollowingPage />,
    },
    {
        path: PATHS.INDEX,
        element: <IndexPage />,
    },
    {
        path: PATHS.ONBOARD,
        element: <TutorialPage />,
    },
];

import axios, { axiosPrivate } from "@api/axios";

class LikeService {
    static async likePost(postId) {
        try {
            const response = await axiosPrivate.post("/like", { postId });
            return response.data.data;
        } catch (error) {
            throw new Error(
                error.response
                    ? error.response.data.error
                    : "Failed to like post."
            );
        }
    }

    static async unlikePost(postId) {
        try {
            await axiosPrivate.delete("/like", {
                data: { postId },
            });
            return {
                success: true,
            };
        } catch (error) {
            throw new Error(
                error.response
                    ? error.response.data.error
                    : "Failed to unlike post."
            );
        }
    }

    static async getLikes(postId) {
        try {
            const response = await axios.get("/like", {
                params: { postId },
            });

            return response.data.data;
        } catch (error) {
            throw new Error(
                error.response
                    ? error.response.data.error
                    : "Failed to get likes."
            );
        }
    }
}

export default LikeService;

import { axiosPrivate } from "@api/axios";
import Page from "@classes/Page";
import PostService from "./PostService";
import { DefaultPageTag } from "@constants";

class PageService {
    static async createPage(pageName, posts) {
        try {
            const response = await axiosPrivate.post(`/page`, {
                pageName,
                posts,
            });

            return this.getPageFromData(response.data.data);
        } catch (error) {
            console.error("Failed to create page:", error);
            throw new Error(
                error.response
                    ? error.response.data.error
                    : "Failed to create page."
            );
        }
    }

    static async updatePage(pageId, newPage) {
        if (!pageId || !newPage) return null;

        try {
            const response = await axiosPrivate.put(`/page/${pageId}`, {
                pageName: newPage.title ?? null,
                posts: newPage.items ?? null,
            });

            return this.getPageFromData(response.data.data);
        } catch (error) {
            console.error("Failed to update page:", error);
            throw new Error("Failed to update page.");
        }
    }

    static async deletePage(pageId) {
        try {
            const response = await axiosPrivate.delete(`/page/${pageId}`);
            if (!response.data.success) {
                throw new Error();
            }

            return response.data.data;
        } catch (error) {
            throw new Error("Failed to delete page.");
        }
    }

    static async getPage(pageId) {
        try {
            const response = await axiosPrivate.get(`/page/${pageId}`);

            return this.getPageFromData(response.data.data);
        } catch (error) {
            console.error("Failed to fetch page:", error);
            throw new Error("Failed to fetch page.");
        }
    }

    static getUserQuery(userId, username) {
        let queryParam = "?";
        queryParam += userId ? `userId=${userId}` : "";
        queryParam += username && !userId ? `username=${username}` : "";

        return queryParam;
    }

    static async getPages(userId = null, username = null) {
        const queryParam = this.getUserQuery(userId, username);

        try {
            const response = await axiosPrivate.get(`/page${queryParam}`);

            return this.getPagesFromData(response.data.data);
        } catch (error) {
            console.error("Failed to fetch page:", error);
            throw new Error("Failed to fetch page.");
        }
    }

    static async updatePages(pages) {
        try {
            const response = await axiosPrivate.put(`/page`, { pages });

            return response.data.data;
        } catch (error) {
            console.error("Failed to update pages:", error);
        }
    }

    static async getPersonalPage() {
        try {
            const response = await axiosPrivate.get("/page/my");

            const pages = this.getPagesFromData(response.data.data?.pages);
            const posts = PostService.getPostsFromData(
                response.data.data?.posts
            );
            return { pages, posts };
        } catch (error) {
            console.log(error);
            console.error("Failed to fetch page:", error);
            throw new Error("Failed to fetch page.");
        }
    }

    static async getUserPages(userId = null, username = null) {
        const queryParam = this.getUserQuery(userId, username);
        try {
            const response = await axiosPrivate.get(`/page${queryParam}`);

            return this.getPagesFromData(response.data.data, true);
        } catch (error) {
            console.error("Failed to fetch page:", error);
            throw new Error("Failed to fetch page.");
        }
    }

    static getPageFromData(pageData, populateItems = false) {
        if (!pageData) {
            return null;
        }

        let items = pageData.items;
        if (populateItems) {
            items = PostService.getPostsFromData(pageData.items);
        }

        return new Page({
            _id: pageData._id,
            title: pageData.title,
            author: pageData.author,
            items: items,
            createdAt: pageData.createdAt,
            updatedAt: pageData.updatedAt,
            description: pageData.description,
            privacyLevel: pageData.privacyLevel,
            status: pageData.status,
            url: pageData.url,
            isDefault: pageData.tags?.some((tag) => tag === DefaultPageTag),
            isArchive: false,
        });
    }

    static getPagesFromData(pagesData, populateItems = false) {
        return pagesData.map((pageData) =>
            this.getPageFromData(pageData, populateItems)
        );
    }
}

export default PageService;

import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Cog6ToothIcon } from "@heroicons/react/24/outline";
import { EditProfile, ProfileTile } from "@features/profile";
import { ModalType } from "@constants/constants";
import { useModal } from "@contexts/ModalContext";
import { PrivacyIcon } from "@components/PrivacyIcon";
import ProfileService from "@services/ProfileService";

function PersonalPageProfileTile({ userInfo, setUserInfo }) {
    const { openModal } = useModal();

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const profileData = await ProfileService.getProfile(
                    userInfo.profile.username
                );
                setUserInfo((prevState) => ({
                    ...prevState,
                    profile: profileData,
                }));
            } catch (error) {
                console.error("Error fetching profile:", error);
            }
        };

        if (
            userInfo.profile &&
            !userInfo?.profile?.hasOwnProperty("profilePicture")
        ) {
            fetchProfile();
        }
    }, [userInfo]);

    const updateProfileInState = (updatedProfile) => {
        setUserInfo((prevState) => ({ ...prevState, profile: updatedProfile }));
    };

    const handleEditProfile = () => {
        openModal(
            EditProfile,
            `Edit your profile`,
            {},
            {
                profile: userInfo.profile,
                updateProfileCallback: updateProfileInState,
            },
            ModalType.WRITE
        );
    };

    return (
        <div className="relative w-full flex justify-center">
            <ProfileTile profile={userInfo.profile} showFollowButton={false} />
            <div className="ml-4 mt-2 flex space-x-1">
                <PrivacyIcon privacyLevel={userInfo.profile?.privacyLevel} />
                <Link onClick={handleEditProfile}>
                    <Cog6ToothIcon className="w-5 h-5 text-gray-400 hover:text-gray-500" />
                </Link>
            </div>
        </div>
    );
}

export default PersonalPageProfileTile;

import React, { useEffect } from "react";
import { usePersonalPage } from "@contexts/PersonalPageContext";
import { useToast } from "@contexts/ToastContext";
import { useModal } from "@contexts/ModalContext";
import { ModalType } from "@constants";
import CollectionForm from "./CollectionForm";
import CollectionService from "@services/CollectionService";
import { PostStatus } from "@constants";
import { CollectionPost } from "@features/collection";
import { Post } from "@features/post";
import LoadingComponent from "@components/LoadingComponent";

function UpdatedCollectionComponent({ updatedCollection }) {
    return <CollectionPost collection={updatedCollection} />;
}

function LoadingUpdatedCollectionComponent({ collection }) {
    return (
        <Post post={collection} allowModify={false}>
            <LoadingComponent />
        </Post>
    );
}

function EditCollection({ postControl, postEditState }) {
    const { post, handleSubmitPostRef, setAdditionalPostData } = postControl;
    const { closeModal, replaceContentInModal } = useModal();
    const { handleUpdatePost } = usePersonalPage();
    const { showToast } = useToast();

    const handleSubmitEditCollection = async (currPost, isDraft) => {
        try {
            closeModal(); // Close the edit modal
            replaceContentInModal(
                LoadingUpdatedCollectionComponent,
                "",
                {},
                { collection: currPost },
                ModalType.VIEW
            );

            const updatedCollection = await CollectionService.updateCollection(
                currPost._id,
                {
                    title: currPost.title,
                    description: currPost.description,
                    items: currPost.items?.map(
                        (collectionItem) => collectionItem._id
                    ),
                    privacyLevel: currPost.privacyLevel,
                    status: isDraft ? PostStatus.DRAFT : PostStatus.PUBLISHED,
                }
            );
            if (updatedCollection) {
                handleUpdatePost(updatedCollection);

                replaceContentInModal(
                    UpdatedCollectionComponent,
                    updatedCollection.title,
                    {},
                    { updatedCollection: updatedCollection },
                    ModalType.VIEW
                );

                showToast(`Successfully updated post`, "bg-emerald-600");
            } else {
                showToast("Failed to update post", "bg-rose-600");
            }
        } catch (error) {
            console.error("Error updating collection:", error);
            showToast("Failed to update post", "bg-rose-600");
        }
    };

    useEffect(() => {
        handleSubmitPostRef.current = handleSubmitEditCollection;
        setAdditionalPostData((prevState) => ({
            ...prevState,
            collectionItems:
                post.items?.map((item) => ({
                    addedItemId: item._id,
                    addedItemIndex: 0,
                })) || [],
        }));
    }, []);

    return (
        <CollectionForm
            postControl={postControl}
            postEditState={postEditState}
        />
    );
}

export default EditCollection;

import { axiosPrivate } from "@api/axios";
import GemService from "./GemService";

class AIService {
    static async parseToGems(text) {
        try {
            const response = await axiosPrivate.post("/ai/parseToGems", {
                text,
            });

            const { gems, title } = response.data.data;
            const items = GemService.getGemsFromData(gems);
            return { items, title };
        } catch (error) {
            console.error("Failed to parse text:", error);
            throw new Error(
                error.response
                    ? error.response.data.error
                    : "Failed to create collection."
            );
        }
    }
}

export default AIService;

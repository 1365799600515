import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const LogoutListener = ({ onLogout }) => {
    const navigate = useNavigate();

    useEffect(() => {
        const handleStorageChange = () => {
            if (localStorage.getItem("logout-event")) {
                onLogout();
                navigate("/");
            }
        };

        window.addEventListener("storage", handleStorageChange);

        return () => {
            window.removeEventListener("storage", handleStorageChange);
        };
    }, [onLogout]);

    return null;
};

export default LogoutListener;

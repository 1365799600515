import React, { useContext, useEffect, useState } from "react";
import { EditProfile } from "@features/profile";
import LoadingComponent from "@components/LoadingComponent";
import { UserContext } from "@contexts/UserContext";
import { DeleteProfile } from "@features/profile";
import { useNavigate, useParams } from "react-router-dom";
import { PATHS } from "@features/routing";

function ProfilePage() {
    const { userInfo, setUserInfo } = useContext(UserContext);
    const [showDeleteForm, setShowDeleteForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(true);
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (userInfo.username !== params.username) {
            navigate(PATHS.getUserPath(params.username));
        }
    }, [params]);

    const updateProfileInState = (updatedProfile) => {
        setUserInfo((prevState) => ({ ...prevState, profile: updatedProfile }));
    };

    const handleDeleteButtonClick = () => {
        setShowDeleteForm(true);
        setShowEditForm(false);
    };

    const handleBackButtonClick = () => {
        setShowDeleteForm(false);
        setShowEditForm(true);
    };

    if (!userInfo) {
        return <LoadingComponent />;
    }

    return (
        <div>
            {showEditForm && (
                <EditProfile
                    profile={userInfo}
                    updateProfileCallback={updateProfileInState}
                />
            )}
            {showDeleteForm && (
                <DeleteProfile
                    profile={userInfo}
                    updateProfileCallback={updateProfileInState}
                />
            )}
            <div className="pl-6 pr-6 max-w-md mx-auto">
                <button
                    className={
                        showDeleteForm
                            ? "w-full pt-2 pb-2 bg-indigo-600 text-white rounded-md"
                            : "w-full pt-2 pb-2 bg-gray-100 text-red-500 rounded-md hover:bg-red-500 hover:text-white"
                    }
                    onClick={
                        showDeleteForm
                            ? handleBackButtonClick
                            : handleDeleteButtonClick
                    }
                >
                    {showDeleteForm ? "Back to Edit Profile" : "Delete Profile"}
                </button>
            </div>
        </div>
    );
}

export default ProfilePage;

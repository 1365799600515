import React, { useState, useRef, useEffect, useContext } from "react";
import { EllipsisHorizontalIcon } from "@heroicons/react/24/solid";
import { UserContext } from "@contexts/UserContext";
import { usePersonalPage } from "@contexts/PersonalPageContext";
import { useModal } from "@contexts/ModalContext";
import CollectionService from "@services/CollectionService";
import { CollectionPost } from "@features/collection";
import { ModalType } from "@constants/constants";

function CollectionRowOptions({ currentItem, parentCollection }) {
    const { userId } = useContext(UserContext);
    const [showOptions, setShowOptions] = useState(false);
    const [uploading, setUploading] = useState(false);
    const optionsRef = useRef(null); // Ref to track the options div element
    const { pushContentToModal, closeModal } = useModal();
    const { handleUpdatePost } = usePersonalPage();

    const isCurrentUser = userId === currentItem.author._id;

    const handleRowOptionsClick = (e) => {
        e.stopPropagation();
        setShowOptions(!showOptions);
    };

    function UpdatedCollectionComponent({ updatedCollection }) {
        return (
            <CollectionPost
                key={updatedCollection._id}
                collection={updatedCollection}
            />
        );
    }

    async function handleDeleteCollectionItem(e) {
        e.stopPropagation();
        setUploading(true);

        try {
            const updatedItems = parentCollection.items
                .filter((item) => item._id !== currentItem._id)
                .map((collectionItem) => collectionItem._id);

            const removeCollectionItem = async () => {
                try {
                    const updatedCollection =
                        await CollectionService.updateCollection(
                            parentCollection._id,
                            { items: updatedItems }
                        );

                    handleUpdatePost(updatedCollection);
                    closeModal(); // Remove previous (original) post from modal stack
                    pushContentToModal(
                        UpdatedCollectionComponent,
                        updatedCollection.title,
                        {},
                        { updatedCollection: updatedCollection },
                        ModalType.VIEW
                    );
                } catch (error) {
                    console.error("Error fetching collection:", error);
                } finally {
                    setUploading(false);
                }
            };

            removeCollectionItem();
        } catch (error) {
            console.error("Failed to update the collection:", error);
        }

        setShowOptions(false);
    }

    // Hide the options box when user clicks outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                optionsRef.current &&
                !optionsRef.current.contains(event.target)
            ) {
                setShowOptions(false);
            }
        };

        // Add the event listener when the options box is shown
        if (showOptions) {
            document.addEventListener("mousedown", handleClickOutside);
        }

        // Clean up the event listener when the component unmounts or when the options box is hidden
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showOptions]);

    // Current user can only edit or delete their own posts.
    if (!isCurrentUser || !currentItem) {
        return;
    }

    return (
        <div>
            <button onClick={handleRowOptionsClick}>
                <EllipsisHorizontalIcon className="h-5 w-5 text-gray-400 hover:text-gray-600" />
            </button>

            {showOptions && (
                <div
                    ref={optionsRef}
                    className="absolute right-0 mr-4 bg-white border border-gray-300 rounded shadow-lg text-sm"
                >
                    <button
                        className="block w-full text-left px-4 py-2 hover:bg-gray-200"
                        disabled={uploading}
                        onClick={handleDeleteCollectionItem}
                    >
                        Remove from collection
                    </button>
                </div>
            )}
        </div>
    );
}

export default CollectionRowOptions;

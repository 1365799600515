import Profile from "@classes/Profile";
import axios, { axiosPrivate } from "@api/axios";

class ProfileService {
    static async getProfile(username) {
        try {
            const response = await axiosPrivate.get(
                `/users/profile/${username}`
            );

            return this.getProfileFromData(response.data);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                // Handle unauthorized error as needed
                // For example, redirect to the login page or show a specific message
                return;
            }

            console.error("Failed to get profile:", error);
            throw new Error(
                error.response
                    ? error.response.data.error
                    : "Failed to get profile."
            );
        }
    }

    static async updateProfile(username, updatedProfileData) {
        try {
            const response = await axiosPrivate.put(
                `/users/profile/${username}`,
                updatedProfileData,
                {
                    headers: { "Content-Type": "multipart/form-data" },
                    withCredentials: true,
                }
            );
            return this.getProfileFromData(response.data);
        } catch (error) {
            console.error("Updating profile failed:", error);
            throw new Error(
                error.response
                    ? error.response.data.error
                    : "Failed to update profile."
            );
        }
    }

    static async completeOnboard(username) {
        try {
            const response = await axiosPrivate.post(
                `/users/complete-onboard/${username}`,
                {}
            );
            return this.getProfileFromData(response.data);
        } catch (error) {
            console.error("Updating profile failed:", error);
            throw new Error(
                error.response
                    ? error.response.data.error
                    : "Failed to update profile."
            );
        }
    }

    static async getCurrentProfile() {
        try {
            const response = await axiosPrivate.get(`/users/profile`);
            return {
                success: true,
                data: response.data.data,
            };
        } catch (error) {
            return {
                success: false,
                message:
                    error.message || "An error occurred fetching the profile.",
            };
        }
    }

    static async getProfilePrivacyLevel(username) {
        try {
            const response = await axios.get(
                `/users/profile/privacyLevel/${username}`
            );

            return response.data.data;
        } catch (error) {
            console.error("Failed to get profile:", error);
            throw new Error(
                error.response
                    ? error.response.data.error
                    : "Failed to get profile."
            );
        }
    }

    static async deleteProfile(credentials) {
        try {
            await axiosPrivate.delete(`/users/profile`, {
                data: {
                    username: credentials.username,
                    password: credentials.password,
                },
            });

            return {
                success: true,
                status: 200,
                message: "Account deleted successfully.",
            };
        } catch (error) {
            if (error.response && error.response.status === 401) {
                return {
                    success: false,
                    status: error.response.status,
                    message: "Invalid credentials.",
                };
            }

            return {
                success: false,
                status: error.response.status,
                message: "Delete failed.",
            };
        }
    }

    static getProfileFromData(profileData) {
        return new Profile(
            profileData.username,
            profileData.profilePicture,
            profileData.name,
            profileData.bio,
            profileData.privacyLevel,
            profileData.verificationToken
        );
    }
}

export default ProfileService;

import React, { useEffect } from "react";
import { usePersonalPage } from "@contexts/PersonalPageContext";
import { useToast } from "@contexts/ToastContext";
import { useModal } from "@contexts/ModalContext";
import { ModalType } from "@constants";
import { useGemForm } from "../hooks/useGemForm";
import GemForm from "./GemForm";
import GemService from "@services/GemService";
import { GemPost } from "@features/gem";
import { Post } from "@features/post";
import LoadingComponent from "@components/LoadingComponent";

function UpdatedGemComponent({ updatedGem }) {
    return <GemPost gem={updatedGem} />;
}

function LoadingUpdatedGemComponent({ gem }) {
    return (
        <Post post={gem} allowModify={false}>
            <LoadingComponent />
        </Post>
    );
}

function EditGem({ postControl }) {
    const { handleSubmitPostRef } = postControl;

    const gemFormControl = useGemForm(postControl);
    const { getGemForm } = gemFormControl;

    const { closeModal, replaceContentInModal } = useModal();
    const { handleUpdatePost } = usePersonalPage();
    const { showToast } = useToast();

    const handleSubmitEditGem = async (
        currPost,
        isDraft,
        currEditorContent
    ) => {
        try {
            closeModal(); // Close the edit modal
            replaceContentInModal(
                LoadingUpdatedGemComponent,
                "",
                {},
                { gem: currPost },
                ModalType.VIEW
            );

            const gemForm = await getGemForm(
                isDraft,
                currPost,
                currEditorContent
            );
            const updatedGem = await GemService.updateGem(gemForm);
            if (updatedGem) {
                handleUpdatePost(updatedGem);

                replaceContentInModal(
                    UpdatedGemComponent,
                    updatedGem.title,
                    {},
                    { updatedGem: updatedGem },
                    ModalType.VIEW
                );

                showToast(`Successfully updated post`, "bg-emerald-600");
            } else {
                showToast("Failed to update post", "bg-rose-600");
            }
        } catch (error) {
            console.error("Error updating gem:", error);
            showToast("Failed to update post", "bg-rose-600");
        }
    };

    useEffect(() => {
        handleSubmitPostRef.current = handleSubmitEditGem;
    }, []);

    return <GemForm postControl={postControl} />;
}

export default EditGem;

import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { TitleRenderer } from "@features/linkToGem";
import { Post } from "@classes/Post";
import { ItemModalComponent } from "@features/collection";
import { useModal } from "@contexts/ModalContext";
import { ModalType, PostType } from "@constants";
import { useCollectionForm } from "../hooks/useCollectionForm";
import { CreatePostButton } from "@features/create";
import { PlusIcon } from "@heroicons/react/24/outline";

function ItemRow({
    item,
    savedEditModalState,
    hoverStyle = "bg-gray-300",
    children,
}) {
    const { pushContentToModal, updateCurrentModalState } = useModal();

    if (!item) return;

    const handleItemClick = () => {
        // Save the current state so when we come back to editing this post, the flow can continue.
        updateCurrentModalState(savedEditModalState);
        pushContentToModal(
            ItemModalComponent,
            item.title,
            {},
            { item, allowModify: false },
            ModalType.VIEW
        );
    };

    return (
        <div className={`flex items-center p-2 hover:${hoverStyle}`}>
            <div className="w-8/12">
                <Link onClick={handleItemClick}>
                    {item.title || item.description ? (
                        <>
                            <div className="text-sm font-semibold text-oversize-1">
                                {TitleRenderer(item)}
                            </div>
                            <div className="text-xs text-gray-500 text-oversize-1 max-w-full">
                                {item.description}
                            </div>
                        </>
                    ) : (
                        <div className="text-white" style={{ opacity: 0 }}>
                            Placeholder
                        </div> // Make the whitespace clickable even if the item has no title or description
                    )}
                </Link>
            </div>
            <div className="w-3/12 text-sm text-gray-500 text-oversize-1 px-2 text-center">
                {Post.timeDisplay(item.updatedAt)}
            </div>
            <div className="w-1/12 flex justify-center">{children}</div>
        </div>
    );
}

function CollectionItemsSection({
    collectionItems,
    savedEditModalState,
    updateCollectionAndOptionItems,
    getPost,
}) {
    const removeFromCollection = (ev, removedItemId) => {
        ev.preventDefault();
        ev.stopPropagation();

        const itemIndex =
            collectionItems?.find((item) => item.addedItemId === removedItemId)
                ?.addedItemIndex ?? 0;

        const updateCollection = (prev) =>
            prev?.filter((item) => item.addedItemId !== removedItemId);
        const updateOptions = (prev) => {
            // If the item should be inserted at the beginning of the array e.g. a new item
            if (itemIndex === -1) {
                return [removedItemId, ...prev];
            }

            // Insert the item at the found index
            return [
                ...prev.slice(0, itemIndex),
                removedItemId,
                ...prev.slice(itemIndex),
            ];
        };

        updateCollectionAndOptionItems(updateCollection, updateOptions);
    };

    if (!collectionItems || collectionItems.length === 0) {
        return (
            <div className="border-b px-2 py-4 bg-gray-300">
                <div className="text-sm px-1 py-1 italic">
                    Add items to your collection
                </div>
            </div>
        );
    }

    return (
        <div className="mb-2 max-h-56 overflow-y-auto divide-y bg-gray-200">
            {collectionItems?.map((item, index) => (
                <ItemRow
                    key={item.addedItemId}
                    item={getPost(item.addedItemId)}
                    savedEditModalState={savedEditModalState}
                >
                    <button
                        onClick={(ev) =>
                            removeFromCollection(ev, item.addedItemId)
                        }
                        className="bg-gray-300 group-hover:bg-gray-400 text-black text-sm py-1 px-2 rounded-full"
                    >
                        Remove
                    </button>
                </ItemRow>
            ))}
        </div>
    );
}

function ItemOptionsSection({
    itemOptions,
    savedEditModalState,
    updateCollectionAndOptionItems,
    getPost,
}) {
    const addToCollection = (ev, addedItemId, addedItemIndex) => {
        ev && ev.preventDefault();
        ev && ev.stopPropagation();
        const updateCollection = (prev) => [
            ...prev,
            { addedItemId, addedItemIndex },
        ];
        const updateOptions = (prev) =>
            prev.filter((itemId) => itemId !== addedItemId);
        updateCollectionAndOptionItems(updateCollection, updateOptions);
    };

    return (
        <div className="pt-4">
            <div className="font-bold text-sm mb-1">
                Choose from existing items
            </div>
            <div className="mb-2 max-h-72 overflow-y-auto divide-y bg-white group">
                {itemOptions?.length > 0 ? (
                    itemOptions.map((itemId, index) => (
                        <ItemRow
                            key={itemId}
                            item={getPost(itemId)}
                            savedEditModalState={savedEditModalState}
                            hoverStyle="bg-gray-200"
                        >
                            <button
                                onClick={(ev) =>
                                    addToCollection(ev, itemId, index)
                                }
                                className="bg-gray-200 group-hover:bg-gray-300 text-black text-sm font-semibold py-1 px-2 rounded-full"
                            >
                                Add
                            </button>
                        </ItemRow>
                    ))
                ) : (
                    <div className="text-sm text-gray-600 bg-gray-200 px-2 py-4">
                        You don't have any items
                    </div>
                )}
            </div>
        </div>
    );
}

function NewItemSection({ savedEditModalState }) {
    const { updateCurrentModalState } = useModal();

    const handleCreatePostButtonClick = () => {
        // Save the current state so when we come back to editing this post, the flow can continue.
        updateCurrentModalState(savedEditModalState);
    };

    return (
        <div className="pt-4">
            <div className="mb-2 bg-white border border-gray-200 rounded-md text-black text-left text-sm shadow-md hover:shadow-lg">
                <CreatePostButton
                    currentPage={null}
                    postType={PostType.GEM}
                    newPostLabel={"Create new item"}
                    onCreatePostButtonClick={handleCreatePostButtonClick}
                    isFromCollection={true}
                >
                    <div className="flex space-x-2 items-center justify-center">
                        <PlusIcon className="w-4 h-4 transition-none" />
                        <div className="">Create a new item</div>
                    </div>
                </CreatePostButton>
            </div>
        </div>
    );
}

function CollectionForm({ postControl, postEditState }) {
    const collectionFormControl = useCollectionForm(postControl);
    const { additionalPostData, updateCollectionAndOptionItems, getPost } =
        collectionFormControl;

    return (
        <div className="my-2">
            <CollectionItemsSection
                collectionItems={additionalPostData?.collectionItems ?? []}
                savedEditModalState={{ postControl, postEditState }}
                updateCollectionAndOptionItems={updateCollectionAndOptionItems}
                getPost={getPost}
            />
            <ItemOptionsSection
                itemOptions={additionalPostData?.itemOptions ?? []}
                savedEditModalState={{ postControl, postEditState }}
                updateCollectionAndOptionItems={updateCollectionAndOptionItems}
                getPost={getPost}
            />
            <NewItemSection
                savedEditModalState={{ postControl, postEditState }}
            />
        </div>
    );
}

export default CollectionForm;

import React, { useContext } from "react";
import { UserContext } from "@contexts/UserContext";
import CollectionsContaingPostPanel from "./CollectionsContainingPostPanel";
import { LikeCommentPanel } from "@features/comment";
import { ProfilePicture, AuthorProfileDisplay } from "@features/profile";
import { TitleRenderer } from "@features/linkToGem";
import PostPanelHeader from "./PostPanelHeader";
import PostFrame from "./PostFrame";
import PostOptions from "./PostOptions";
import {
    CopyPostToClipboardButton,
    EditPostButton,
    DeletePostButton,
} from "./PostActionButtons";

function PostHeader({ post, useLink, allowModify }) {
    const { userId } = useContext(UserContext);
    const isCurrentUser = userId === post?.author?._id;

    return (
        <div className="flex justify-between text-sm">
            <AuthorProfileDisplay profile={post.author} useLink={useLink} />
            <div className="ml-auto flex justify-end items-center space-x-4 text-black">
                {isCurrentUser && (
                    <div className="flex items-center space-x-2">
                        <EditPostButton post={post} />
                        {allowModify && <DeletePostButton post={post} />}
                        <CopyPostToClipboardButton post={post} />
                        <PostOptions post={post} />
                    </div>
                )}
            </div>
        </div>
    );
}

function PostDescription({ post }) {
    if (!post.description) return null;

    return (
        <div className="description flex space-x-2 text-sm mt-4">
            <div className="mt-1 flex-shrink-0">
                <ProfilePicture profile={post.author} />
            </div>
            <div>
                <AuthorProfileDisplay
                    profile={post.author}
                    showProfilePicture={false}
                />
                <p>{post.description}</p>
            </div>
        </div>
    );
}

function PostContent({ post, children }) {
    const title = TitleRenderer(post);
    return (
        <div className="post-content pt-6">
            {title && (
                <div className="flex items-center justify-between mb-6">
                    <h1 className="font-title font-bold">{title}</h1>
                </div>
            )}
            <div className="content text-sm">{children}</div>
        </div>
    );
}

function PanelContent({ post }) {
    return (
        <div className="px-4 pt-2 text-black">
            <PostDescription post={post} />
            <LikeCommentPanel post={post} />
            <CollectionsContaingPostPanel post={post} />
        </div>
    );
}

function Post({ post, allowModify = true, children }) {
    return (
        <PostFrame
            leftContent={
                <>
                    <PostHeader post={post} allowModify={allowModify} />
                    {children}
                </>
            }
            rightContent={
                <>
                    <PostPanelHeader post={post} />
                    <PanelContent post={post} />
                </>
            }
        />
    );
}

export { Post, PostHeader, PostDescription, PostContent };

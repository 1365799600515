import { Link } from "react-router-dom";
import { PATHS } from "../constants/Paths";

// const GemLink = ({ gemId, children }) => {
//     const gemPath = PATHS.GEM.replace(":id", gemId);
//     return <Link to={gemPath}>{children}</Link>;
// };

export const ProfileLink = ({ profile, children, onClick }) => {
    const profilePath = PATHS.USER.replace(":username", profile.username);
    if (onClick) {
        return (
            <Link to={profilePath} onClick={onClick}>
                {children}
            </Link>
        );
    }
    return <Link to={profilePath}>{children}</Link>;
};

import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { PageDisplay, UserPageSidePanel } from "@features/page";
import { ProfileTile } from "@features/profile";
import LoadingComponent from "@components/LoadingComponent";
import { UserContext } from "@contexts/UserContext";
import useUserPagePosts from "../hooks/useUserPagePosts";
import { PostGrid } from "@features/grid";

function UserPageDisplay({
    username,
    profile,
    loading,
    isSidePanelOpen,
    setPageLoading = null,
}) {
    const { userInfo } = useContext(UserContext);
    const { page: pageParam } = useParams();
    const [posts, setPosts] = useState(null);

    const {
        pages,
        currentPage,
        pagePosts,
        handleSelectPage,
        loading: pageLoading,
    } = useUserPagePosts(username, pageParam);

    useEffect(() => {
        if (setPageLoading) {
            setPageLoading(pageLoading);
        }
    }, [pageLoading]);

    useEffect(() => {
        setPosts(pagePosts);
    }, [pagePosts]);

    if (!profile) {
        return <div>{username} not found.</div>;
    }

    const showFollowButton = Boolean(userInfo);
    return (
        <PageDisplay
            SidePanel={() => (
                <UserPageSidePanel
                    username={username}
                    pages={pages}
                    currentPageId={currentPage?._id}
                    handleSelectPage={handleSelectPage}
                    isOpen={isSidePanelOpen}
                />
            )}
            isSidePanelOpen={isSidePanelOpen}
            isLoading={pageLoading}
        >
            <div className="mt-8">
                <div className="relative w-full flex justify-center">
                    <ProfileTile
                        profile={profile}
                        showFollowButton={showFollowButton}
                    />
                </div>
                {pageLoading ? (
                    <LoadingComponent />
                ) : (
                    <PostGrid posts={posts} />
                )}
            </div>
        </PageDisplay>
    );
}

export default UserPageDisplay;

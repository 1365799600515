import React, { useState, useRef } from "react";
import { PlusIcon, XMarkIcon, CheckIcon } from "@heroicons/react/24/outline";
import PageService from "@services/PageService";
import useOutsideClick from "@hooks/useOutsideClick";

function PageTitleInput({
    placeholderValue,
    handleChange,
    page = null,
    children,
}) {
    const [isEditing, setIsEditing] = useState(false);
    const [pageTitle, setPageTitle] = useState(page?.title ?? "");
    const pageNameEditRef = useRef();
    useOutsideClick(pageNameEditRef, () => setIsEditing(false));

    const handleButtonClick = () => {
        setIsEditing(true);
    };

    const handleInputChange = (e) => {
        setPageTitle(e.target.value);
    };

    const handlePageChange = async () => {
        let newPage;
        try {
            if (!page) {
                newPage = await PageService.createPage(pageTitle, []);
                handleChange(newPage);
                setPageTitle("");
            } else if (page && page.title !== pageTitle) {
                page.title = pageTitle;
                handleChange(page);

                const newPage = await PageService.updatePage(page._id, {
                    title: page.title,
                });
                setPageTitle(newPage?.title);
            }
        } catch (error) {
            console.error(error);
        }

        setIsEditing(false);
    };

    const handleKeyPress = async (e) => {
        if (e.key === "Enter") {
            handlePageChange();
        }
    };

    if (isEditing) {
        return (
            <div
                className="flex items-center bg-white pr-2"
                ref={pageNameEditRef}
            >
                <input
                    autoFocus
                    className={`py-4 pl-4 pr-2 appearance-none focus:outline-none border-none`}
                    name={"new page"}
                    type={"text"}
                    placeholder={placeholderValue}
                    value={pageTitle}
                    onChange={handleInputChange}
                    onKeyPress={handleKeyPress}
                />
                <div className="flex items-center space-x-1">
                    <button onClick={handlePageChange}>
                        <CheckIcon className="w-4 h-4 text-gray-500 font-bold" />
                    </button>
                    <button onClick={() => setIsEditing(false)}>
                        <XMarkIcon className="w-4 h-4 text-gray-500 font-bold" />
                    </button>
                </div>
            </div>
        );
    }

    // If not in edit state, return the default button state (e.g. New button, Edit button).
    return children(handleButtonClick);
}

function NewPageButton({ handleNewPage }) {
    return (
        <PageTitleInput
            placeholderValue={"New page name"}
            handleChange={handleNewPage}
        >
            {(handleButtonClick) => (
                <button
                    onClick={handleButtonClick}
                    className="bg-gray-200 font-bold p-4 w-full hover:bg-gray-300"
                >
                    <div className="flex items-center space-x-2">
                        <PlusIcon className="w-4 h-4" />
                        <div className="text-purple-800">New Page</div>
                    </div>
                </button>
            )}
        </PageTitleInput>
    );
}

export { NewPageButton, PageTitleInput };

import { axiosPrivate } from "@api/axios";

class FollowService {
    static followersCache = new Map();
    static followingCache = new Map();

    static async getFollowers(username) {
        if (this.followersCache.has(username)) {
            return this.followersCache.get(username);
        }

        try {
            const response = await axiosPrivate.get(
                `/users/${username}/followers`
            );

            this.followersCache.set(username, response.data);
            return response.data;
        } catch (error) {
            console.error("Error fetching followers list:", error);
            throw error;
        }
    }

    static async getFollowing(username) {
        if (this.followingCache.has(username)) {
            return this.followingCache.get(username);
        }

        try {
            const response = await axiosPrivate.get(
                `/users/${username}/following`
            );

            this.followingCache.set(username, response.data);
            return response.data;
        } catch (error) {
            console.error("Error fetching following list:", error);
            throw error;
        }
    }

    static invalidateCache(cache, username) {
        cache.delete(username);
    }

    static async followUser(usernameToFollow, currentUsername) {
        try {
            console.log(usernameToFollow);
            const response = await axiosPrivate.post(
                `/users/${usernameToFollow}/follow`
            );

            this.invalidateCache(this.followingCache, currentUsername);
            this.invalidateCache(this.followersCache, usernameToFollow);
            return response.data;
        } catch (error) {
            console.error("Error following user:", error);
            throw error;
        }
    }

    static async unfollowUser(usernameToUnfollow, currentUsername) {
        try {
            const response = await axiosPrivate.delete(
                `/users/${usernameToUnfollow}/unfollow`
            );

            this.invalidateCache(this.followingCache, usernameToUnfollow);
            this.invalidateCache(this.followersCache, currentUsername);
            return response.data;
        } catch (error) {
            console.error("Error unfollowing user:", error);
            throw error;
        }
    }
}

export default FollowService;

import HttpError from "@classes/HttpError";
import GemService from "./GemService";
import CollectionService from "./CollectionService";
import { PostType } from "@constants";
import { Endpoints } from "@constants/constants";
import { axiosPrivate } from "@api/axios";

const test_post_data = "/test-data/posts.json";

class PostService {
    static buildQueryParam(endpoint, username = null, pageId = null) {
        let queryParam;
        switch (endpoint) {
            case Endpoints.ALL:
                queryParam = `?scope=${Endpoints.FOLLOWING},${Endpoints.FRIENDS},${Endpoints.PUBLIC}`;
                break;

            case Endpoints.FOLLOWING:
                queryParam = `?scope=${Endpoints.FOLLOWING},${Endpoints.FRIENDS}`;
                break;
            case Endpoints.FRIENDS:
            case Endpoints.MY:
                queryParam = `?scope=${endpoint}`;
                queryParam += pageId ? `&pageId=${pageId}` : "";
                break;

            case Endpoints.USER:
                if (!username) {
                    return console.error("Username must be specified");
                }

                queryParam = `?username=${username}`;
                queryParam += pageId ? `&pageId=${pageId}` : "";
                break;

            default:
                return console.error("Invalid scope:", endpoint);
        }

        // Exclude fetching gems that should only be displayed in collections
        queryParam += "&collectionFilter=excludeCollectionOnly";
        return queryParam;
    }

    static async deletePost(post, shouldDeleteCollectionItems = false) {
        try {
            const response = await axiosPrivate.delete(
                `/${post.type}/${post._id}`,
                {
                    data: { shouldDeleteCollectionItems },
                }
            );
            if (!response.data.success) {
                throw new Error();
            }
            return response.data.data;
        } catch (error) {
            throw new Error("Failed to delete post.");
        }
    }

    static async getPosts(queryParam) {
        try {
            if (process.env.REACT_APP_MODE === "test") {
                const posts = await this.getPostsFromTestData();
                return posts;
            }

            const response = await axiosPrivate.get(`/post${queryParam}`);

            return this.getPostsFromData(response.data.data);
        } catch (error) {
            console.error("Failed to fetch posts:", error);
            throw new Error("Failed to fetch posts.");
        }
    }

    static async getRecommendations(page = "all") {
        try {
            if (process.env.REACT_APP_MODE === "test") {
                const posts = await this.getPostsFromTestData();
                return posts;
            }

            const response = await axiosPrivate.get(
                `/post/recommendations?page=${page}`
            );

            const map = await this.convertMap(response.data.data);
            return map;
        } catch (error) {
            console.error("Failed to fetch posts:", error);
            throw new Error("Failed to fetch posts.");
        }
    }

    static async getPost(postId) {
        try {
            const response = await axiosPrivate.get(`/post/${postId}`);

            if (!response.data.success) {
                throw new HttpError(
                    response.status,
                    `Request failed with status: ${response.status}`
                );
            }

            return this.getPostFromData(response.data.data);
        } catch (error) {
            console.error("Error fetching post:", error);
            throw new Error("Failed to fetch post.");
        }
    }

    static async getCollectionsContainingPost(postId) {
        try {
            const response = await axiosPrivate.get(
                `/post/inCollections/${postId}`
            );

            return this.getPostsFromData(response.data.data);
        } catch (error) {
            console.error("Failed to fetch posts:", error);
            throw new Error("Failed to fetch posts.");
        }
    }

    static async getPostsFromTestData() {
        // Use fetch to get data from a local file in the public directory
        const response = await fetch(test_post_data);
        const posts = await response.json();
        return this.getPostsFromData(posts);
    }

    static getPostFromData(postData) {
        if (!postData) return null;

        if (postData.__t === PostType.GEM) {
            return GemService.getGemFromData(postData);
        } else if (postData.__t === PostType.COLLECTION) {
            return CollectionService.getCollectionFromData(postData);
        }
    }

    static getPostsFromData(postsData) {
        return postsData
            .map((post) => this.getPostFromData(post))
            .filter((post) => post != null);
    }

    static async convertMap(currentResponse) {
        const newMap = new Map();

        for (const [key, objects] of currentResponse) {
            const posts = await this.getPostsFromData(objects);
            newMap.set(key, posts);
        }

        return newMap;
    }
}

export default PostService;

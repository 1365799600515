import React from "react";
import { useGemForm } from "../hooks/useGemForm";
import Editor from "@components/Editor";
import { MediaInput } from "@features/mediaInput";

function GemFormHelperText() {
    return (
        <div className="text-gray-500">
            Embed a link, add text, and/or add images.
        </div>
    );
}

function GemForm({ postControl }) {
    const gemFormControl = useGemForm(postControl);
    const {
        post,
        handleContentChange,
        handleLinks,
        setEditorContent,
        setIsLinkLoading,
    } = gemFormControl;

    return (
        <>
            <div className="mt-2">
                <GemFormHelperText />
            </div>
            <div className="mt-1">
                <MediaInput
                    post={post}
                    handleLinks={handleLinks}
                    setIsLinkLoading={setIsLinkLoading}
                />
            </div>
            <div className="my-2">
                <Editor
                    value={post.content}
                    onChange={handleContentChange}
                    setEditorContent={setEditorContent}
                    handleLinks={handleLinks}
                    gemTitle={post.title}
                />
            </div>
        </>
    );
}

export default GemForm;

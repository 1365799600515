import { useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { UserContext } from "@contexts/UserContext";

export function useRedirectIfLoggedIn() {
    const navigate = useNavigate();
    const location = useLocation();
    const { userInfo, isAuthenticated } = useContext(UserContext);

    useEffect(() => {
        if (isAuthenticated()) {
            let from = location.state?.from?.pathname || "/";

            if (userInfo?.profile?.verificationToken) {
                from = "/onboard";
            }
            navigate(from);
        }
    }, [userInfo, isAuthenticated, navigate, location]);
}

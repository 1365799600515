import React from "react";
import { PaperAirplaneIcon } from "@heroicons/react/24/solid";

function SendCommentButton({ handleSendComment }) {
    return (
        <button onClick={handleSendComment} className="focus:outline-none">
            <PaperAirplaneIcon className="h-5 w-5 text-blue-700 transform hover:scale-110 transition-transform" />
        </button>
    );
}

export default SendCommentButton;

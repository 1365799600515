import React, { useEffect } from "react";
import { PostEditState } from "@constants";
import CreateGem from "../gem/components/CreateGem";
import EditGem from "../gem/components/EditGem";
import CreateCollection from "../collection/components/CreateCollection";
import EditCollection from "../collection/components/EditCollection";
import AutoCreate from "../autocreate/components/AutoCreate";
import EditPost from "./EditPost";
import { usePostEditState } from "../hooks/usePostEditState";
import { usePost } from "../hooks/usePost";
import PreviewPost from "./PreviewPost";

const getPostEditForm = (postControl, postEditState) => {
    const { currentState } = postEditState;
    const { isGem, isCollection, isAutoCreate } = postControl;

    let form;
    switch (currentState) {
        case PostEditState.Create:
            if (isGem) {
                form = <CreateGem postControl={postControl} />;
            } else if (isCollection) {
                form = (
                    <CreateCollection
                        postControl={postControl}
                        postEditState={postEditState}
                    />
                );
            } else if (isAutoCreate) {
                form = <AutoCreate postControl={postControl} />;
            }
            break;
        case PostEditState.Edit:
            if (isGem) {
                form = <EditGem postControl={postControl} />;
            } else if (isCollection) {
                form = (
                    <EditCollection
                        postControl={postControl}
                        postEditState={postEditState}
                    />
                );
            }
            break;
    }

    return form;
};

function EditPostState({ postControl, postEditState }) {
    const { currentState } = postEditState;

    if (currentState === PostEditState.Preview) {
        return (
            <PreviewPost
                postControl={postControl}
                postEditState={postEditState}
            />
        );
    } else {
        const form = getPostEditForm(postControl, postEditState);
        return (
            <EditPost postControl={postControl} postEditState={postEditState}>
                {form}
            </EditPost>
        );
    }
}

function CreatePostState({
    currentPage,
    postType,
    isFromCollection = false,
    savedState = null,
}) {
    const postControl = usePost(postType, null, currentPage, isFromCollection);
    const postEditState = usePostEditState(PostEditState.Create);

    useEffect(() => {
        if (savedState?.postControl) {
            const { mergePostState } = postControl;
            mergePostState(savedState?.postControl);
        }

        if (savedState?.postEditState) {
            const { mergePostEditState } = postEditState;
            mergePostEditState(savedState?.postEditState);
        }
    }, [savedState]);

    return (
        <EditPostState
            postControl={postControl}
            postEditState={postEditState}
        />
    );
}

export { CreatePostState, EditPostState };

import React from "react";
import Page from "@classes/Page";
import PageButtonWithOptions from "./PageButtonWithOptions";
import { LockClosedIcon } from "@heroicons/react/24/solid";

function PageLabel({ page, isCurrentPage, handleSelectPage, children }) {
    let pageLabel = page?.title;
    if (page?.isArchive) {
        pageLabel = (
            <div className="flex items-center justify-center space-x-2 opacity-60">
                <LockClosedIcon className="h-4 w-4" />
                <div>{page?.title}</div>
            </div>
        );
    }

    if (page?.isDefault) {
        pageLabel = (
            <div className="flex items-center space-x-2">
                <div>🏠 {page?.title}</div>
            </div>
        );
    }

    return (
        <div
            onClick={() => handleSelectPage(page)}
            className={`group ${
                isCurrentPage
                    ? "bg-indigo-200 bg-opacity-70"
                    : "hover:bg-indigo-200 hover:bg-opacity-40"
            } py-4 pl-4 pr-2 transition-colors duration-200 hover:cursor-pointer relative flex justify-between items-center`}
        >
            <div className="text-md font-bold">{pageLabel}</div>
            {children}
        </div>
    );
}

function PageButtons({ page, currentPageId, handleSelectPage }) {
    const isCurrentPage = currentPageId === page._id;
    return (
        <PageLabel
            page={page}
            isCurrentPage={isCurrentPage}
            handleSelectPage={handleSelectPage}
        />
    );
}

function PageSidePanelWrapper({ isOpen, children }) {
    return (
        <div
            className={`${
                isOpen ? "opacity-100" : "opacity-0"
            } transition-all ease-in-out delay-150`}
        >
            {isOpen && children}
        </div>
    );
}

function PageSidePanel({ pages, currentPageId, handleSelectPage, isOpen }) {
    return (
        <PageSidePanelWrapper isOpen={isOpen}>
            {pages &&
                pages.map((page) => (
                    <PageButtons
                        key={page._id}
                        page={page}
                        currentPageId={currentPageId}
                        handleSelectPage={handleSelectPage}
                    />
                ))}
        </PageSidePanelWrapper>
    );
}

function UserPageSidePanel({
    username,
    pages,
    currentPageId,
    handleSelectPage,
    isOpen,
}) {
    const handleCopyLinkToPage = async (page) => {
        const pageLink = Page.getPageLink(username, page);
        await navigator.clipboard.writeText(pageLink);
    };

    return (
        <PageSidePanelWrapper isOpen={isOpen}>
            {pages &&
                pages.map((page) => {
                    const isCurrentPage = currentPageId === page._id;
                    return (
                        <PageButtonWithOptions
                            key={page._id}
                            page={page}
                            isCurrentPage={isCurrentPage}
                            handleSelectPage={handleSelectPage}
                            handleCopyLinkToPage={handleCopyLinkToPage}
                        />
                    );
                })}
        </PageSidePanelWrapper>
    );
}

export { PageLabel, PageSidePanelWrapper, PageSidePanel, UserPageSidePanel };

import { useState, useEffect } from "react";
import FollowService from "@services/FollowService";

export const useFollow = (username) => {
    const [followersList, setFollowersList] = useState(null);
    const [followingList, setFollowingList] = useState(null);

    const addFollower = (newFollower) => {
        setFollowersList((prev) => [...prev, newFollower]);
    };

    const removeFollower = (oldFollowerUsername) => {
        setFollowersList((prev) =>
            prev.filter((follower) => follower.username !== oldFollowerUsername)
        );
    };

    useEffect(() => {
        const fetchFollowData = async () => {
            try {
                const followers = await FollowService.getFollowers(username);
                setFollowersList(followers);
            } catch (error) {
                console.error("Error fetching followers list:", error);
            }

            try {
                const following = await FollowService.getFollowing(username);
                setFollowingList(following);
            } catch (error) {
                console.error("Error fetching following list:", error);
            }
        };

        if (username) {
            fetchFollowData();
        }
    }, [username]);

    return {
        followersList,
        followingList,
        addFollower,
        removeFollower,
    };
};

import { CreateEmbedRenderer } from "../EmbedRenderer";
import DefaultLinkRenderer from "./DefaultLinkRenderer";

const iframePropsDefault = {
    width: "100%",
    height: "240",
    allowtransparency: "true",
    allow: "encrypted-media",
};

function getEmbedURL(url) {
    const match = url.match(/open\.spotify\.com\/([a-zA-Z0-9\/:_-]+)/);
    return match ? `https://open.spotify.com/embed/${match[1]}` : null;
}

const SpotifyRenderer = {
    detect: (url) => /open\.spotify\.com\/[a-zA-Z0-9\/:_-]+/.test(url),
    preview: (gem) => {
        const linkInfo = gem.metadata;
        let spotifyLinkInfo = { ...linkInfo };
        const regex = new RegExp(`${linkInfo.title} · `, "g");
        spotifyLinkInfo.description = (linkInfo.description || "").replace(
            regex,
            ""
        );

        return DefaultLinkRenderer.preview(spotifyLinkInfo, gem);
    },
    content: (gem) => {
        let iframeProps = { ...iframePropsDefault };
        if (
            gem.metadata?.link?.includes("playlist") ||
            gem.metadata?.link?.includes("album") ||
            gem.metadata?.link?.includes("artist")
        ) {
            iframeProps = { ...iframeProps, height: "500" };
        }

        return CreateEmbedRenderer(gem, getEmbedURL, iframeProps);
    },
    title: (gem) => {
        return <>🎵 {gem.title}</>;
    },
};

export default SpotifyRenderer;

// ResetPasswordForm.js
import React, { useState } from "react";
import { FormButton, FormInput } from "@components/Form";

function ResetPasswordForm({ onSubmit }) {
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState("");

    const handleSubmit = (ev) => {
        ev.preventDefault();
        if (newPassword !== confirmPassword) {
            setError("Passwords do not match");
            return;
        }
        setError("");
        onSubmit({ newPassword });
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="mb-4">
                <FormInput
                    type="password"
                    placeholder="New Password"
                    value={newPassword}
                    onChange={(ev) => setNewPassword(ev.target.value)}
                />
            </div>
            <div className="mb-4">
                <FormInput
                    type="password"
                    placeholder="Confirm New Password"
                    value={confirmPassword}
                    onChange={(ev) => setConfirmPassword(ev.target.value)}
                />
            </div>
            {error && <p className="text-red-500">{error}</p>}
            <div className="flex items-center justify-center">
                <FormButton text="Reset Password" />
            </div>
        </form>
    );
}

export default ResetPasswordForm;

import React, { useContext, useEffect } from "react";
import { UserContext } from "@contexts/UserContext";
import { usePersonalPage } from "@contexts/PersonalPageContext";
import { useToast } from "@contexts/ToastContext";
import { useModal } from "@contexts/ModalContext";
import CollectionForm from "./CollectionForm";
import CollectionService from "@services/CollectionService";
import { Collection } from "@classes/Post";
import { PostStatus } from "@constants";

function CreateCollection({ postControl, postEditState }) {
    const { userInfo } = useContext(UserContext);

    const { handleSubmitPostRef } = postControl;

    const { showToast } = useToast();
    const { closeModal } = useModal();
    const { handleAddPost, handleUpdatePost, handleRemovePost } =
        usePersonalPage();

    const handleSubmitCreateCollection = async (currPost, isDraft, _, page) => {
        const tempCollection = Collection.getLoadingCollection(
            userInfo.profile
        );

        try {
            handleAddPost(tempCollection, page);
            closeModal(); // Close the create post modal

            const newCollection = await CollectionService.createCollection(
                currPost.title,
                currPost.description,
                currPost.items?.map((collectionItem) => collectionItem._id),
                currPost.privacyLevel,
                isDraft ? PostStatus.DRAFT : PostStatus.PUBLISHED,
                page?._id
            );

            if (newCollection) {
                handleUpdatePost(newCollection, tempCollection._id, page);
                showToast(`New collection created!`, "bg-emerald-600");
            } else {
                handleRemovePost(tempCollection, page);
                showToast("Failed to create post", "bg-rose-600");
            }
        } catch (error) {
            console.error("Error creating gem:", error);
            handleRemovePost(tempCollection, page);
            showToast("Failed to create post", "bg-rose-600");
        }
    };

    useEffect(() => {
        handleSubmitPostRef.current = handleSubmitCreateCollection;
    }, []);

    return (
        <CollectionForm
            postControl={postControl}
            postEditState={postEditState}
        />
    );
}

export default CreateCollection;

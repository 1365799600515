import React from "react";
import SymbolRating from "./SymbolRating";

function PriceRating({ value }) {
    return (
        <SymbolRating
            value={Math.round(value)}
            filledSymbol="$"
            halfSymbol=""
            emptySymbol=""
        />
    );
}

export default PriceRating;

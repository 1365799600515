import React from "react";

export function NotificationCounter({ count, color = "bg-red-500" }) {
    return (
        <>
            {count > 0 && (
                <div
                    className={`absolute top-1 right-0 ${color} text-white h-4 min-w-4 px-[4px] py-[2px] flex items-center justify-center rounded-full text-xs`}
                >
                    {count}
                </div>
            )}
        </>
    );
}

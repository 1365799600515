import React from "react";
import { useModal } from "@contexts/ModalContext";
import { useToast } from "@contexts/ToastContext";
import { usePersonalPage } from "@contexts/PersonalPageContext";
import PostService from "@services/PostService";
import { PostType, ModalType } from "@constants";
import { EditGemWrapper } from "@features/gem";
import {
    EditCollectionWrapper,
    DeleteCollectionModal,
} from "@features/collection";
import DeleteConfirmationModal from "@components/DeleteConfirmationModal";
import Tooltip from "@components/Tooltip";
import {
    PencilSquareIcon,
    TrashIcon,
    ArrowTopRightOnSquareIcon,
} from "@heroicons/react/24/outline";

const OptionButton = ({ Icon, onClick, title, tooltipPosition = null }) => {
    return (
        <Tooltip text={title} position={tooltipPosition}>
            <button
                onClick={onClick}
                className="text-gray-600 hover:text-black"
            >
                <Icon className={"h-5 w-5"} />
            </button>
        </Tooltip>
    );
};

const EditPostButton = ({ post }) => {
    const { openModal } = useModal();
    let editComponent;
    let initialState = {};
    if (post.type === PostType.GEM) {
        editComponent = EditGemWrapper;
    } else {
        editComponent = EditCollectionWrapper;
    }

    const editPost = () => {
        openModal(
            editComponent,
            `Edit "${post.title}"`,
            initialState,
            { post: post },
            ModalType.WRITE
        );
    };

    return (
        <OptionButton
            Icon={PencilSquareIcon}
            onClick={editPost}
            title={"Edit"}
        />
    );
};

const DeletePostModal = ({ post }) => {
    const { deletePostFromMyPosts } = usePersonalPage();
    const onDeletePost = async () => {
        try {
            const deleteResponse = await PostService.deletePost(post);

            // Update local state
            deletePostFromMyPosts(post._id, deleteResponse);
        } catch (error) {
            console.log("Error deleting post: " + error);
        }
    };

    if (post.type === PostType.COLLECTION) {
        return <DeleteCollectionModal post={post} />;
    }

    return (
        <DeleteConfirmationModal
            onDelete={onDeletePost}
            deleteMessage={"Delete post?"}
        />
    );
};

const DeletePostButton = ({ post }) => {
    const { openOverlayModal } = useModal();
    const deletePost = () => {
        openOverlayModal(DeletePostModal, { post: post });
    };

    return (
        <OptionButton Icon={TrashIcon} onClick={deletePost} title={"Delete"} />
    );
};

const CopyPostToClipboardButton = ({ post }) => {
    const { showToast } = useToast();

    const sharedPostUrl = `${process.env.REACT_APP_URL}/${encodeURIComponent(
        post.type
    )}/${encodeURIComponent(post._id)}`;

    const handleCopy = async () => {
        try {
            await navigator.clipboard.writeText(sharedPostUrl);
            showToast("Link copied to clipboard", "bg-emerald-600");
        } catch (error) {
            console.log("Error copying link: " + error);
        }
    };

    return (
        <OptionButton
            Icon={ArrowTopRightOnSquareIcon}
            onClick={handleCopy}
            title={"Copy link to post"}
            tooltipPosition={"top-full right-1/2"}
        />
    );
};

export { EditPostButton, DeletePostButton, CopyPostToClipboardButton };

import React from "react";
import { LandingPageBase } from "@features/landing";
import ResetPasswordPanel from "@features/authentication/components/ResetPasswordPanel";

function ResetPasswordPage() {
    return (
        <LandingPageBase>
            <ResetPasswordPanel />
        </LandingPageBase>
    );
}

export default ResetPasswordPage;

import React, { useState, useEffect } from "react";
import { Post, PostContent } from "@features/post";
import { CollectionContent } from "..";
import { usePersonalPage } from "@contexts/PersonalPageContext";
import GemService from "@services/GemService";

function CollectionPost({ collection, allowModify = true }) {
    const { posts, getPost } = usePersonalPage();
    const [currCollection, setCurrCollection] = useState(collection);

    useEffect(() => {
        // Get the most updated post from post context
        if (collection._id) {
            const updatedPost = getPost(collection._id);
            if (updatedPost) {
                setCurrCollection(updatedPost);
            }
        } else {
            setCurrCollection(collection);
        }
    }, [getPost, collection._id]);

    useEffect(() => {
        const fetchData = async () => {
            const updatedItems = [];
            for (let index in collection.items) {
                let curPost = collection.items[index];
                if (curPost.type === "Gem" && !curPost.fullQuality) {
                    let post = await GemService.getGem(curPost._id);
                    post.fullQuality = true;
                    updatedItems.push(post);
                } else {
                    updatedItems.push(curPost);
                }
            }
            setCurrCollection((prevCollection) => ({
                ...prevCollection,
                items: updatedItems,
            }));
        };

        fetchData();
    }, [getPost, collection._id]);

    return (
        <Post post={currCollection} allowModify={allowModify}>
            <PostContent post={currCollection}>
                <CollectionContent
                    collection={currCollection}
                    allowNavToCollectionItem={true}
                    showCollectionRowOptions={true}
                />
            </PostContent>
        </Post>
    );
}

export default CollectionPost;

import React, { useContext } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { reorder } from "@util/drag-utils";
import { UserContext } from "@contexts/UserContext";
import { usePersonalPage } from "@contexts/PersonalPageContext";
import { useToast } from "@contexts/ToastContext";
import Page from "@classes/Page";
import PageService from "@services/PageService";
import { NewPageButton } from "@features/page/components/PageTitleInput";
import {
    PageLabel,
    PageSidePanelWrapper,
    PageButtonWithOptions,
} from "@features/page";

function PersonalPageSidePanel({ currentPageId, handleSelectPage, isOpen }) {
    const { pages, setPages, updatePage, deletePage, addNewPage } =
        usePersonalPage();
    const { userInfo } = useContext(UserContext);
    const { showToast } = useToast();

    const updatePages = async (newPages) => {
        try {
            PageService.updatePages(newPages.map((page) => page?._id));
        } catch (err) {
            showToast("Failed to update pages", "bg-rose-600");
            console.error("Error updating pages: ", err);
        }
    };

    const onDragEnd = async (result) => {
        const { source, destination } = result;
        if (!destination) {
            return;
        }

        const newPages = reorder(pages, source.index, destination.index);
        setPages(newPages);
        await updatePages(newPages);
    };

    const handleCopyLinkToPage = async (page) => {
        const pageLink = Page.getPageLink(userInfo?.username, page);
        await navigator.clipboard.writeText(pageLink);
    };

    const archivePage = pages?.find((page) => page.isArchive);
    return (
        <PageSidePanelWrapper isOpen={isOpen}>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="pages">
                    {(provided) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            {pages &&
                                pages.map((page, index) => {
                                    if (!page || page.isArchive) return;
                                    const isCurrentPage =
                                        currentPageId === page._id;
                                    return (
                                        <Draggable
                                            key={page._id}
                                            draggableId={page._id}
                                            index={index}
                                        >
                                            {(provided) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    <PageButtonWithOptions
                                                        page={page}
                                                        isCurrentPage={
                                                            isCurrentPage
                                                        }
                                                        handleSelectPage={
                                                            handleSelectPage
                                                        }
                                                        handleUpdatePage={
                                                            updatePage
                                                        }
                                                        handleDeletePage={
                                                            deletePage
                                                        }
                                                        handleCopyLinkToPage={
                                                            handleCopyLinkToPage
                                                        }
                                                    />
                                                </div>
                                            )}
                                        </Draggable>
                                    );
                                })}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            {archivePage && (
                <PageLabel
                    key={archivePage._id}
                    page={archivePage}
                    isCurrentPage={currentPageId === archivePage._id}
                    handleSelectPage={handleSelectPage}
                />
            )}
            <NewPageButton handleNewPage={addNewPage} />
        </PageSidePanelWrapper>
    );
}

export default PersonalPageSidePanel;

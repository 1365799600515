import { CreateEmbedRenderer } from "../EmbedRenderer";
import DefaultLinkRenderer from "./DefaultLinkRenderer";

const iframeProps = {
    frameBorder: "0",
    width: "480",
    height: "270",
    allow: "autoplay",
    allowFullScreen: true,
};

function getEmbedURL(url) {
    const match = url.match(
        /^https:\/\/www\.dailymotion\.com\/video\/([a-z0-9]+)$/
    );
    return match ? `https://www.dailymotion.com/embed/video/${match[1]}` : null;
}

const DailyMotionRenderer = {
    detect: (url) =>
        /^https:\/\/www\.dailymotion\.com\/video\/[a-z0-9]+$/.test(url),
    content: (gem) => {
        return CreateEmbedRenderer(gem, getEmbedURL, iframeProps);
    },
    title: (gem) => {
        return <>🎥 {gem.title}</>;
    },
};

export default DailyMotionRenderer;

// WaitlistForm.js
import React, { useState } from "react";
import { FormButton, FormInput } from "@components/Form";
import LoadingComponent from "@components/LoadingComponent";

function WaitlistForm({ onWaitlistSignup }) {
    const [email, setEmail] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    if (isLoading) {
        return <LoadingComponent />;
    }

    const handleSubmit = async (ev) => {
        setIsLoading(true);
        await onWaitlistSignup({ email });
        setEmail("");
        setIsLoading(false);
    };

    if (isLoading) {
        return <LoadingComponent />;
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="mb-6">
                <input
                    className={` appearance-none border rounded w-full py-3 px-3 leading-tight focus:outline-none focus:ring-1`}
                    type={"text"}
                    placeholder={"Enter your email"}
                    value={email}
                    onChange={(ev) => setEmail(ev.target.value)}
                    autoComplete={"current-email"}
                />
            </div>
            <div className="flex items-center justify-center">
                <button
                    className="py-2 bg-purple-700 bg-opacity-90 hover:bg-opacity-100 text-white font-bold w-full rounded focus:outline-none focus:shadow-outline text-md py-2"
                    type="submit"
                >
                    Join Waitlist
                </button>
            </div>
        </form>
    );
}

export default WaitlistForm;

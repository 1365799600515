import React, { useState, useEffect } from "react";
import { useHeader } from "@contexts/HeaderContext";
import { Endpoints, HeaderPage } from "@constants/constants";
import { usePosts } from "@hooks/usePosts";
import { PostGrid } from "@features/grid";
import LoadingComponent from "@components/LoadingComponent";

function FollowingPage() {
    const { setActivePage } = useHeader();
    const [endpoint] = useState(Endpoints.FOLLOWING);
    const { posts, loading } = usePosts({ endpoint: endpoint });

    useEffect(() => {
        setActivePage(HeaderPage.Following);
    });

    if (loading) {
        return <LoadingComponent />;
    }
    return (
        <div className="px-3">
            <PostGrid posts={posts} />
        </div>
    );
}

export default FollowingPage;

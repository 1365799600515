import UserPageSteps from "./UserPageSteps";
import PersonalPageSteps from "./PersonalPageSteps";
import MainPageSteps from "./MainPageSteps";

const TutorialSteps = ({ setStepIndex, setPage }) => {
    const userPageSteps = UserPageSteps({ setStepIndex, setPage });
    const personalPageSteps = PersonalPageSteps({
        setStepIndex,
        setPage,
    });
    const mainPageSteps = MainPageSteps({ setStepIndex, setPage });
    const steps = [...userPageSteps, ...personalPageSteps, ...mainPageSteps];

    return steps;
};

export default TutorialSteps;

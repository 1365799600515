import React, { useContext, useEffect } from "react";
import { UserContext } from "@contexts/UserContext";
import PersonalPageDisplay from "@features/personalPage/components/PersonalPageDisplay";
import { useHeader } from "@contexts/HeaderContext";
import { HeaderPage } from "@constants";

function PersonalPage() {
    const { userInfo, setUserInfo } = useContext(UserContext);
    const { setActivePage, isSidePanelOpen } = useHeader();

    useEffect(() => {
        setActivePage(HeaderPage.Personal);
    });

    return (
        <PersonalPageDisplay
            userInfo={userInfo}
            setUserInfo={setUserInfo}
            isSidePanelOpen={isSidePanelOpen}
        />
    );
}

export default PersonalPage;

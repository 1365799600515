import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "@contexts/UserContext";
import HeaderMenu from "./components/HeaderMenu";
import ProfileService from "@services/ProfileService";
import { ProfilePicture } from "@features/profile";
import { PATHS } from "@features/routing";
import { Bars3Icon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { SparkleLogo } from "@components/Logo";
import { HeaderPage } from "@constants";
import LogoutListener from "@features/authentication/components/LogoutListener";
import NotificationPanelButton from "@features/notifications/components/NotificationPanelButton";
import { useNotificationsHook } from "@contexts/NotificationProvider";
import { useNavigate } from "react-router-dom";
import NotificationService from "@services/NotificationService";
import { NotificationCounter } from "@features/notifications/components/NotificationCounter";

function SidePanelToggle({ onClick, isSidePanelOpen }) {
    return (
        <button
            onClick={onClick}
            className={`transition-colors p-2 ${
                isSidePanelOpen
                    ? "rounded-full bg-gray-100 shadow-inner"
                    : "rounded-full hover:bg-gray-100"
            }`}
        >
            <Bars3Icon className="w-5 h-5" />
        </button>
    );
}

function HeaderButton({ to, children, isActive, classes = "" }) {
    return (
        <Link to={to}>
            <div
                className={`py-2 px-4 flex space-x-2 items-center font-semibold rounded-full border-4 hover:border-purple-700 ${classes} ${
                    isActive ? "border-purple-700" : "border-white text-black"
                }`}
            >
                {children}
            </div>
        </Link>
    );
}

function ExploreLink({ isActive }) {
    return (
        <HeaderButton
            to="/explore"
            isActive={isActive}
            classes="explore-page-button"
        >
            <div>Explore</div>
        </HeaderButton>
    );
}

function FollowingLink({ isActive }) {
    const {
        followingPostCount,
        resetFollowingPostCount,
    } = useNotificationsHook();
    const navigate = useNavigate();

    let handleClick = () => {
        NotificationService.resetFollowingPostCount();
        resetFollowingPostCount();
        navigate("/following");
    };

    return (
        <div onClick={handleClick} className="cursor-pointer">
            <div
                className={`py-2 px-4 flex space-x-2 items-center font-semibold relative rounded-full border-4 hover:border-purple-700 ${
                    isActive ? "border-purple-700" : "border-white text-black"
                }`}
            >
                <div>Following</div>

                <NotificationCounter
                    count={followingPostCount}
                    color="bg-purple-500"
                />
            </div>
        </div>
    );
}

function SearchLink({ isActive }) {
    return (
        <HeaderButton to={PATHS.SEARCH} isActive={isActive}>
            <MagnifyingGlassIcon className="w-5 h-5" />
            <div>Search</div>
        </HeaderButton>
    );
}

function PersonalPageLink({ userInfo, isActive }) {
    return (
        <HeaderButton
            to={PATHS.getUserPath(userInfo?.username)}
            isActive={isActive}
            classes="personal-page-profile-button"
        >
            <ProfilePicture profile={userInfo?.profile} size={"w-6 h-6"} />
            <div>Your Page</div>
        </HeaderButton>
    );
}

function UnauthenticatedMenu() {
    return (
        <div className="flex items-center space-x-4">
            <Link to={PATHS.LOGIN}>Login</Link>
            <Link to={PATHS.REGISTER}>Register</Link>
        </div>
    );
}

function Header({ isSidePanelOpen, toggleSidePanel, activePage }) {
    const {
        setUserInfo,
        userInfo,
        logout,
        isLoading,
        setIsLoading,
        isAuthenticated,
    } = useContext(UserContext);

    const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);

    useEffect(() => {
        if (isLoading) {
            return;
        }

        if (isAuthenticated()) {
            setIsUserAuthenticated(true);
        }
    }, [userInfo, isAuthenticated, isLoading]);

    useEffect(() => {
        if (isUserAuthenticated && !userInfo?.profile) {
            fetchProfile();
        }
    }, [setUserInfo, setIsLoading, isUserAuthenticated, userInfo]);

    const fetchProfile = async () => {
        try {
            const response = await ProfileService.getCurrentProfile();
            const user = response.data;

            if (user && userInfo?.username) {
                const profileData = await ProfileService.getProfile(
                    userInfo.username
                );

                setUserInfo({
                    username: user.username,
                    profile: profileData,
                });
            }

            setIsLoading(false); // Data has been fetched and state has been set
        } catch (error) {
            console.error("Error fetching profile:", error);
            setIsLoading(false); // Even on error, we stop showing loading state
        }
    };

    function triggerLogout() {
        logout();
        localStorage.setItem("logout-event", "logout" + Math.random());
        localStorage.removeItem("logout-event");
    }

    function handleLogout() {
        logout();
    }

    return (
        <>
            <header className="fixed top-0 left-0 w-full flex justify-between items-center px-2 bg-white shadow-sm z-20">
                <div className="flex items-center space-x-2 pl-1">
                    {toggleSidePanel && (
                        <SidePanelToggle
                            onClick={toggleSidePanel}
                            isSidePanelOpen={isSidePanelOpen}
                        />
                    )}
                    <Link to={"/"}>
                        <div className="text-4xl p-2 pt-4">
                            <SparkleLogo />
                        </div>
                    </Link>
                    <ExploreLink isActive={activePage === HeaderPage.Explore} />
                    {isUserAuthenticated && userInfo && (
                        <FollowingLink
                            userInfo={userInfo}
                            isActive={activePage === HeaderPage.Following}
                        />
                    )}
                </div>
                <div className="pr-1">
                    {isUserAuthenticated ? (
                        <div className="flex items-center justify-center space-x-2">
                            {isUserAuthenticated && userInfo && (
                                <>
                                    <SearchLink
                                        isActive={
                                            activePage === HeaderPage.Search
                                        }
                                    />
                                    <PersonalPageLink
                                        userInfo={userInfo}
                                        isActive={
                                            activePage === HeaderPage.Personal
                                        }
                                    />
                                    <NotificationPanelButton
                                        isActive={
                                            activePage ===
                                            HeaderPage.Notification
                                        }
                                    />
                                </>
                            )}
                            <div className="header-menu flex items-center">
                                <HeaderMenu logout={triggerLogout} />
                            </div>
                        </div>
                    ) : (
                        <UnauthenticatedMenu />
                    )}
                </div>
            </header>
            <LogoutListener onLogout={handleLogout} />
        </>
    );
}

export default Header;

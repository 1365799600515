import { createContext, useState, useContext, useCallback } from "react";
import { Transition } from "@headlessui/react";

const ToastContext = createContext();

export function ToastProvider({ children }) {
    const [isToastVisible, setToastVisible] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [toastColor, setToastColor] = useState("");

    const showToast = useCallback((message, color) => {
        setToastMessage(message);
        setToastColor(color);
        setToastVisible(true);
        setTimeout(() => setToastVisible(false), 4000); // Auto-hide toast after 4 seconds
    }, []);

    const hideToast = useCallback(() => {
        setToastVisible(false);
    }, []);

    const Toast = () => (
        <Transition
            show={isToastVisible}
            enter="transition-opacity duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
        >
            <div
                className={`fixed bottom-8 left-1/2 transform -translate-x-1/2 text-white px-4 py-2 rounded shadow-lg text-md font-bold ${toastColor} z-50`}
            >
                {toastMessage}
            </div>
        </Transition>
    );

    return (
        <ToastContext.Provider
            value={{
                showToast,
                hideToast,
                Toast,
            }}
        >
            {children}
        </ToastContext.Provider>
    );
}

export function useToast() {
    const context = useContext(ToastContext);
    if (!context) {
        throw new Error("useToast must be used within a ToastProvider");
    }
    return context;
}

import React from "react";
import { useNavigate } from "react-router-dom";
import { ProfilePicture } from "@features/profile";
import { useNotificationsHook } from "@contexts/NotificationProvider";
import { NotificationMessageBase } from "./NotificationMessage";

function PanelNotificationMessage({ notification, setShowDropdown }) {
    const { message, time, handleClick } = NotificationMessageBase({
        notification,
    });

    return (
        <div
            onClick={() => {
                setShowDropdown(false);
                handleClick();
            }}
            className={`flex items-center hover:bg-gray-300 cursor-pointer p-2 ${
                notification.seen ? "" : "font-semibold"
            }`}
        >
            <ProfilePicture
                profile={notification.userActing}
                size={"w-8 h-8"}
            />
            <div className="pl-2 max-w-full overflow-hidden">
                <div className="whitespace-nowrap">
                    {notification.userActing.username} {message}
                </div>
                <div className="text-gray-500">{time}</div>
            </div>
        </div>
    );
}

function NotificationPanel({ setShowDropdown }) {
    const navigate = useNavigate();

    let handleClick = () => {
        navigate("/notifications");
        setShowDropdown(false);
    };
    const { notifications } = useNotificationsHook();

    return (
        <div className="rounded-md bg-gray-100 divide-y divide-gray-200 text-sm">
            <div className="p-4 font-semibold">Notifications </div>
            {notifications.map((notification, index) => {
                if (index < 8)
                    return (
                        <PanelNotificationMessage
                            key={index}
                            notification={notification}
                            setShowDropdown={setShowDropdown}
                        />
                    );
            })}
            <div
                className="flex items-center justify-center cursor-pointer hover:bg-gray-300 p-2"
                onClick={handleClick}
            >
                Show All
            </div>
        </div>
    );
}

export default NotificationPanel;

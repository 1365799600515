import React from "react";
import avatar from "nextjs-animal-avatar-generator";

function ProfileDefault({ username, className }) {
    const generateAnimalAvatar = (username) => {
        try {
            const seed = username || "defaultSeed";
            const svg = avatar(seed, { size: "100%", blackout: false });
            return svg;
        } catch (error) {
            return null;
        }
    };

    const animalAvatar = generateAnimalAvatar(username);

    // Conditionally import the default user photo only if needed
    const DefaultUserPhoto = animalAvatar
        ? null
        : require("@assets/defaultUser.jpg");

    const image = (
        <div className={`${className} rounded-full object-cover shadow-lg`}>
            {animalAvatar ? (
                <div dangerouslySetInnerHTML={{ __html: animalAvatar }} />
            ) : (
                <img
                    className={`${className} rounded-full object-cover shadow-lg`}
                    src={DefaultUserPhoto}
                    alt="Default User Photo"
                />
            )}
        </div>
    );

    return image;
}

export default ProfileDefault;

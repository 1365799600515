import React from "react";

const getRenderedContent = (gem) => {
    // Create a div element to parse the HTML content
    const div = document.createElement("div");
    div.innerHTML = gem.content ?? "";

    // Format h1 and h2 elements
    const h1Tags = div.querySelectorAll("h1");
    const h2Tags = div.querySelectorAll("h2");
    h1Tags.forEach((h1) => {
        h1.classList.add("text-3xl", "font-semibold"); // Add your Tailwind classes
    });
    h2Tags.forEach((h2) => {
        h2.classList.add("text-xl", "font-semibold"); // Add your Tailwind classes
    });

    // Format blockquote elements
    const blockquotes = div.querySelectorAll("blockquote");
    blockquotes.forEach((blockquote) => {
        blockquote.classList.add(
            "border-l-4",
            "border-gray-400",
            "italic",
            "my-4",
            "rendered-quill-blockquote",
            "rendered-blockquote"
        );
    });

    // Format links
    const linkElements = div.querySelectorAll("a");
    linkElements.forEach((linkElement) => {
        linkElement.classList.add("underline", "text-blue-700");
    });

    // Return the modified HTML content. Adding ql-editor formats most elements but not all, hence the custom formatting above.
    return (
        <div
            className="rendered-quill-content ql-editor"
            dangerouslySetInnerHTML={{ __html: div.innerHTML }}
        />
    );
};

const DefaultRenderer = {
    detect: (url) => true,

    content: getRenderedContent,

    preview: getRenderedContent,

    title: (gem) => {
        return gem.title;
    },
};

export default DefaultRenderer;

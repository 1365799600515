import React from "react";
import { useModal } from "@contexts/ModalContext";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { PrevContentBar } from "./ContentModal";
import { ModalType } from "@constants/constants";
import { ItemModalComponent } from "@features/collection";

function CollectionItemArrowButton({
    ArrowIcon,
    parentCollectionItem,
    itemIndex,
}) {
    const { replaceContentInModal } = useModal();
    const currItem = parentCollectionItem?.items[itemIndex] ?? null;
    const handleArrowClick = (item) => {
        replaceContentInModal(
            ItemModalComponent,
            item.title,
            {},
            { item, index: itemIndex, parentCollectionItem },
            ModalType.COLLECTIONITEM
        );
    };

    return (
        currItem && (
            <button
                className="rounded-full bg-gray-100 opacity-70 hover:opacity-90 p-1"
                onClick={() => handleArrowClick(currItem)}
            >
                <ArrowIcon className="h-5 w-5 text-gray-800" />
            </button>
        )
    );
}

function CollectionItemModal({ prevContentTitle, currentComponent }) {
    const CurrentComponentContent = currentComponent?.content;
    const itemIndex = currentComponent?.props?.index;
    const parentCollectionItem = currentComponent?.props?.parentCollectionItem;

    if (!parentCollectionItem) return null;

    return (
        <>
            <div className="absolute left-4 top-1/2 transform -translate-y-1/2">
                <CollectionItemArrowButton
                    ArrowIcon={ChevronLeftIcon}
                    parentCollectionItem={parentCollectionItem}
                    itemIndex={itemIndex - 1}
                />
            </div>
            <div
                className="modal flex flex-col bg-gray-100 rounded-lg shadow-2xl overflow-y-auto z-10 md:min-w-[64vw] md:max-w-[80vw] w-[92vw] max-h-[92vh] min-h-[64vh]"
                onClick={(e) => e.stopPropagation()}
            >
                <PrevContentBar prevContentTitle={prevContentTitle} />
                <CurrentComponentContent
                    {...currentComponent?.props}
                    savedState={currentComponent?.state}
                />
            </div>
            <div className="absolute right-4 top-1/2 transform -translate-y-1/2">
                <CollectionItemArrowButton
                    ArrowIcon={ChevronRightIcon}
                    parentCollectionItem={parentCollectionItem}
                    itemIndex={itemIndex + 1}
                />
            </div>
        </>
    );
}

export { CollectionItemModal };

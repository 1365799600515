import { axiosPrivate } from "@api/axios";
import { ContentType } from "@constants";

class LinkPreviewService {
    static async getLinkPreview(searchQuery, type = ContentType.LINK) {
        searchQuery = searchQuery.trim();
        if (!searchQuery) return null;
        if (type === ContentType.LINK && !this.isValidURL(searchQuery))
            return null;

        try {
            const response = await axiosPrivate.get("/web/link-preview", {
                params: {
                    searchQuery: searchQuery,
                    type: type,
                },
            });

            return this.getLinkPreviewFromData(response.data);
        } catch (error) {
            throw new Error(
                error.response
                    ? error.response.data.error
                    : "Failed to fetch preview."
            );
        }
    }

    static isValidURL(str) {
        try {
            new URL(str);
            return true;
        } catch (error) {
            return false;
        }
    }

    static getLinkPreviewFromData(linkInfoData) {
        // Ensure data validity before creating LinkInfo instance
        if (!linkInfoData?.title) {
            throw new Error("Invalid link preview data received.");
        }

        return linkInfoData;
        // return new LinkInfo(
        //     linkInfoData.title,
        //     linkInfoData.description,
        //     linkInfoData.image,
        //     linkInfoData.metadata,
        //     linkInfoData.logo,
        //     linkInfoData.url,
        //     linkInfoData.publisher,
        //     linkInfoData.contentType
        // );
    }
}

export default LinkPreviewService;

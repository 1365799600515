import React from "react";
import { Link } from "react-router-dom";
import { useModal } from "@contexts/ModalContext";
import ProfileDefault from "./ProfileDefault";

function ProfilePicture({ profile, size = "h-6 w-6" }) {
    if (!profile) return null;

    if (!profile.profilePicture) {
        return <ProfileDefault username={profile.username} className={size} />;
    }

    return (
        <img
            src={profile.profilePicture}
            alt={`${profile.username}'s profile`}
            className={`${size} rounded-full object-cover`}
        />
    );
}

function AuthorProfileDisplay({
    profile,
    size = "w-6 h-6",
    useLink = true,
    showProfilePicture = true,
    children,
}) {
    const { closeAllModals } = useModal();

    if (!profile) return null;

    const handleProfileClick = (ev) => {
        ev.stopPropagation();
        closeAllModals();
    };

    const ProfileLink = ({ children }) => (
        <Link to={`/${profile.username}`} onClick={handleProfileClick}>
            {children}
        </Link>
    );

    const ProfileInfo = () => (
        <div className="flex items-center space-x-2 opacity-90">
            {showProfilePicture && (
                <ProfilePicture profile={profile} size={size} />
            )}

            <span className={`font-semibold`}>{profile.username}</span>
            {children}
        </div>
    );

    return useLink ? (
        <ProfileLink>
            <ProfileInfo />
        </ProfileLink>
    ) : (
        <ProfileInfo />
    );
}

export { ProfilePicture, AuthorProfileDisplay };

import { useState } from "react";
import useAuth from "./useAuth";
import AuthService from "@services/AuthService";

const useLogin = () => {
    const { setAuth } = useAuth();
    const [isLoading, setIsLoading] = useState(false);

    const login = async (username, password) => {
        setIsLoading(true);
        try {
            const response = await AuthService.login(username, password);
            setAuth({ accessToken: response.token });
            localStorage.setItem("login-event", "login" + Math.random());
            localStorage.removeItem("login-event");
            return response;
        } catch (error) {
            throw error;
        } finally {
            setIsLoading(false);
        }
    };

    return { login, isLoading };
};

export default useLogin;

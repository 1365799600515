import React from "react";
import { useModal } from "@contexts/ModalContext";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { ModalType } from "@constants/constants";
import { CollectionItemModal } from "./CollectionItemModal";
import { ContentModal } from "./ContentModal";
import { OverlayModal } from "./OverlayModal";

function CloseModalButton({ handleClose }) {
    return (
        <button
            className="close-modal absolute top-0 right-0 mr-6 mt-6 rounded-full bg-gray-100 opacity-90 hover:opacity-100 p-1"
            onClick={handleClose}
        >
            <XMarkIcon className="h-5 w-5 text-gray-800" />
        </button>
    );
}

function CloseModalConfirmation({ closeOverlayModal, closeAllModals }) {
    return (
        <div className="rounded flex flex-col items-center justify-center p-4">
            <div className="font-title text-bold mb-4">
                Are you sure you want to exit?
            </div>
            <button
                className="exit-modal-btn bg-rose-600 hover:bg-rose-800 text-white px-4 py-2 mb-2 rounded-full text-sm font-semibold"
                onClick={closeAllModals}
            >
                Yes, exit
            </button>
            <button
                className="bg-gray-300 hover:bg-gray-400 hover:bg-opacity-80 px-4 py-2 mb-2 rounded-full text-sm font-semibold"
                onClick={closeOverlayModal}
            >
                Cancel
            </button>
        </div>
    );
}

function handleModalClose(
    currentComponent,
    openOverlayModal,
    closeOverlayModal,
    closeAllModals
) {
    if (currentComponent?.type === ModalType.WRITE) {
        openOverlayModal(CloseModalConfirmation, {
            closeOverlayModal: closeOverlayModal,
            closeAllModals: closeAllModals,
        });
    } else {
        closeAllModals();
    }
}

function Modal() {
    const {
        modalStack,
        closeAllModals,
        openOverlayModal,
        closeOverlayModal,
        overlayModal,
    } = useModal();

    const isModalOpen = modalStack.length > 0;

    const renderOverlayModal = () => {
        return (
            <>
                <div
                    className="fixed inset-0 flex items-center justify-center z-40"
                    onClick={closeOverlayModal}
                ></div>
                <OverlayModal
                    ContentComponent={overlayModal.content}
                    props={overlayModal.props}
                />
            </>
        );
    };

    const currentComponent = modalStack[modalStack.length - 1];
    const renderModalContent = () => {
        const prevContentTitle =
            modalStack.length > 1
                ? modalStack[modalStack.length - 2]?.title
                : null;

        return currentComponent.type === ModalType.COLLECTIONITEM ? (
            <CollectionItemModal
                prevContentTitle={prevContentTitle}
                currentComponent={currentComponent}
            />
        ) : (
            <ContentModal
                prevContentTitle={prevContentTitle}
                currentComponent={currentComponent}
            />
        );
    };

    const handleClose = () => {
        return handleModalClose(
            currentComponent,
            openOverlayModal,
            closeOverlayModal,
            closeAllModals
        );
    };

    if (!isModalOpen) {
        return (
            overlayModal && (
                <div className="fixed inset-0 flex items-center justify-center z-30">
                    {renderOverlayModal()}
                </div>
            )
        );
    }

    return (
        <div className="fixed inset-0 flex items-center justify-center z-30">
            <div
                className="absolute inset-0 bg-black opacity-50"
                onClick={handleClose}
            ></div>
            {isModalOpen && (
                <>
                    {renderModalContent()}
                    <CloseModalButton handleClose={handleClose} />
                </>
            )}
            {overlayModal && renderOverlayModal()}
        </div>
    );
}

export default Modal;

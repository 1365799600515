import React, { useContext, useState } from "react";
import ProfileService from "@services/ProfileService";
import AuthService from "@services/AuthService";
import { useNavigate } from "react-router-dom";
import { UserContext } from "@contexts/UserContext";

const DeleteProfile = () => {
    const { logout } = useContext(UserContext);
    const navigate = useNavigate();

    const [credentials, setCredentials] = useState({
        username: "",
        password: "",
    });

    const [status, setStatus] = useState(null); // Add status state

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCredentials((prevCredentials) => ({
            ...prevCredentials,
            [name]: value,
        }));
    };

    const handleDelete = async () => {
        try {
            // Assume deleteProfile is a method in ProfileService that accepts credentials
            const response = await ProfileService.deleteProfile(credentials);
            if (response.status === 200) {
                setStatus("Account deleted successfully. Redirecting...");
                // clear form
                setCredentials({
                    username: "",
                    password: "",
                });
                setTimeout(() => {
                    logout();
                }, 3000);
                return;
            }
            if (response.status === 401) {
                setStatus("Invalid credentials");
                setCredentials({
                    username: "",
                    password: "",
                });
                return;
            }
            throw new Error();
        } catch (error) {
            setStatus("Something went wrong. Please try again later.");
        }
    };

    return (
        <div className="delete-profile p-6 max-w-md mx-auto">
            <h2 className="text-2xl mb-4">Delete Profile</h2>
            <p>
                Are you sure you want to delete your profile? This action cannot
                be undone.
            </p>
            <div className="mt-6">
                <label className="block mb-2">
                    Username
                    <input
                        type="text"
                        name="username"
                        value={credentials.username}
                        onChange={handleInputChange}
                        className="mt-1 w-full px-3 py-2 border rounded-md focus:outline-none focus:border-indigo-500"
                    />
                </label>
                <label className="block mb-4">
                    Password
                    <input
                        type="password"
                        name="password"
                        value={credentials.password}
                        onChange={handleInputChange}
                        className="mt-1 w-full px-3 py-2 border rounded-md focus:outline-none focus:border-indigo-500"
                    />
                </label>
                {status && (
                    <div className="mt-4 mb-4 text-red-600">
                        {/* Display status message */}
                        {status}
                    </div>
                )}
                <div className="flex justify-between">
                    <button
                        onClick={handleDelete}
                        className="w-full p-2 bg-red-500 text-white rounded-full focus:outline-none hover:bg-red-600"
                    >
                        Delete
                    </button>
                </div>
            </div>
        </div>
    );
};

export default DeleteProfile;

import React from "react";
import {
    Input,
    InputGroup,
    InputLeftAddon,
    InputRightAddon,
    Select,
} from "@chakra-ui/react";
import { ChevronRightIcon } from "@heroicons/react/24/solid";
import Loader from "@components/Loader";
import { ContentTypeToValue, ContentTypeToPlaceholderText } from "@constants";

function SelectInput({ selectedInputType, setSelectedInputType }) {
    const handleTypeChange = (e) => {
        setSelectedInputType(e.target.value);
    };

    const getMediaTypeValue = (type) => ContentTypeToValue[type];

    return (
        <Select
            variant="unstyled"
            pl="1"
            onChange={handleTypeChange}
            defaultValue={selectedInputType}
        >
            {Object.keys(ContentTypeToValue).map((type) => (
                <option key={type} value={type}>
                    {getMediaTypeValue(type)}
                </option>
            ))}
        </Select>
    );
}

function InputField({
    linkInput,
    setLinkInput,
    onLinkChange,
    handleSubmitLink,
    selectedInputType,
    setSelectedInputType,
    loading,
    error,
}) {
    const onChange = (ev) => {
        setLinkInput(ev.target.value);
        onLinkChange(ev.target.value);
    };

    const onKeyDown = (ev) => {
        if (ev.key === "Enter") {
            handleSubmitLink();
        }
    };

    const placeholderText = ContentTypeToPlaceholderText[selectedInputType];

    return (
        <div className="relative">
            <InputGroup size="md">
                <InputLeftAddon p="0">
                    <SelectInput
                        selectedInputType={selectedInputType}
                        setSelectedInputType={setSelectedInputType}
                    />
                </InputLeftAddon>
                <Input
                    variant="unstyled"
                    name="embed"
                    type="text"
                    placeholder={placeholderText}
                    _placeholder={{
                        color: "gray.500",
                        fontStyle: "italic",
                    }}
                    value={linkInput || ""}
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    isDisabled={loading}
                    isInvalid={error}
                    bg="white"
                    pl="2"
                />
                <button
                    onClick={(ev) => {
                        ev.preventDefault();
                        handleSubmitLink();
                    }}
                >
                    <InputRightAddon>
                        {loading ? (
                            <Loader size="h-5 w-5" />
                        ) : (
                            <ChevronRightIcon className="h-5 w-5 text-gray-700" />
                        )}
                    </InputRightAddon>
                </button>
            </InputGroup>
            {error && (
                <div className="text-red-800 text-sm mt-1 mb-2 ml-1">
                    {error}
                </div>
            )}
        </div>
    );
}

export default InputField;

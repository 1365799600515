import HttpError from "@classes/HttpError";
import { Gem } from "@classes/Post";
import { axiosPrivate } from "@api/axios";

class GemService {
    static async createGem(formData) {
        try {
            const response = await axiosPrivate.post("/gem", formData, {
                headers: { "Content-Type": "multipart/form-data" },
                withCredentials: true,
            });

            return this.getGemFromData(response.data.data);
        } catch (error) {
            console.error("Failed to create gem:", error);
            throw new Error("Failed to create gem.");
        }
    }

    static async updateGem(formData) {
        try {
            const response = await axiosPrivate.put("/gem", formData, {
                headers: { "Content-Type": "multipart/form-data" },
                withCredentials: true,
            });

            return this.getGemFromData(response.data.data);
        } catch (error) {
            console.error("Failed to update gem:", error);
            throw new Error("Failed to update gem.");
        }
    }

    static async getGem(id) {
        try {
            const response = await axiosPrivate.get(`/gem/${id}`);

            if (!response.data.success) {
                throw new HttpError(
                    response.status,
                    `Request failed with status: ${response.status}`
                );
            }

            return this.getGemFromData(response.data.data);
        } catch (error) {
            if (error.response) {
                // If the error is an HTTP response error, throw it as an HttpError
                throw new HttpError(
                    error.response.status,
                    `Request failed with status: ${error.response.status}`
                );
            } else {
                // For non-HTTP errors, rethrow the original error.
                throw error;
            }
        }
    }

    static async getGems(queryParam) {
        try {
            const response = await axiosPrivate.get(`/gem${queryParam}`);

            return this.getGemsFromData(response.data.data);
        } catch (error) {
            console.error("Failed to fetch gems:", error);
            throw new Error("Failed to fetch gems.");
        }
    }

    static getGemFromData(gemData) {
        let metadata;
        try {
            if (
                typeof gemData.metadata === "string" &&
                gemData.metadata !== ""
            ) {
                metadata = JSON.parse(gemData.metadata);
            } else {
                metadata = gemData.metadata;
            }

            if (
                !metadata ||
                (Object.keys(metadata).length === 0 &&
                    metadata.constructor === Object)
            ) {
                metadata = null;
            }
        } catch (error) {
            console.error("Error parsing metadata:", error);
        }

        return new Gem({
            _id: gemData._id,
            title: gemData.title,
            description: gemData.description,
            author: gemData.author,
            createdAt: gemData.createdAt,
            updatedAt: gemData.updatedAt,
            content: gemData.content,
            link: metadata?.link || null,
            gemId: gemData.gemId,
            privacyLevel: gemData.privacyLevel,
            likeCount: gemData.likeCount,
            commentCount: gemData.commentCount,
            metadata: metadata,
            contentType: gemData.contentType,
            status: gemData.status,
            isLoading: false,
        });
    }

    static getGemsFromData(gemsData) {
        return gemsData.map((gemData) => this.getGemFromData(gemData));
    }
}

export default GemService;

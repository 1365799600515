import React from "react";
import { GemContent } from "@features/gem";
import { Post, PostContent } from "@features/post";
import { FullContentRenderer } from "@features/linkToGem";

function GemPost({ gem, allowModify = true }) {
    return (
        <Post post={gem} allowModify={allowModify}>
            <PostContent post={gem}>
                <GemContent gem={gem} contentRenderer={FullContentRenderer} />
            </PostContent>
        </Post>
    );
}

export default GemPost;

import { createContext, useState, useContext, useEffect } from "react";
import { UserContext } from "@contexts/UserContext";
import { usePersonalPage } from "@contexts/PersonalPageContext";

const GlobalPostContext = createContext();

export function GlobalPostProvider({ children }) {
    const { userId } = useContext(UserContext);
    const [posts, setPosts] = useState(new Map());
    const {
        getPost: getPersonalPost,
        handleUpdatePost: updatePersonalPost,
    } = usePersonalPage();

    const isPersonalPost = (post, userId) => {
        return post?.author?._id === userId;
    };

    const getPost = (post) => {
        if (isPersonalPost(post, userId)) {
            return getPersonalPost(post._id);
        }
        if (posts && posts.has(post?._id)) {
            return posts.get(post._id);
        }

        return null;
    };

    const updatePost = (updatedPost) => {
        // If it's a personal post, don't update the global posts map. Those are tracked separately.
        if (isPersonalPost(updatedPost, userId)) {
            updatePersonalPost(updatedPost);
        } else {
            const updatedPosts = new Map(posts);
            updatedPosts.set(updatedPost._id, updatedPost);
            setPosts(updatedPosts);
        }
    };

    return (
        <GlobalPostContext.Provider
            value={{
                posts,
                getPost,
                updatePost,
            }}
        >
            {children}
        </GlobalPostContext.Provider>
    );
}

export function useGlobalPosts() {
    const context = useContext(GlobalPostContext);
    if (!context) {
        throw new Error(
            "useGlobalPosts must be used within a GlobalPostProvider"
        );
    }
    return context;
}

import React, { useContext } from "react";
import { LikePanel } from "@features/like";
import CommentPanel from "./CommentPanel";
import CommentsDisplay from "./CommentsDisplay";
import useComments from "../hooks/useComments";
import { UserContext } from "@contexts/UserContext";

function LikeCommentPanel({ post }) {
    const { userId, userInfo } = useContext(UserContext);
    const {
        comment,
        setComment,
        allComments,
        handleSendComment,
        handleDeleteComment,
    } = useComments(post);

    return (
        <div className="panel pt-2 rounded mt-4 flex flex-col items-start">
            <CommentsDisplay
                allComments={allComments}
                handleDeleteComment={handleDeleteComment}
                currentUserId={userId}
            />
            <div className="pt-4 w-full">
                <LikePanel post={post} />
                {userInfo && (
                    <CommentPanel
                        comment={comment}
                        setComment={setComment}
                        handleSendComment={handleSendComment}
                    />
                )}
            </div>
        </div>
    );
}

export default LikeCommentPanel;

import React from "react";

function StandardIFrame({ url, title, iframeProps }) {
    return <iframe src={url} title={title} {...iframeProps} />;
}

const EmbeddedContent = ({ gem, getEmbedURL, props }) => {
    return (
        <div>
            <StandardIFrame
                url={getEmbedURL(gem.metadata.link)}
                title={gem.metadata.title}
                iframeProps={props}
            />
        </div>
    );
};

const CreateEmbedRenderer = (gem, getEmbedURL, props) => {
    if (!gem.metadata.link || !gem.metadata) {
        return null;
    }

    return (
        <EmbeddedContent
            key={gem.id}
            gem={gem}
            getEmbedURL={getEmbedURL}
            props={props}
        />
    );
};

export { CreateEmbedRenderer };

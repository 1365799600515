import React from "react";
import { Link } from "react-router-dom";
import { WelcomeButton } from "@features/landing";
import butterflyImage from "@assets/butterfly.png";

function AboutPage() {
    return (
        <div className="flex flex-col justify-center items-center bg-white text-2xl py-8">
            <Link
                to={`/`}
                className="mb-6 font-logo bold text-8xl blue-purple-gradient-text p-4"
            >
                Welcome to Gems
            </Link>
            <div className="bg-white w-full px-24 p-12 pb-20 items-center">
                <span className="font-semibold py-8">
                    We're excited to introduce you to Gems — a platform to
                    collect and share the hype stuff you encounter in life 👊
                </span>
                <div className="flex items-center space-x-32 mt-12">
                    <div>
                        <div>The world is full of gems, hidden and not:</div>
                        <ul className="mt-3 pl-8 text-xl">
                            <li className="mb-2">
                                🌎 The{" "}
                                <span className="font-semibold">
                                    restaurants
                                </span>{" "}
                                you loved on your last trip 🌎
                            </li>
                            <li className="mb-2">
                                🎧 The{" "}
                                <span className="font-semibold">podcast</span>{" "}
                                episode that made you stop and really listen 🎧
                            </li>
                            <li className="mb-2">
                                📖 The{" "}
                                <span className="font-semibold">book</span> that
                                had you zoned in for hours 📖
                            </li>
                            <li className="mb-2">
                                🥘 The{" "}
                                <span className="font-semibold">recipe</span>{" "}
                                you experimented with and was a hit 🥘
                            </li>
                            <li className="mb-2">
                                🎵 The{" "}
                                <span className="font-semibold">song</span> you
                                just made and are semi-proud of 🎵
                            </li>
                            <li className="mb-2">
                                📸 The{" "}
                                <span className="font-semibold">photo</span> you
                                took and want to hang on your wall 📸
                            </li>
                        </ul>
                    </div>
                    <img
                        src={butterflyImage}
                        alt="butterfly in guanajuato, mexico"
                        className="w-[400px]"
                    />
                </div>
            </div>
            <div className="bg-blue-purple p-24 w-full">
                <div className="text-white p-8 font-semibold text-center">
                    We believe the world is full of beauty, that humans are
                    unique individuals with unique tastes, and that there is
                    great power in sharing with others, whether with close
                    friends or people across the world. We can all benefit from
                    each other’s joys — the world is too cluttered not to share
                    what’s truly special. ✨
                </div>
            </div>
            <div className="bg-indigo-200 bg-opacity-50 w-full p-24">
                <div className="font-semibold pb-8">With Gems, you can...</div>
                <div className="flex items-center space-x-32">
                    <div>
                        <div className="mt-4">
                            Post a <span className="font-semibold">gem</span> (a
                            link, photo, or text), like:
                        </div>
                        <ul className="text-xl pl-8">
                            <li className="mt-2">📍 A Google Maps Link</li>
                            <li className="mt-2">🎵 A Spotify playlist</li>
                            <li className="mt-2">🎥 A Youtube video</li>
                            <li className="mt-2">📝 A note</li>
                            <li className="mt-2">📷 A photo</li>
                        </ul>
                    </div>
                    <div>
                        <div className="mt-4">
                            Post a{" "}
                            <span className="font-semibold">collection</span>,
                            like:
                        </div>
                        <ul className="text-xl pl-8">
                            <li className="mt-2">
                                📍 Restaurants, bars, activities from your last
                                trip
                            </li>
                            <li className="mt-2">
                                🎶 Your favorite sets, mixes, and playlists of
                                the year
                            </li>
                            <li className="mt-2">
                                💭 Articles, quotes, and podcasts about a topic
                                you're diving into
                            </li>
                            <li className="mt-2">
                                😁 Your favorite comedy clips, specials, and
                                movies
                            </li>
                            <li className="mt-2">
                                🥗 Your favorite dinner party recipes for fall
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="bg-indigo-200  w-full p-24">
                <div className="pb-8">Some cool features...</div>
                <ul className="text-xl pl-8">
                    <li className="mt-2">
                        ⚡️ <span className="font-semibold">Auto-generate</span>{" "}
                        gems and collections using AI, making it insanely simple
                        to organize and share your pre-existing Notes lists
                    </li>
                    <li className="mt-2">
                        🤝 <span className="font-semibold">Customize</span> your
                        page by creating sub-pages by topic, reordering your
                        posts, and setting 🔒 privacy level on posts
                    </li>
                    <li className="mt-2">
                        🌎 <span className="font-semibold">Discover</span> gems
                        and collections from users around the world, via our
                        recommendation algorithm based on your interests, or by
                        search
                    </li>
                    <li className="mt-2">
                        👯‍♀️ <span className="font-semibold">Interact </span> with
                        like-minded, like-spirited people around the globe and
                        follow your favorites
                    </li>
                    <li className="mt-2">
                        💖 <span className="font-semibold">Connect</span> with
                        your close friends and family on a private feed, getting
                        to them more fully and deeply
                    </li>
                </ul>
            </div>
            <div className="bg-blue-purple p-24 w-full">
                <div className="text-white p-8 text-center">
                    <div className="font-semibold pb-8">
                        We started Gems after working in Jakarta, Indonesia and
                        spending a year traveling around the world. During our
                        journeys we realized:
                    </div>
                    <ul className="text-lg">
                        <li>
                            The world is huge and{" "}
                            <span className="underline">
                                there are so many things to discover
                            </span>
                        </li>
                        <li>
                            Yet everyone goes to the same crowded touristy
                            places.
                        </li>
                        <li>
                            Our best recommendations come from talking to people
                            on the road
                        </li>
                        <li className="font-semibold">
                            There is a wealth of goodness in each of us
                        </li>
                        <li>
                            Yet all we share online is photos of our vacations
                            and highlight reels.
                        </li>
                        <li className="italic font-semibold">
                            What if we could create a spot online for people to
                            share with meaning and value?
                        </li>
                        <li>
                            In a way that doesn't ask to be seen, but simply{" "}
                            <span className="underline">offers a space</span>
                        </li>
                        <li>
                            In a way that honors our vibrant offline lives,
                            instead of trapping our attention online
                        </li>
                        <li>
                            That allows us to express our unique differences,
                            instead of hopping on the next trends.
                        </li>
                        <li className="font-semibold">
                            Celebrating all the good in the world -- and in
                            ourselves.
                        </li>
                    </ul>
                    <div className="font-semibold pt-16">
                        We know we have a tough journey ahead, but we're
                        inspired by the call to create something good.
                    </div>
                </div>
            </div>
            <div className="bg-white w-full p-24 text-center">
                Gems is currently in private alpha. We’d love to hear from you
                if you're interested in what we're building. Please reach out at{" "}
                <span className="font-semibold">gemsthecompany@gmail.com</span>.
                <div className="pt-24 text-center">
                    Thank you! {"<3"}
                    <br />
                    <Link
                        to={`${process.env.REACT_APP_URL}/sandy`}
                        className="font-semibold"
                    >
                        Sandy
                    </Link>{" "}
                    and{" "}
                    <Link
                        to={`${process.env.REACT_APP_URL}/bilva`}
                        className="font-semibold"
                    >
                        Bilva
                    </Link>
                </div>
            </div>
            <div className="mb-4">
                <WelcomeButton toLink="/login">Login</WelcomeButton>
            </div>
            <div className="">
                <WelcomeButton toLink="/register">Register</WelcomeButton>
            </div>
        </div>
    );
}

export default AboutPage;

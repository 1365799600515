import React from "react";
import { Link } from "react-router-dom";
import { SplitContentRendererFrame } from "../RendererFrames";
import DefaultLinkRenderer from "./DefaultLinkRenderer";
import { LinkWrapper, LinkIcon } from "@components/Link";
import { ContentType } from "@constants";

const getMovieTitle = (gem) => {
    return (
        <div className="flex space-x-2 items-center">
            <LinkIcon linkInfo={gem?.metadata} size={"h-5 w-5"} />
            <div className="font-semibold text-oversize-1">
                {gem?.metadata?.webTitle}
            </div>
        </div>
    );
};
const getRenderedContent = (gem) => {
    const metadata = gem.metadata;
    const title = getMovieTitle(gem);
    return (
        <LinkWrapper linkInfo={metadata}>
            <SplitContentRendererFrame
                gem={gem}
                isContent={true}
                previewTitle={title}
                previewImage={metadata?.image}
            >
                {metadata?.tagline && (
                    <p className="mt-2 text-oversize-2 italic">
                        {metadata.tagline}
                    </p>
                )}
                {metadata?.description && (
                    <p className="mt-4 text-oversize-4">
                        {metadata.description}
                    </p>
                )}
                {metadata.movieData && (
                    <div className="mt-4 text-oversize-2">
                        {metadata.movieData}
                    </div>
                )}
                {metadata?.publisher && (
                    <div className="text-md text-oversize-1 text-gray-500">
                        View on {metadata.publisher}
                    </div>
                )}
            </SplitContentRendererFrame>
        </LinkWrapper>
    );
};

const getPreviewContent = (gem) => {
    const metadata = gem.metadata;
    const title = getMovieTitle(gem);
    return (
        <SplitContentRendererFrame
            gem={gem}
            previewTitle={title}
            previewImage={metadata?.image}
        >
            {metadata.tagline && (
                <p className="mt-2 text-md text-oversize-2 text-gray-600 italic">
                    {metadata.tagline}
                </p>
            )}
            {metadata.movieData && (
                <div className="mt-4 text-gray-600">{metadata.movieData}</div>
            )}
        </SplitContentRendererFrame>
    );
};

const MovieRenderer = {
    detect: (url, tag, contentType) => {
        return (
            tag?.toLowerCase() === ContentType.MOVIE ||
            contentType?.toLowerCase() === ContentType.MOVIE
        );
    },

    content: getRenderedContent,

    preview: (gem) => {
        return getPreviewContent(gem);
    },

    title: (gem) => {
        return <>🎬 {gem.title}</>;
    },
};

export default MovieRenderer;

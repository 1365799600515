import "./assets/App.css";
import { Route, Routes } from "react-router-dom";
import { UserContextProvider } from "./contexts/UserContext";
import { ModalProvider } from "./contexts/ModalContext";
import { PersonalPageProvider } from "./contexts/PersonalPageContext";
import { PersonalPagePostsProvider } from "./contexts/PersonalPagePostContext";
import { GlobalPostProvider } from "./contexts/GlobalPostContext";
import { ToastProvider } from "./contexts/ToastContext";
import ProtectedRoute from "./layouts/ProtectedRoute";
import { PUBLIC_ROUTES, PROTECTED_ROUTES } from "@features/routing";
import LoginListener from "@features/authentication/components/LoginListener";

import { ChakraProvider } from "@chakra-ui/react";
import { AuthProvider } from "./contexts/AuthProvider";
import AxiosProvider from "./contexts/AxiosProvider";

import { Quill } from "react-quill";
import ImageResize from "quill-image-resize-module-react";
import NotificationProvider from "./contexts/NotificationProvider";

Quill.register("modules/imageResize", ImageResize);
window.Quill = Quill;

function App() {
    return (
        <ChakraProvider>
            <AuthProvider>
                <AxiosProvider />
                <UserContextProvider>
                    <NotificationProvider>
                        <PersonalPageProvider>
                            <PersonalPagePostsProvider>
                                <GlobalPostProvider>
                                    <ToastProvider>
                                        <ModalProvider>
                                            <LoginListener />
                                            <Routes>
                                                {PUBLIC_ROUTES.map(
                                                    ({ path, element }) => (
                                                        <Route
                                                            key={path}
                                                            path={path}
                                                            element={element}
                                                        />
                                                    )
                                                )}
                                                <Route
                                                    element={<ProtectedRoute />}
                                                >
                                                    {PROTECTED_ROUTES.map(
                                                        ({ path, element }) => (
                                                            <Route
                                                                key={path}
                                                                path={path}
                                                                element={
                                                                    element
                                                                }
                                                            />
                                                        )
                                                    )}
                                                </Route>
                                            </Routes>
                                        </ModalProvider>
                                    </ToastProvider>
                                </GlobalPostProvider>
                            </PersonalPagePostsProvider>
                        </PersonalPageProvider>
                    </NotificationProvider>
                </UserContextProvider>
            </AuthProvider>
        </ChakraProvider>
    );
}

export default App;

import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "@contexts/UserContext";
import { PATHS } from "@features/routing";
import { OptionsButton } from "@components/OptionsButton";

function HeaderMenu({ logout }) {
    const navigate = useNavigate();
    const { userInfo } = useContext(UserContext);

    const menuLinkClasses =
        "block w-full text-left px-4 py-2 text-black hover:bg-gray-200";

    return (
        <OptionsButton widthAndPositionStyles="right-0 w-[180px]">
            {(setShowOptions) => (
                <div className="block absolute right-0 w-36 mt-4 bg-gray-100 divide-y divide-gray-200 rounded-md shadow-lg z-50">
                    <div
                        onClick={() => {
                            navigate("/about");
                            setShowOptions(false);
                        }}
                        className={`${menuLinkClasses} cursor-pointer`}
                    >
                        About Gems
                    </div>
                    <div
                        onClick={() => {
                            navigate(
                                PATHS.getUserProfilePath(userInfo?.username)
                            );
                            setShowOptions(false);
                        }}
                        className={`${menuLinkClasses} cursor-pointer`}
                    >
                        Profile
                    </div>
                    <div
                        onClick={() => {
                            navigate("/onboard");
                            setShowOptions(false);
                        }}
                        className={`${menuLinkClasses} cursor-pointer`}
                    >
                        Watch Tutorial
                    </div>
                    <div
                        onClick={() => {
                            logout();
                            navigate("/");
                            setShowOptions(false);
                        }}
                        className={`${menuLinkClasses} cursor-pointer`}
                    >
                        <span className="font-semibold">Logout</span>
                    </div>
                </div>
            )}
        </OptionsButton>
    );
}

export default HeaderMenu;

import { useNavigate } from "react-router-dom";
import { ProfilePicture } from "@features/profile";
import NotificationService from "@services/NotificationService";
import { useNotificationsHook } from "@contexts/NotificationProvider";

export function NotificationMessageBase({ notification }) {
    const navigate = useNavigate();
    const { refreshNotifications } = useNotificationsHook();

    let handleClick = () => {
        NotificationService.markNotificationsSeen(notification._id).then(() => {
            refreshNotifications();
        });
        navigate(`/Post/${notification.post}`);
    };

    let fullMessage;
    if (notification.type === "Like") {
        fullMessage = "liked your post";
    }
    if (notification.type === "Comment") {
        fullMessage = "commented on your post";
    }
    if (notification.type === "Follow") {
        fullMessage = "followed you";
        handleClick = () => {
            NotificationService.markNotificationsSeen(notification._id).then(
                () => {
                    refreshNotifications();
                }
            );
            navigate(`/${notification.userActing.username}`);
        };
    }
    if (!fullMessage) {
        return;
    }

    function timeAgo(timestamp) {
        const now = new Date();
        const timeElapsed = now - new Date(timestamp);
        const seconds = Math.round(timeElapsed / 1000);

        if (seconds < 60) {
            return `less than a minute ago`;
        } else if (seconds < 3600) {
            const minutes = Math.floor(seconds / 60);
            return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
        } else if (seconds < 86400) {
            const hours = Math.floor(seconds / 3600);
            return `${hours} hour${hours > 1 ? "s" : ""} ago`;
        } else {
            const days = Math.floor(seconds / 86400);
            return `${days} day${days > 1 ? "s" : ""} ago`;
        }
    }

    return {
        message: fullMessage,
        time: timeAgo(notification.createdAt),
        handleClick,
    };
}

function NotificationMessage({ notification }) {
    const { message, time, handleClick } = NotificationMessageBase({
        notification,
    });

    return (
        <div
            onClick={handleClick}
            className={`cursor-pointer flex items-center bg-gray-100 hover:bg-gray-200 p-2 rounded-md mb-2 ${
                notification.seen ? "" : "font-bold"
            }`}
        >
            <ProfilePicture
                profile={notification.userActing}
                size={"w-10 h-10"}
            />
            <div className="pl-4">
                <p>
                    {notification.userActing.username} {message}
                </p>
                <p className="text-sm text-gray-500">{time}</p>
            </div>
        </div>
    );
}

export default NotificationMessage;
